import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const apiBaseUrl = process.env.REACT_APP_API_URL;

const initialState = {
  createGuest: {
    loading: false,
    data: "",
    error: "null",
  },
};

export const createGuest = createAsyncThunk(
  "createGuest",
  async (data, thunkAPI) => {
    const response = await fetch(`${apiBaseUrl}/api/uploadGuestContact`, {
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      method: "post",
      body: JSON.stringify(data),
    }).catch((error) => {
      return thunkAPI.rejectWithValue("No Response from Server!");
    });

    try {
      if (response.status) {
        if (response.status === 200) {
          const response1 = await response.json();
          return response1;
        } else {
          const response1 = await response.json();
          return thunkAPI.rejectWithValue(response1);
        }
      } else {
        return thunkAPI.rejectWithValue("No Response from Server!");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: "Failed to Send Guest Contact!",
        message: error.message,
      });
    }
  }
);

export const clearAllGuestData = () => (dispatch) => {
  dispatch({ type: "CLEAR_ALL_GUEST_DATA" });
};

export const saveCreatedGuest = createAsyncThunk();

const authSlice = createSlice({
  name: "guest",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createGuest.pending, (state) => {
        state.createGuest.loading = true;
        state.createGuest.data = "";
        state.createGuest.error = null;
      })
      .addCase(createGuest.fulfilled, (state, action) => {
        state.createGuest.loading = true;
        state.createGuest.data = action?.payload;
        state.createGuest.error = action.payload.message;
      })
      .addCase(createGuest.rejected, (state, action) => {
        state.createGuest.loading = false;
        state.createGuest.data = "";
        state.error = action.payload.message;
      })
      .addCase("CLEAR_ALL", (state, action) => {
        state.loading = "";
        state.error = null;
        state.data = "";
        state.createGuest.loading = false;
        state.createGuest.data = "";
        state.createGuest.error = null;
      })
      .addCase("CLEAR_ALL_CLIENT_DATA", (state, action) => {
        state.createGuest.loading = false;
        state.createGuest.data = "";
        state.createGuest.error = null;
      });
  },
});
export default authSlice.reducer;
