import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const apiUrl = process.env.REACT_APP_API_URL;
const conductorUrl = process.env.CONDUCTOR_BACKEND_URL || "https://www.mediamesh.cloud:8081";
const initialState = {
  loading: false,
  data: "",
  error: null,
  conductor: {
    loading: false,
    data: "",
    error: null,
  },
  scheduler: {
    loading: false,
    data: "",
    error: null,
  },
  domainData: {
    loading: false,
    data: "",
    error: null,
  },
  executionData: {
    loading: false,
    data: "",
    error: null,
  },
};
export const execution = createAsyncThunk(
  "execution",

  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;
    const domainData = await fetch(`${apiUrl}/api/addExecutionData`, {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }).catch((error) => {
      return "No Resonse from API!";
    });

    try {
      if (domainData.status === 200) {
        const response1 = await domainData.json();
      }
    } catch (error) {
      return "No Resonse from API!";
    }
    try {
      if (domainData.status) {
        if (domainData.status === 200) {
          const response1 = await domainData.json();
          return response1;
        } else {
          return thunkAPI.rejectWithValue("Error in API");
        }
      } else {
        return thunkAPI.rejectWithValue("No Resonse from Server!");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("No Response from Server!");
    }
  }
);
export const conductor = createAsyncThunk(
  "conductor",
  async (data, thunkAPI) => {
    const { token,userGroup } = thunkAPI.getState().auth;
    const response = await fetch(
      `${conductorUrl}/dynamicWorkflow/backendApiCall/executeWorkflow/${userGroup}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    ).catch((error) => {
      return thunkAPI.rejectWithValue(error);
    });

    try {
      if (response.status === 200) {
        const response1 = await response.json();
        return response1;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
    try {
      if (response.status) {
        if (response.status === 200) {
          const response1 = await response.json();
          return response1;
        } else {
          return thunkAPI.rejectWithValue("Error in API");
        }
      } else {
        return thunkAPI.rejectWithValue("No Resonse from Server!");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("No Response from Server!");
    }
  }
);
export const getExecutionData = createAsyncThunk(
  "executionData",
  async (data, thunkAPI) => {
    const { token,userGroup } = thunkAPI.getState().auth;
    const domainData = await fetch(`${apiUrl}/api/getExecutionData/${userGroup}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).catch((error) => {
      return thunkAPI.rejectWithValue(error);
    });
    try {
      if (domainData.status === 200) {
        const response1 = await domainData.json();
        return response1;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("No Response from Server!");
    }
  }
);
export const getDomainData = createAsyncThunk(
  "domainData",
  async (data, thunkAPI) => {
    const { token,userGroup } = thunkAPI.getState().auth;
    const domainData = await fetch(`${apiUrl}/api/getDomainData/${userGroup}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).catch((error) => {
      return thunkAPI.rejectWithValue(error);
    });
    try {
      if (domainData.status === 200) {
        const response1 = await domainData.json();
        return response1;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("No Response from Server!");
    }
  }
);
export const fixScheduler = createAsyncThunk(
  "scheduler",
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;
    
    const response = await fetch(
      `${conductorUrl}/dynamicWorkflow/backendApiCall/scheduleWorkflow`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    ).catch((error) => {
      return thunkAPI.rejectWithValue(error);
    });

    try {
      if (response.status === 200) {
        const response1 = await response.json();
        return response1;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
    try {
      if (response.status) {
        if (response.status === 200) {
          const response1 = await response.json();
          return response1;
        } else {
          return thunkAPI.rejectWithValue("Error in API");
        }
      } else {
        return thunkAPI.rejectWithValue("No Resonse from Server!");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("No Response from Server!");
    }
  }
);
export const clearAll = () => async (dispatch) => {
  dispatch({ type: "CLEAR_All" });
};
export const clearAllDomaindata = () => async (dispatch) => {
  dispatch({ type: "CLEAR_All_DOMAIN_DATA" });
};
const executionSlice = createSlice({
  name: "execution",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(execution.pending, (state) => {
        state.loading = true;
        state.data = "";
        state.error = null;
      })
      .addCase(execution.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action?.payload;
        state.error = null;
      })
      .addCase(execution.rejected, (state, action) => {
        state.loading = false;
        state.data = "";
        state.error = action?.payload;
      })
      .addCase(conductor.pending, (state) => {
        state.conductor.loading = true;
        state.conductor.data = [];
        state.conductor.error = null;
      })
      .addCase(conductor.fulfilled, (state, action) => {
        state.conductor.loading = false;
        state.conductor.data = action?.payload;
        state.conductor.error = null;
      })
      .addCase(conductor.rejected, (state, action) => {
        state.conductor.loading = false;
        state.conductor.data = [];
        state.conductor.error = action?.payload;
      })
      .addCase(getDomainData.pending, (state) => {
        state.domainData.loading = true;
        state.domainData.data = [];
        state.domainData.error = null;
      })
      .addCase(getDomainData.fulfilled, (state, action) => {
        state.domainData.loading = false;
        state.domainData.data = action?.payload;
        state.domainData.error = null;
      })
      .addCase(getDomainData.rejected, (state, action) => {
        state.domainData.loading = false;
        state.domainData.data = [];
        state.domainData.error = action?.payload;
      })

      .addCase(getExecutionData.pending, (state) => {
        state.executionData.loading = true;
        state.executionData.data = [];
        state.executionData.error = null;
      })
      .addCase(getExecutionData.fulfilled, (state, action) => {
        state.executionData.loading = false;
        state.executionData.data = action?.payload;
        state.executionData.error = null;
      })
      .addCase(getExecutionData.rejected, (state, action) => {
        state.executionData.loading = false;
        state.executionData.data = [];
        state.executionData.error = action?.payload;
      })
      .addCase(fixScheduler.pending, (state) => {
        state.scheduler.loading = true;
        state.scheduler.data = [];
        state.scheduler.error = null;
      })
      .addCase(fixScheduler.fulfilled, (state, action) => {
        state.scheduler.loading = false;
        state.scheduler.data = action?.payload;
        state.scheduler.error = null;
      })
      .addCase(fixScheduler.rejected, (state, action) => {
        state.scheduler.loading = false;
        state.scheduler.data = [];
        state.scheduler.error = action?.payload;
      })
      .addCase("CLEAR_All", (state, action) => {
        state.loading = "";
        state.error = null;
        state.data = "";
        state.conductor.loading = false;
        state.conductor.data = "";
        state.conductor.error = null;
        state.scheduler.loading=false;
        state.scheduler.data = "";
        state.scheduler.error = null;
      })
      .addCase("CLEAR_All_DOMAIN_DATA", (state, action) => {
        state.domainData.data = "";
        state.domainData.error = null;
        state.domainData.loading = false;
      });
  },
});
export default executionSlice.reducer;
