import React, { useState, useEffect } from "react";
import "../AdminProject/updateClient.css";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { enqueueSnackbar } from "notistack";
const UpdateAdminUser = ({
  onClose,
  projectMapping,
  onUpdate,
  clientData,
  projectData,
  roleData,
}) => {
  const [contactName, setContactName] = useState(projectMapping.contactName);
  const [filteredProjectData, setFilteredProjectdata] = useState(projectData);
  const [primaryEmail, setPrimaryEmail] = useState(projectMapping.primaryEmail);
  const [selectedClient, setSelectedClient] = useState(
    projectMapping.clientName._id
  );
  const [selectedProject, setSelectedProject] = useState(
    projectMapping.projectName._id
  );
  const [selectedRole, setSelectedRole] = useState(projectMapping.role._id);
  const [isFormValid, setIsFormValid] = useState(true);
  const [emailError, setEmailError] = useState("");
  const validateForm = () => {
    // Validate email and update email error state
    if (!validEmailId(primaryEmail)) {
      setEmailError("Invalid email Address!");
      setIsFormValid(false);
    } else {
      setEmailError("");
      setIsFormValid(true);
    }
  };

  useEffect(() => {
    if (primaryEmail) {
      validateForm();
    }
   
  }, [primaryEmail, isFormValid]);
  const onPrimaryEmailChange = (e) => {
    const email = e.target.value;
    setPrimaryEmail(email);
  };
  const onContactNameChange = (e) => {
    setContactName(e.target.value);
  };
  const validEmailId = (email) => {
    const regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEmail.test(String(email).toLowerCase());
  };
  const handleUpdate = () => {
    // Check if required dropdown fields are selected
    if (!selectedClient || !selectedProject || !selectedRole || !primaryEmail || !contactName ) {
      enqueueSnackbar("Please select all required fields!", {
        variant: "error",
      });
      return; // Prevent further execution of the function
    }
    if (!isFormValid) {
     
      enqueueSnackbar("Invalid Email!", {
        variant: "error",
      });
      return;
    }
    onUpdate({
      id:projectMapping._id,
      companyName: selectedClient,
      projectName: selectedProject,
      role: selectedRole,
      contactName,
      primaryEmail,
    })
   
  };
  const handleSelectedClient = (e) => {
    setSelectedClient(e.value);
    setFilteredProjectdata(projectData.filter((project) => project.clientName._id === e.value));
  };
  return (
    <div className="assignment-container">
      <div className="assignment-container-frame1">
        <text>Update Admin User</text>
      </div>
      <div className="assignment-container-frame2">
        <div className="assignment-container-frame3">
          <div className="assignment-module-container">
            <div className="assignment-module1">
              <div className="assignment-module-title">
                <div className="project-label">
                  <text>User Name</text>
                  <p1>*</p1>
                </div>
                <input
                  className="setting-admin-heading-companyname-input"
                  placeholder="Enter Admin Name"
                  value={contactName}
                  onChange={onContactNameChange}
                  required
                />
              </div>
              <div className="assignment-module-title">
                <div className="project-label">
                  <text>User Email</text>
                  <p1>*</p1>
                </div>
                <input
                  className="setting-admin-heading-email-input"
                  placeholder="enter email"
                  value={primaryEmail}
                  onChange={onPrimaryEmailChange}
                  required
                />
              </div>
              <div className="assignment-module-title">
                <div className="project-label">
                  <text>Client Name</text>
                  <p1>*</p1>
                </div>
                <DropDownListComponent
                  className="setting-diagram-projectClient-input"
                  placeholder="Select Client"
                  dataSource={clientData} // Set client data as dataSource
                  fields={{ text: "companyName", value: "_id" }} // Define the fields to display
                  value={selectedClient} // Bind selectedClient state to the value property
                  change={(e) =>  handleSelectedClient(e)} // Handle change event to update selected client
                  style={{ height: "38px" }}
                  required
                />
              </div>
              <div className="assignment-module-title">
                <div className="project-label">
                  <text>project</text>
                  <p1 id="2">*</p1>
                </div>
                <DropDownListComponent
                  className="setting-diagram-projectClient-input"
                  placeholder="Select Project"
                  dataSource={filteredProjectData} // Set project data as dataSource
                  fields={{ text: "projectName", value: "_id" }} // Define the fields to display
                  value={selectedProject} // Bind selectedproject state to the value property
                  change={(e) => setSelectedProject(e.value)} // Handle change event to update selected project
                  style={{ height: "38px" }}
                  required
                />
              </div>

              <div className="assignment-module-title">
                <div className="project-label">
                  <text>role</text>
                  <p1 id="2">*</p1>
                </div>
                <DropDownListComponent
                  className="setting-diagram-projectClient-input"
                  placeholder="Select Role"
                  dataSource={roleData} // Set project data as dataSource
                  fields={{ text: "roleName", value: "_id" }} // Define the fields to display
                  value={selectedRole} // Bind selectedproject state to the value property
                  change={(e) => setSelectedRole(e.value)} // Handle change event to update selected project
                  style={{ height: "38px" }}
                  required
                />
              </div>
            </div>
            <div className="assignment-button-frame">
              <button className="assignment-cancel-button" onClick={onClose}>
                <div className="assignment-cancel-button-label">
                  <text>Cancel</text>
                </div>
              </button>
              <button
                className="assignment-create-button"
                onClick={handleUpdate
                }
              >
                <div className="assignment-create-button-label">
                  <text>Update</text>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateAdminUser;
