// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 80vh;
}
.Auth-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
}
.Auth-img-container {
  display: flex;
  width: 350px;
  justify-content: center;
  align-items: center;
}

.Auth-form {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  padding: 10px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}
.error
  {display:block;
    color:red;
   
  }
  .btn
  {
    width:22rem;
  }`, "",{"version":3,"sources":["webpack://./src/components/SignUp/index.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,YAAY;AACd;AACA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,yCAAyC;AAC3C;AACA;EACE,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,sBAAsB;AACxB;AACA;GACG,aAAa;IACZ,SAAS;;EAEX;EACA;;IAEE,WAAW;EACb","sourcesContent":[".App {\r\n  background-color: white;\r\n}\r\n\r\n.Auth-form-container {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  width: 100vw;\r\n  height: 80vh;\r\n}\r\n.Auth-container {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  justify-content: center;\r\n  align-items: center;\r\n  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;\r\n}\r\n.Auth-img-container {\r\n  display: flex;\r\n  width: 350px;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.Auth-form {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  width: 350px;\r\n  padding: 10px;\r\n  border-radius: 8px;\r\n  background-color: white;\r\n}\r\n\r\n.Auth-form-content {\r\n  padding-left: 12%;\r\n  padding-right: 12%;\r\n}\r\n\r\n.Auth-form-title {\r\n  text-align: center;\r\n  margin-bottom: 1em;\r\n  font-size: 24px;\r\n  color: rgb(34, 34, 34);\r\n  font-weight: 800;\r\n}\r\n\r\nlabel {\r\n  font-size: 14px;\r\n  font-weight: 600;\r\n  color: rgb(34, 34, 34);\r\n}\r\n.error\r\n  {display:block;\r\n    color:red;\r\n   \r\n  }\r\n  .btn\r\n  {\r\n    width:22rem;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
