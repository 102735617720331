import React from "react";
import {
  AiFillFacebook,
  AiFillTwitterCircle,
  AiFillInstagram,
  AiFillApple,
} from "react-icons/ai";
import "./index.css";
import { FaGooglePlay } from "react-icons/fa";
import logo_entertainment from "../../images/logo1.jpg";

const Footer = () => {
  return (
    <div className="footer">
      <p>
        MediaMesh is copyright Entertainment Technologists. <br />
        For more information visit{" "}
        <a
          href="https://www.entertainmenttechnologists.com"
          style={{
            color: "#FFF",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "140%",
            textDecorationLine: "underline",
          }}
        >
          www.entertainmenttechnologists.com
        </a>
      </p>
    </div>
  );
};
export default Footer;
