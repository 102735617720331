import "./index.css";
import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import SearchClient from "../../../images/Logos/search.png";
import Loader from "../../Common/Loader";
import UpdateClient from "./UpdateClient";
import {
  useGetClientsQuery,
  useAddClientMutation,
  useDeleteClientMutation,
  useEditClientMutation,
} from "../../../redux/apiSlice";

const AdminClient = () => {
  const { data, isLoading: loading, error } = useGetClientsQuery();
  const [addClient, { isLoading: addClientLoading, error: addClientError }] =
    useAddClientMutation();
  const [
    deleteClient,
    { isLoading: loadingDeleteClient, error: errorDeleteClient },
  ] = useDeleteClientMutation();
  const [editClient, { isLoading: loadingEditClient, error: errorEditClient }] =
    useEditClientMutation();
  const [companyName, setCompanyName] = useState("");
  const [contactName, setContactName] = useState("");
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [clientData, setClientData] = useState([]);
  const [filteredClientData, setFilteredClientData] = useState([]);
  const [searchCompanyName] = useState("");
  const [searchContactName] = useState("");
  const [searchPrimaryEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [editdata, setEditData] = useState({});
  // Clear input fields after successful submission
  const clearFields = () => {
    setCompanyName("");
    setContactName("");
    setPrimaryEmail("");
  };
  //On closing edit window
  const onClose = () => {
    setEditClicked(false);
  };
  const onUpdate = (client) => {
    editClientFromTable(client);
    onClose();
  };
  //On Adding client
  const onFormSubmit = async (e) => {
    e.preventDefault();

    if (submitting) return;

    try {
      setSubmitting(true);

      if (!isFormValid) {
        enqueueSnackbar("Invalid Email!", {
          variant: "error",
        });
        return;
      }
      // Dispatch action to create new client
      const response = await addClient({
        companyName,
        contactName,
        primaryEmail,
      }).unwrap();
      console.log("Success!", response);
      enqueueSnackbar(response.message, { variant: "success" });
      clearFields();
    } catch (error) {
      enqueueSnackbar("Failed to add client. Please try again.", {
        variant: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };
  //On deleting client
  const deleteClientFromTable = async (id) => {
    try {
      // Dispatch action to delete  client
      const response = await deleteClient(id).unwrap();
      if (!loadingDeleteClient) {
        if (response) {
          enqueueSnackbar(response.message, {
            variant: "success",
          });
        }
        if (errorDeleteClient) {
          enqueueSnackbar(errorDeleteClient.message, {
            variant: "error",
          });
        }
      }
    } catch (error) {
      enqueueSnackbar("An unknown error occurred", {
        variant: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };
   useEffect(() => {
    if (!loading) {
      if (data) {
        setClientData(data.data);
        setFilteredClientData(data.data);
      }
      if (error) {
        enqueueSnackbar(`Failed to load clients`, {
          variant: "error",
        });

        setClientData([]);
        setFilteredClientData([]);
      }
    }
  }, [loading, data, error]);
  //On editing client
  const editClientFromTable = async (data) => {
    try {
      // Dispatch action to edit  client
      const response = await editClient(data).unwrap();
      if (!loadingEditClient) {
        if (response) {
          enqueueSnackbar(response.message, {
            variant: "success",
          });
        }
        if (errorEditClient) {
          enqueueSnackbar(errorEditClient.message, {
            variant: "error",
          });
        }
      }
    } catch (error) {
      enqueueSnackbar("An unknown error occurred", {
        variant: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };
  const showEditWindow = (data) => {
    setEditClicked(true);
    setEditData(data);
  };
  const onCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
  };

  const onContactNameChange = (e) => {
    setContactName(e.target.value);
  };

  const validateForm = () => {
    // Validate email and update email error state
    if (!validEmailId(primaryEmail)) {
      setEmailError("Invalid email Address!");
      setIsFormValid(false);
    } else {
      setEmailError("");
      setIsFormValid(true);
    }
  };

  useEffect(() => {
    if (primaryEmail) {
      validateForm();
    }
  }, [primaryEmail]);
  const onPrimaryEmailChange = (e) => {
    const email = e.target.value;
    setPrimaryEmail(email);
  };

  const validEmailId = (email) => {
    const regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEmail.test(String(email).toLowerCase());
  };
  useEffect(() => {
    if (!loading) {
      if (data) {
        setClientData(data.data);
        setFilteredClientData(data.data);
      }
      if (error) {
        enqueueSnackbar(`Failed to load clients`, {
          variant: "error",
        });

        setClientData([]);
        setFilteredClientData([]);
      }
    }
  }, [loading, data, error]);
  useEffect(() => {
    if (!loading) {
      if (data) {
        setClientData(data.data);
        setFilteredClientData(data.data);
      }
      if (error) {
        enqueueSnackbar(`Failed to load clients`, {
          variant: "error",
        });
        setClientData([]);
        setFilteredClientData([]);
      }
    }
  }, [clientData]);

  //Search items from client table:
  useEffect(() => {
    const filteredData = clientData.filter(
      (item) =>
        item.companyName
          .toLowerCase()
          .includes(searchCompanyName.toLowerCase()) &&
        item.contactName
          .toLowerCase()
          .includes(searchContactName.toLowerCase()) &&
        item.primaryEmail
          .toLowerCase()
          .includes(searchPrimaryEmail.toLowerCase())
    );
    setFilteredClientData(
      filteredData.map((item) => ({
        ...item,
        companyName: item.companyName,
      }))
    );
  }, [searchCompanyName, searchContactName, searchPrimaryEmail]);
  const searchClient=(args)=>{
    const filterdData=[...clientData];
    if (args?.target.value !== "") {
      const searchedFound = filterdData?.filter((element) => {
        if ((element.companyName.toLowerCase()).includes(args.target.value.toLowerCase())) {
          return element;
        }
      });
      setFilteredClientData(searchedFound)
    } 
    else{
      setFilteredClientData(clientData)
    }
  }
 

  return (
    <div className="setting-client-diagram-container-overview">
      <text>Clients</text>
      <div className="setting-client-diagram-frame">
        <form
          className="setting-client-diagram-heading"
          onSubmit={onFormSubmit}
        >
          <text>New Client</text>
          <div className="setting-client-heading-companyname">
            <div className="setting-client-heading-companyname-label">
              <p4>Company Name</p4>
              <p5 id="2">*</p5>
            </div>
            <input
              className="setting-client-heading-companyname-input"
              placeholder="e.g. Apple Computers"
              name="companyName"
              value={companyName}
              onChange={onCompanyNameChange}
              required
            />
          </div>
          <div className="setting-diagram-projectName-frame">
            <div className="setting-client-heading-contactEmail">
              <div className="setting-client-heading-contactEmail-label">
                <p4>Primary Contact Name</p4>
                <p5 id="2">*</p5>
              </div>
              <input
                className="setting-client-heading-contactEmail-input"
                placeholder="e.g. Apple Computers"
                name="contactName"
                value={contactName}
                onChange={onContactNameChange}
                required
              />
            </div>
            <div className="setting-client-heading-contactEmail">
              <div className="setting-client-heading-contactEmail-label">
                <p4>Primary Contact Email</p4>
                <p5 id="2">*</p5>
              </div>
              <input
                className="setting-client-heading-contactEmail-input"
                placeholder="e.g. Apple Computers"
                name="primaryEmail"
                value={primaryEmail}
                onChange={onPrimaryEmailChange}
                required
              />
            </div>
          </div>
          <button disabled={submitting} className="setting-client-create">
            <div className="setting-client-create-label">
              <text>Create New Client</text>
            </div>
          </button>
        </form>
        <div className="setting-diagram-clients">
          <text>Clients</text>
          <div className="setting-diagram-Projects-search">
            <input
              className="setting-diagram-Projects-search-input"
              onChange={(e) => {
                searchClient(e);
              }}
              placeholder="Search Clients"
            ></input>{" "}
            <span>
              <img src={SearchClient} />
            </span>
          </div>
          <div className="setting-diagram-clients-table-frame">
            <table className="setting-diagram-clients-table">
              <thead>
                <tr className="setting-diagram-clients-table-hdr">
                  <th className="setting-diagram-clients-hdr">
                    <p3>Company Name</p3>
                  </th>
                  <th className="setting-diagram-clients-company">
                    <p3>Contact</p3>
                  </th>
                  <th className="setting-diagram-clients-email">
                    <p3>Email</p3>
                  </th>
                  <th className="setting-diagram-clients-action">
                    <p3>Actions</p3>
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <Loader />
                ) : (
                  filteredClientData.map((item) => (
                    <tr
                      key={item._id}
                      className="setting-diagram-clients-table-body"
                    >
                      <td className="setting-diagram-body-clients">
                        <p4>{item.companyName}</p4>
                      </td>
                      <td className="setting-diagram-company-clients">
                        <p4>{item.contactName}</p4>
                      </td>
                      <td className="setting-diagram-email-clients">
                        <p4>{item.primaryEmail}</p4>
                      </td>
                      <td className="setting-diagram-status-clients">
                        <button
                          className="setting-clients-diagram-status-edit"
                          onClick={(e) => showEditWindow(item)}
                        >
                          <div className="setting-clients-diagram-status-edit-label">
                            <text>Edit</text>
                          </div>
                        </button>
                        <button
                          className="setting-clients-diagram-status-delete"
                          onClick={(e) => deleteClientFromTable(item._id)}
                        >
                          <div className="setting-clients-diagram-status-delete-label">
                            <text>Delete</text>
                          </div>
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <div className="setting-client-scrollbar"></div>
            {editClicked && (
              <UpdateClient
                onClose={onClose}
                client={editdata}
                onUpdate={onUpdate}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminClient;
