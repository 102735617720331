import React, { useState, useEffect, useRef } from "react";
import lockButton from "../../images/add_circle_outline.png";
import lock from "../../images/lock.png";
import { ListViewComponent } from "@syncfusion/ej2-react-lists";
const TemplateWindow = ({
  workflowData,
  workflowFields,
  workflowText,
  workflowQuery,
  selectWorkflow,
  newDiagram,
}) => {
  const [filteredWorkflow, setFilteredworkflow] = useState(workflowData);

  function listTemplate(data) {
    return (
      <div className="list-wrapper">
        <span className="list-content">{data.name}</span>
        <img src={lock} alt="lock_img" className="lock" />
      </div>
    );
  }
  let listviewInstance = null;
  const newObject = { item: { tooltipText: "New Diagram" } };
  let itemsRendered = 2;
  let itemPerScroll = 5;
  let result = [];
  const onListScrolled = (args) => {
    if (args.scrollDirection === "Bottom" && args.distanceY < 100) {
      if (itemsRendered < workflowData.length) {
        const startIndex = itemsRendered;
        const endIndex = Math.min(
          itemsRendered + itemPerScroll,
          workflowData.length
        );
        result = workflowData.slice(startIndex, endIndex);
        listviewInstance?.addItem(result);
        itemsRendered = endIndex;
      }
    }
  };
  const searchWorkflow = (args) => {
    if (args?.target.value !== "") {
      const searchedFound = workflowData?.filter((element) => {
        if ((element.name.toLowerCase()).includes(args.target.value.toLowerCase())) {
          return element;
        }
      });
      setFilteredworkflow(searchedFound);
    } else {
      setFilteredworkflow(workflowData);
    }
  };

  return (
    <div className="template-content">
      <center>
        <h1 className="template-header">Select a prebuilt workflow, or build your own</h1>
        <p className="template-subtitle">
        Choose from our prebuilt templates or start fresh with a blank canvas.
        </p>
      </center>
      <div className="fileCardContainer">
        <div className="file-card" onClick={(e) => newDiagram(newObject)}>
          <img src={lockButton} alt="lock_img" width="24px" height="24px"></img>
          <p className="file-card-name">New File</p>
        </div>
        <text className="list-template-header">Workflow Templates</text>

        <input
          className="searchTemplateBar"
          placeholder="Search for a template"
          type="text"
          onChange={(e) => searchWorkflow(e)}
        ></input>
       
          <ListViewComponent
            id="workflow"
            dataSource={filteredWorkflow}
            ref={(scope) => {
              listviewInstance = scope;
            }}
            fields={workflowFields}
            text={workflowText}
            query={workflowQuery}
            popupHeight="300px"
            template={listTemplate}
            select={selectWorkflow.bind(this)}
            scroll={onListScrolled.bind(this)}
          />
        </div>
     
    </div>
  );
};

export default TemplateWindow;
