import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const apiBaseUrl = process.env.REACT_APP_API_URL;
export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: customFetchBaseQuery,
  endpoints: (build) => ({
    getAdminUsers: build.query({
      query: () => "accountsettings/getAdminUsers",
      providesTags: ["AdminUsers"],
    }),
    getUsers: build.query({
      query: (data) => `accountsettings/getUsers/${data}`,
      providesTags: ["AdminUsers"],
    }),
    addAdminUser: build.mutation({
      query: (data) => {
        return {
          url: `/accountsettings/addAdminUser`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["AdminUsers"],
    }),
    addUser: build.mutation({
      query: (data) => {
        return {
          url: `/accountsettings/addUser`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["AdminUsers"],
    }),
    deleteAdminUser: build.mutation({
      query: (data) => {
        return {
          url: `/accountsettings/deleteAdminUser/${data}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["AdminUsers"],
    }),
    
    editAdminUser: build.mutation({
      query: (data) => {
        return {
          url: `/accountsettings/editAdminUser/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["AdminUsers"],
    }),
    editUser: build.mutation({
      query: (data) => {
        return {
          url: `/accountsettings/editUser/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["AdminUsers"],
    }),
    getUserRoles: build.query({
      query: () => {
        return {
          url: `/accountsettings/getUserRoles/admin`,
          method: "GET",
        };
      },
    }),
    getUserGroup: build.query({
      query: () => {
        return {
          url: `/accountsettings/getUserGroup/admin`,
          method: "GET",
        };
      },
    }),
    getPrivileges: build.query({
      query: () => {
        return {
          url: `/accountsettings/getPrivileges`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetAdminUsersQuery,
  useGetUsersQuery,
  useAddAdminUserMutation,
  useDeleteAdminUserMutation,
  useEditAdminUserMutation,
  useGetUserRolesQuery,
  useGetUserGroupQuery,
  useEditUserMutation,
  useAddUserMutation,
  useGetPrivilegesQuery
} = userApi;

function customFetchBaseQuery(args, api, extraOptions) {
  const { getState } = api; // Access getState from the api object
  const { token } = getState().auth;
  return fetchBaseQuery({
    baseUrl: apiBaseUrl,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
    ...extraOptions,
  })(args, api, extraOptions);
}
