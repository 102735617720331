import "./index.css";
import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import Loader from "../../Common/Loader";
import { TabComponent } from "@syncfusion/ej2-react-navigations";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";


const ProjectConfig = () => {
 

  return (
    <div className="setting-client-diagram-container-overview">
      <text>Project Configuration</text>

      <form className="setting-project-config-diagram-heading">
        <div className="setting-project-config-total">
          <div className="setting-project-config-heading">
            <div className="setting-project-config-heading">
              <p4>Client Name</p4>
              <p5 id="2"></p5>
              <input
                className="setting-project-config-heading-input"
                placeholder="Select Client"
                name="contactName"
                value={""}
                onChange={""}
                required
              />
            </div>
          </div>
          <div className="setting-project-config-heading">
            <div className="setting-project-config-heading">
              <p4>Project Name</p4>
              <p5 id="2"></p5>
              <input
                className="setting-project-config-heading-input"
                placeholder="Select Project"
                name="contactName"
                value={""}
                onChange={""}
                required
              />
            </div>
          </div>
        </div>
      </form>
      <div className="setting-diagram-clients">
        <TabComponent id="defaultTab">
          <div className="e-tab-header">
            <div> Cloud</div>
            <div> API </div>
          </div>
          <div className="e-content">
            <div>
              <div className="configuration-headers">
                <text>Cloud Configuration</text>
              </div>
              <div className="frame-13">
                <div className="frame-12">
                  <div className="setting-project-config-heading">
                    <div className="setting-project-config-heading">
                      <div className="project-config-label">
                        <p4>Region</p4>
                        <p5 id="2">*</p5>
                      </div>
                      <input
                        className="setting-project-config-heading-input"
                        name="contactName"
                        value={""}
                        onChange={""}
                        required
                      />
                    </div>
                  </div>
                  <div className="setting-project-config-heading">
                    <div className="setting-project-config-heading">
                      <div className="project-config-label">
                        <p4>Bucket Name</p4>
                        <p5 id="2">*</p5>
                      </div>
                      <input
                        className="setting-project-config-heading-input"
                        name="contactName"
                        value={""}
                        onChange={""}
                        required
                      />
                    </div>
                  </div>
                  <div className="setting-project-config-heading">
                    <div className="setting-project-config-heading">
                      <div className="project-config-label">
                        <p4>Access Key</p4>
                        <p5 id="2">*</p5>
                      </div>
                      <input
                        className="setting-project-config-heading-input"
                        name="contactName"
                        value={""}
                        onChange={""}
                        required
                      />
                    </div>
                  </div>
                  <div className="setting-project-config-heading">
                    <div className="setting-project-config-heading">
                      <div className="project-config-label">
                        <p4>Secret Key</p4>
                        <p5 id="2">*</p5>
                      </div>
                      <input
                        className="setting-project-config-heading-input"
                        name="contactName"
                        value={""}
                        onChange={""}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="grey-text-frame">
                  <text className="grey-text-frame-header">
                    Secure Your Cloud Configuration with Confidence
                  </text>
                  <p>
                    At MediaMesh, we prioritize your privacy and the security of
                    your data. Rest assured that your Cloud Configuration
                    details are safe with us. We use this information solely to
                    enhance your project settings and optimize your workflow
                    performance.
                    <br />
                    <br />
                    <li>
                      Confidentiality Guaranteed: We never share your data.
                    </li>{" "}
                    <li>
                      Purposeful Use: Your details are used only to enhance your
                      experience.
                    </li>
                    <li>
                      Robust Security: Advanced protections keep your data
                      secure.
                    </li>
                    <br />
                    Configure your cloud settings with confidence and get the
                    most out of MediaMesh.
                  </p>
                </div>
              </div>
              <button className="setting-client-create">
                <div className="setting-client-create-label">
                  <text>Save Cloud</text>
                </div>
              </button>
            </div>
            <div>
              <div className="configuration-headers">
                <text>API Configuration</text>
              </div>
              <div className="frame-13">
                <div className="frame-12">
                  <div className="setting-project-config-heading">
                    <div className="setting-project-config-heading">
                      <div className="project-config-label">
                      <p4>Vendor</p4>
                      <p5 id="2"></p5>
                    </div>
                    <DropDownListComponent
                      className="setting-client-heading-companyname-input"
                      name="companyName"
                      value={""}
                      onChange={""}
                      required
                      style={{ height: "38px" }}
                    />
                  </div>
                  </div>
                  <div className="setting-project-config-heading">
                    <div className="setting-project-config-heading">
                      <div className="project-config-label">
                        <p4>Select Api</p4>
                        <p5 id="2">*</p5>
                      </div>
                      <DropDownListComponent
                        className="setting-client-heading-contactName-input"
                        name="contactName"
                        value={""}
                        onChange={""}
                        required
                        style={{ height: "38px" }}
                      />
                    </div>
                  </div>
                  <div className="setting-project-config-heading">
                    <div className="setting-project-config-heading">
                      <div className="project-config-label">
                        <p4>User Name</p4>
                        <p5 id="2">*</p5>
                      </div>
                      <input
                        className="setting-project-config-heading-input"
                        name="contactName"
                        value={""}
                        onChange={""}
                        required
                      />
                    </div>
                  </div>
                  <div className="setting-project-config-heading">
                    <div className="setting-project-config-heading">
                      <div className="project-config-label">
                        <p4>Password</p4>
                        <p5 id="2">*</p5>
                      </div>
                      <input
                        className="setting-project-config-heading-input"
                        name="contactName"
                        value={""}
                        onChange={""}
                        required
                      />
                    </div>
                  </div>
                  <div className="setting-project-config-heading">
                    <div className="setting-project-config-heading">
                      <div className="project-config-label">
                        <p4>Licence Key</p4>
                        <p5 id="2">*</p5>
                      </div>
                      <input
                        className="setting-project-config-heading-input"
                        name="contactName"
                        value={""}
                        onChange={""}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="button-margin"></div>
              <button className="setting-client-create">
                <div className="setting-client-create-label">
                  <text>Save API</text>
                </div>
              </button>
            </div>
          </div>
        </TabComponent>
      </div>
    </div>
  );
};

export default ProjectConfig;
