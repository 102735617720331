import "./index.css";
import SearchClient from "../../../images/Logos/search.png";
import AdminUserOverlay from "./adminUserOverlay";
import Loader from "../../Common/Loader";
import React, { useState, useEffect } from "react";
import {
  DropDownListComponent,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import { useGetClientsQuery } from "../../../redux/apiSlice";
import { useGetProjectsQuery } from "../../../redux/projectapiSlice";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import {
  useGetUsersQuery,
  useDeleteAdminUserMutation,
  useEditUserMutation,
  useAddUserMutation,
  useGetUserRolesQuery,
  useGetPrivilegesQuery,
} from "../../../redux/userSlice";
const AdminProject = () => {
  const { userGroup,projectName } = useSelector((state) => state.auth);
  //Get users according to 
  const {
    data: dataUsers,
    isLoading: loadingUsers,
    error: errorUsers,
  } = useGetUsersQuery(projectName._id);
  const { enqueueSnackbar } = useSnackbar();
  const [contactName, setContactName] = useState("");
  const [primaryEmail, setPrimaryEmail] = useState("");
  const { data, isLoading: loading, error } = useGetClientsQuery();
  const [cliendData, setClientData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [userData, setUserData] = useState([]);
  const [priviligeData, setPrivilegeData] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [editClicked, setEditClicked] = useState(false);
  const [editdata, setEditData] = useState({});
  const [priviliges, setPrivileges] = useState([]);
  const [addUser] = useAddUserMutation();
  const [filteredData, setFilteredData] = useState([]);
  const validateForm = () => {
    // Validate email and update email error state
    if (!validEmailId(primaryEmail)) {
      setEmailError("Invalid email Address!");
      setIsFormValid(false);
    } else {
      setEmailError("");
      setIsFormValid(true);
    }
  };

  useEffect(() => {
    if (primaryEmail) {
      validateForm();
    }
  }, [primaryEmail]);
  const onPrimaryEmailChange = (e) => {
    const email = e.target.value;
    setPrimaryEmail(email);
  };

  const validEmailId = (email) => {
    const regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEmail.test(String(email).toLowerCase());
  };

  const onContactNameChange = (e) => {
    setContactName(e.target.value);
  };

  //On Deleting a mapping
  const handleDelete = async (id) => {
    try {
      // Dispatch action to delete Admin user
      const response = await deleteAdminUser(id).unwrap();
      if (!loadingDeleteMapping) {
        if (response) {
          enqueueSnackbar(response.message, {
            variant: "success",
          });
        }
        if (errorDeleteMapping) {
          enqueueSnackbar(errorDeleteMapping.message, {
            variant: "error",
          });
        }
      }
    } catch (error) {
      enqueueSnackbar("An unknown error occurred", {
        variant: "error",
      });
    }
  };

  //Show edit Window
  const showEditWindow = (data) => {
    setEditClicked(true);
    setEditData(data);
  };
  //On closing edit window
  const onClose = () => {
    setEditClicked(false);
  };

  //On Update AdminUser
  const onUpdate = (projectMapping) => {
    editAdminFromTable(projectMapping);
    onClose();
  };
  // Clear input fields after successful submission
  const clearFields = () => {
    setPrivileges([]);
    setSelectedRole(null);
    setContactName("");
    setPrimaryEmail("");
  };

  const {
    data: dataPrivileges,
    isLoading: loadingPrivileges,
    error: errorPriviliges,
  } = useGetPrivilegesQuery();
  const {
    data: dataProjects,
    isLoading: loadingProjects,
    error: errorprojects,
  } = useGetProjectsQuery();
  const [
    deleteAdminUser,
    { isLoading: loadingDeleteMapping, error: errorDeleteMapping },
  ] = useDeleteAdminUserMutation();
  const [
    editAdminUser,
    { isLoading: loadingEditAdminUser, error: errorEditAdminUser },
  ] = useEditUserMutation();

  //Get User roles for role dropdown
  const {
    data: dataRoles,
    isLoading: loadingRoles,
    error: errorRoles,
  } = useGetUserRolesQuery();
  //Get Client Data for client dropdown
  useEffect(() => {
    if (!loading) {
      if (data) {
        setClientData(data.data);
      }
      if (error) {
        enqueueSnackbar(`Failed to load clients!`, {
          variant: "error",
        });
      }
    }
  }, [loading, data, error]);
  //Get Project Data for project dropdown
  useEffect(() => {
    if (!loadingProjects) {
      if (dataProjects?.data) {
        setProjectData(dataProjects?.data);
      }
      if (errorprojects) {
        enqueueSnackbar(`Failed to load projects`, {
          variant: "error",
        });

        setProjectData([]);
      }
    }
  }, [loadingProjects, dataProjects, errorprojects]);

  //getting user roles for dropdown
  useEffect(() => {
    if (!loadingRoles) {
      if (dataRoles) {
        setRoleData(dataRoles.data);
      }
      if (errorRoles) {
        enqueueSnackbar(`Failed to load user roles!`, {
          variant: "error",
        });
      }
    }
  }, [loadingRoles, dataRoles, errorRoles]);
  //Getting User Data from backend
  useEffect(() => {
    if (!loadingUsers) {
      if (dataUsers) {
        setUserData(dataUsers.data);
        setFilteredData(dataUsers.data);
      }
      if (errorUsers) {
        enqueueSnackbar(`Failed to load users information!`, {
          variant: "error",
        });
      }
    }
  }, [loadingUsers, dataUsers, errorUsers]);
  //On editing Project
  const editAdminFromTable = async (data) => {
    try {
      // Dispatch action to edit Admin user mapping
      const response = await editAdminUser(data).unwrap();
      if (!loadingEditAdminUser) {
        if (response) {
          enqueueSnackbar(response.message, {
            variant: "success",
          });
        }
        if (errorEditAdminUser) {
          enqueueSnackbar(errorEditAdminUser.message, {
            variant: "error",
          });
        }
      }
    } catch (error) {
      enqueueSnackbar("An unknown error occurred", {
        variant: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };
 
  //On Adding project mapping
  const onFormSubmit = async (e) => {
    e.preventDefault();

    if (submitting) return;

    try {
      setSubmitting(true);

      if (!isFormValid) {
        enqueueSnackbar("Invalid Email!", {
          variant: "error",
        });
        return;
      }
      // Dispatch action to create new client
      const response = await addUser({
        userGroup: userGroup,
        role: selectedRole,
        priviliges: priviliges,
        contactName,
        primaryEmail,
      }).unwrap();
      clearFields();
      if (response.data) {
        enqueueSnackbar(
          `New user was created successfuly with password ${response.data}`,
          { variant: "success" }
        );
      }
      enqueueSnackbar(response.message, { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Failed to add User. Please try again.", {
        variant: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };
  //Get Privelege Data for privileges dropdown
  useEffect(() => {
    if (!loadingPrivileges) {
      if (dataPrivileges.data) {
        setPrivilegeData(dataPrivileges.data);
      }
      if (errorPriviliges) {
        enqueueSnackbar(`Failed to load privileges!`, {
          variant: "error",
        });
      }
    }
  }, [loadingPrivileges, dataPrivileges, errorPriviliges]);
  const searchAdminUser = (args) => {
    const filterdData = [...userData];
    if (args?.target.value !== "") {
      const searchedFound = filterdData?.filter((element) => {
        if (
          element.primaryEmail
            .toLowerCase()
            .includes(args.target.value.toLowerCase())
        ) {
          return element;
        }
      });
      setFilteredData(searchedFound);
    } else {
      setFilteredData(userData);
    }
  };

  return (
    <div className="setting-diagram-container">
      <div className="setting-diagram-container-overview">
        <text>Users</text>
       
        <div className="setting-diagram-frame">
        
          <form
          className="setting-diagram-users-invite"
          onSubmit={onFormSubmit}
        >
            <text>Invite User(s)</text>
            <div className="setting-diagram-projectName-frame">
              <div className="setting-admin-heading-username-label">
                <div className="setting-admin-heading-usernames">
                  <p4>New user Name</p4>
                  <p5 id="2">*</p5>
                </div>
                <input
                  className="setting-admin-heading-companyname-input"
                  placeholder="Enter Admin Name"
                  value={contactName}
                  onChange={onContactNameChange}
                  required
                />
              </div>
              <div className="setting-diagram-projectName">
                <div className="setting-diagram-projectName-label">
                  <p1>Email</p1>
                  <p2>*</p2>
                </div>
                <input
                  className="setting-admin-heading-email-input"
                  placeholder="enter email"
                  value={primaryEmail}
                  onChange={onPrimaryEmailChange}
                  required
                />
              </div>
            </div>

            <div className="setting-diagram-projectClient">
              <div className="setting-diagram-projectClient-label">
                <p1>Role</p1>
                <p2>*</p2>
              </div>
              <DropDownListComponent
                className="setting-admin-heading-email-input"
                placeholder="Select Role"
                dataSource={roleData} // Set project data as dataSource
                fields={{ text: "roleName", value: "_id" }} // Define the fields to display
                value={selectedRole} // Bind selectedproject state to the value property
                change={(e) => setSelectedRole(e.value)} // Handle change event to update selected project
                style={{ height: "38px" }}
                popupHeight="160"
              />
            </div>

            <div className="setting-diagram-projectClient">
              <div className="privilages-container-frame">
                <text>privilegies</text>
                <p1></p1>
              </div>
              <MultiSelectComponent
                id="privileges"
                dataSource={priviligeData}
                fields={{ text: "privilegeName", value: "_id" }}
                style={{ height: "40px" }}
                change={(e) => {
                  setPrivileges(e.value);
                }}
                popupHeight="160"
                placeholder="Select Privileges"
              />
            </div>
            <button className="privilages-user-button" >
              <div className="privilages-user-button-label">
                <text>Invite user</text>
              </div>
            </button>
            </form>
  
          
          <div className="setting-diagram-Projects">
            <text>Project Users</text>
            <div className="setting-diagram-Projects-search">
              <input
                className="setting-diagram-Projects-search-input"
                onChange={(e) => {
                  searchAdminUser(e);
                }}
                placeholder="Search Users"
              ></input>{" "}
              <span>
                <img src={SearchClient} />
              </span>
            </div>
            <div className="setting-diagram-Projects-table">
              <table>
                <thead>
                  <tr className="setting-diagram-Projects-table-hdr">
                    <th className="setting-diagram-Project-hdr">
                      <p3>Project</p3>
                    </th>
                    <th className="setting-diagram-Project-hdr">
                      <p3>Name</p3>
                    </th>
                    <th className="setting-diagram-Project-company">
                      <p3>Email</p3>
                    </th>
                    <th className="setting-diagram-Project-email">
                      <p3>Role</p3>
                    </th>
                    <th className="setting-diagram-Project-action">
                      <p3>Actions</p3>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loadingUsers ? (
                    <Loader />
                  ) : (
                    filteredData.length > 0 &&
                    filteredData.map((item, index) => (
                      <tr
                        key={index}
                        className="setting-diagram-admin-table-body"
                      >
                        <td className="setting-diagram-body-admin">
                          <p4>{item.projectName.projectName}</p4>
                        </td>
                        <td className="setting-diagram-company-admin">
                          <p4>{item.clientName.companyName}</p4>
                        </td>
                        <td className="setting-diagram-email-admin">
                          <p4>{item.primaryEmail}</p4>
                        </td>
                        <td className="setting-diagram-email-admin">
                          <p4>{item.role.roleName}</p4>
                        </td>
                        <td className="setting-diagram-status-admin">
                          <button
                            className="setting-admin-diagram-status-edit"
                            onClick={() => showEditWindow(item)}
                          >
                            <div className="setting-admin-diagram-status-edit-label">
                              <text>Edit</text>
                            </div>
                          </button>
                          <button
                            className="setting-admin-diagram-status-delete"
                            onClick={(e) => handleDelete(item._id)}
                          >
                            <div className="setting-admin-diagram-status-delete-label">
                              <text>Delete</text>
                            </div>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <div className="setting-client-scrollbar">
                {editClicked && (
                  <AdminUserOverlay
                    onClose={onClose}
                    projectMapping={editdata}
                    onUpdate={onUpdate}
                    roleData={roleData}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  );
};

export default AdminProject;
