import "../DomainStatus/index.css";
import "../MonitorPreview/index.css";
import "../MonitorApproval/index.css";
import { useState, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useSelector, useDispatch } from 'react-redux';
import Button from "../Common/Button/index.jsx";
// import { Player } from "video-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  execution,
  conductor,
  clearAll,
  getExecutionData as getWorkflowsSentForExecution,
  clearAllDomaindata,
  fixScheduler,
} from "../../redux/executionSlice.js";
import Scheduler from "../Common/Scheduler/index.js";

function MonitorApproval() {
  const [videoSource, setVideoSource] = useState('');
  const [selectedCheckBox, setSelectedCheckBox] = useState(null)

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [searchWorkflowId, setSearchWorkflowId] = useState("");
  const [searchWorkflowName, setSearchWorkflowName] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filteredDomainData, setFilteredDomainData] = useState([]);
  const [props, setProps] = useState(null);

  const [dateTime, setDateTime] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { loading, data, error } = useSelector((state) => state.execution);

const handleClose = () => {
   };

   const handleCheckBoxChange = (filename) =>{
    if(selectedCheckBox === filename){
      setSelectedCheckBox(null);
      setVideoSource('');}
    else{
      setSelectedCheckBox(filename);
      setVideoSource(filename);}
    }

    const handleRowClick = (filename) => {
      if (selectedCheckBox === filename) {
        setSelectedCheckBox(null);
        setVideoSource('');
      } else {
        setSelectedCheckBox(filename);
        setVideoSource(filename);
      }
    };
  
  const sampleData = [
      {
        id: '0000',
        type: 'jpg',
        filename: 'sample_1.mp4',
        duration: '00:00:00.00',
        creation: 'Jan 1st 2024'
      },
      {
        id: '0000',
        type: 'mov',
        filename: 'sample_2.mov',
        duration: '00:00:00.00',
        creation: 'Jan 1st 2024'
      },
    {
      id: "0000",
      type: "mov",
      filename: "showfilenameEp01",
      duration: "00:00:00.00",
      creation: "Jan 1st 2024",
    },
    {
      id: "0000",
      type: "jpg",
      filename: "showfilenameEp01",
      duration: "00:00:00.00",
      creation: "Jan 1st 2024",
    },
    {
      id: "0000",
      type: "mov",
      filename: "showfilenameEp01",
      duration: "00:00:00.00",
      creation: "Jan 1st 2024",
    },
    {
      id: "0000",
      type: "jpg",
      filename: "showfilenameEp01",
      duration: "00:00:00.00",
      creation: "Jan 1st 2024",
    },
    {
      id: "0000",
      type: "mov",
      filename: "showfilenameEp01",
      duration: "00:00:00.00",
      creation: "Jan 1st 2024",
    },
    {
      id: "0000",
      type: "jpg",
      filename: "showfilenameEp01",
      duration: "00:00:00.00",
      creation: "Jan 1st 2024",
    },

    {
      id: "0000",
      type: "jpg",
      filename: "showfilenameEp01",
      duration: "00:00:00.00",
      creation: "Jan 1st 2024",
    },
    {
      id: "0000",
      type: "mov",
      filename: "showfilenameEp01",
      duration: "00:00:00.00",
      creation: "Jan 1st 2024",
    },

    {
      id: "0000",
      type: "jpg",
      filename: "showfilenameEp01",
      duration: "00:00:00.00",
      creation: "Jan 1st 2024",
    },
    {
      id: "0000",
      type: "mov",
      filename: "showfilenameEp01",
      duration: "00:00:00.00",
      creation: "Jan 1st 2024",
    },
    {
      id: "0000",
      type: "Type A",
      filename: "showfilenameEp01",
      duration: "00:00:00.00",
      creation: "Jan 1st 2024",
    },
    {
      id: "0000",
      type: "Type A",
      filename: "showfilenameEp01",
      duration: "00:00:00.00",
      creation: "Jan 1st 2024",
    },
    {
      id: "0000",
      type: "Type A",
      filename: "showfilenameEp01",
      duration: "00:00:00.00",
      creation: "Jan 1st 2024",
    },
    {
      id: "0000",
      type: "Type A",
      filename: "showfilenameEp01",
      duration: "00:00:00.00",
      creation: "Jan 1st 2024",
    },
    {
      id: "0000",
      type: "Type A",
      filename: "showfilenameEp01",
      duration: "00:00:00.00",
      creation: "Jan 1st 2024",
    },
    {
      id: "0000",
      type: "Type A",
      filename: "showfilenameEp01",
      duration: "00:00:00.00",
      creation: "Jan 1st 2024",
    },
    {
      id: "0000",
      type: "Type A",
      filename: "showfilenameEp01",
      duration: "00:00:00.00",
      creation: "Jan 1st 2024",
    },
    {
      id: "0000",
      type: "Type A",
      filename: "showfilenameEp01",
      duration: "00:00:00.00",
      creation: "Jan 1st 2024",
    },
    {
      id: '0000',
      type: 'Type A',
      filename: 'showfilenameEp01',
      duration: '00:00:00.00',
      creation: 'Jan 1st 2024'
    }   
  ];
  
  return (
    <div className="grid-container">
      <div className="preview-leftside" style={{backgroundColor: 'white'}} > 
          <div className="containerServices-frame1" style={{ marginBottom: '10px' }}>
            <text >Files Approval</text>
          </div>
          <div className="containerServices-frame1" style={{ marginBottom: '10px' }}>
            <text>very-very-long-workflow-filename</text>
          </div>
          <div style={{width: '97%' }}>
            <input type="text" placeholder="Search file" className="input-box"/>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr className="monitor-table-header">
                  <th className="monitor-h1"><input type="checkbox" /></th>
                  <th className="monitor-h2">TYPE</th>
                  <th className="monitor-h3">ID</th>
                  <th className="monitor-h4">FILE NAME</th>
                  <th className="monitor-h5">DURATION</th>
                  <th className="monitor-h6">CREATION</th>
                </tr>
              </thead>
              <tbody>
              {sampleData.map((item, index) => (
                    <tr className="monitor-rows" key={index} onClick={() => handleRowClick(item.filename)}>
                      <td className="monitor-rows1">
                        <input
                          type="checkbox"
                          onChange={() => handleCheckBoxChange(item.filename)}
                          checked={selectedCheckBox === item.filename}
                        />
                      </td>
                    <td className="monitor-rows2">
                      {item.type === 'jpg' ? (
                        <img src="/jpg_image.png" alt="JPG" style={{ width: '25px', height: '25px' }} />
                      ) : item.type === 'mov' ? (
                        <img src="/movfile_image.jpg" alt="MOV" style={{ width: '30px', height: '25px' }} />
                      ) : item.type === 'sub' ? (
                        <img src="path_to_sub_icon.jpg" alt="SUB" style={{ width: '20px', height: 'auto' }} />
                      ) : (
                        <img src="path_to_default_icon.jpg" alt="Default" style={{ width: '20px', height: 'auto' }} />
                      )}
                    </td>
                    <td className="monitor-rows3">{item.id}</td>
                    <td className="monitor-rows4">{item.filename}</td>
                    <td className="monitor-rows5">{item.duration}</td>
                    <td className="monitor-rows6">{item.creation}</td>
                  </tr>
                ))}
              </tbody>
            </table>
        </div>
      </div>
         <div className="preview-rightside" style={{marginTop:"8vh"}}>
        <div className="uppper-right">
        <div>
        <video controls style={{ width: "100%", }} src={videoSource}></video>
        </div>
        </div>

        <div className="lower-right">
        <div style={{ margin: '10px 0' }}>
          <text className="filemetadata_" style={{ marginBottom: '10px' }}>File Metadata</text>
        </div>
        <div>
          <text>TITLE</text>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <input type="text" placeholder="Entered text" className="input-box" />
        </div>
        <div>
          <text>TRACK NUMBER</text>
        </div>
        <div>
          <input type="text" placeholder="Entered text" className="input-box" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          <button disabled="true" className="btnclose">Close</button>
          <button className="btn-approval" style={{marginLeft: '15px'}}>Accept</button>
          <button className="btn-approval" style={{marginLeft: '15px'}}>Reject</button>
          <button className="btn-approval" style={{marginLeft: '15px'}}>Save</button>
        </div>
        </div>
      </div>
      </div>
  
  );      
  
  
  
}
export default MonitorApproval;
