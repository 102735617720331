// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  font-family: "Open Sans", serif;
  max-height: 120vh;
  max-width: 70vw;
  margin: 0px;
}

body {
  margin: 0px;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.loader
{
display: flex;
justify-content: center;
align-items: center;
width:100%;
height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,iBAAiB;EACjB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,WAAW;EACX,uBAAuB;EACvB,mCAAmC;EACnC,kCAAkC;AACpC;AACA;;AAEA,aAAa;AACb,uBAAuB;AACvB,mBAAmB;AACnB,UAAU;AACV,YAAY;AACZ;AACA;EACE;aACW;AACb","sourcesContent":["html {\r\n  font-family: \"Open Sans\", serif;\r\n  max-height: 120vh;\r\n  max-width: 70vw;\r\n  margin: 0px;\r\n}\r\n\r\nbody {\r\n  margin: 0px;\r\n  font-family: sans-serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n.loader\r\n{\r\ndisplay: flex;\r\njustify-content: center;\r\nalign-items: center;\r\nwidth:100%;\r\nheight: 100%;\r\n}\r\ncode {\r\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\r\n    monospace;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
