import "./index.css";
import { useState, useEffect } from "react";
import {  useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getDomainData as getWorkflowsSentForExecution,
  clearAllDomaindata,
} from "../../redux/executionSlice.js";
import { useSnackbar } from "notistack";
import SearchBar from "../../components/Searchbar/index.js";
import StatusOrder from "../../images/Logos/order_down.png";
import PrevPage from "../../images/Logos/Prev.svg";
import NextPage from "../../images/Logos/Next.svg";

function DomainStatus({ dashDomain, limit }) {
  const history = useHistory();
  const cockpit_url =
    process.env?.CONDUCTOR_BACKEND_COCKPIT ||
    "https://www.mediamesh.cloud:5000";
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [domainData, setDomainData] = useState([]);
  const [searchWorkflowId, setSearchWorkflowId] = useState("");
  const [searchWorkflowName, setSearchWorkflowName] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filteredDomainData, setFilteredDomainData] = useState([]);

  const handleSearchWorkflowIdChange = (event) => {
    setSearchWorkflowId(event.target.value);
  };

  const handleSearchWorkflowNameChange = (event) => {
    setSearchWorkflowName(event.target.value);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const applyDateRangeFilter = () => {
    setIsFilterApplied(true);
  };

  const handleFilterClick = (start, end) => {
    setIsFilterApplied(true);
    setStartDate(start);
    setEndDate(end);
  };
  const [currentPage, setCurrentPage] = useState(1); // default value is 1
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handlePrevClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(filteredDomainData.length / rowsPerPage))
    ); // increment currentPage by 1, but ensure it doesn't go above the total number of pages
  };
  function openCenteredWindow(url, title, w, h) {
    // Calculate the position of the window
    const left = window.screen.width / 2 - w / 2;
    const top = window.screen.height / 2 - h / 2;
    // Open the window with calculated position
    window.open(
      url,
      title,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${top}, left=${left}`
    );
  }
  const totalRows = filteredDomainData.length;
  const rangeStart = (currentPage - 1) * rowsPerPage + 1;
  const rangeEnd = Math.min(currentPage * rowsPerPage, totalRows);
  const rangeText = `${rangeStart} to ${rangeEnd} of ${totalRows}`;
  const { loading, data, error } = useSelector(
    (state) => state.execution.domainData
  );

  useEffect(() => {
    if (!loading) {
      if (data) {
        setDomainData(data.data);
        setFilteredDomainData(data.data);
      if(dashDomain)
      {enqueueSnackbar(`Current Workflows in execution`, {
        variant: "success",
      });}  
        dispatch(clearAllDomaindata());
      }
      if (error) {
        if(dashDomain)
        enqueueSnackbar(error);
        dispatch(clearAllDomaindata());
        setDomainData([]);
        setFilteredDomainData([]);
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    if(dashDomain)
      {const filteredData = domainData.filter(
      (item) =>
        (item._id ? item._id : "")
          .toLowerCase()
          .includes(searchWorkflowId.toLowerCase()) &&
        (item.workflowName ? item.workflowName : "")
          .toLowerCase()
          .includes(searchWorkflowName.toLowerCase()) &&
        (selectedStatus === "All" ||
          item.status.toLowerCase() === selectedStatus.toLowerCase()) &&
        (!startDate || new Date(item.createdAt) >= startDate) &&
        (!endDate || new Date(item.createdAt) <= endDate)
    );

    setFilteredDomainData(filteredData);
  }

  }, [
    searchWorkflowId,
    searchWorkflowName,
    selectedStatus,
    startDate,
    endDate,
    domainData,
  ]);

  useEffect(() => {
    getDomainData();
  }, []);
  const getDomainData = () => {
    dispatch(getWorkflowsSentForExecution());
  };

  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    // timeZoneName: "short",
  };

  return (
    // <div className= "container-fluid">
      <div className={dashDomain? "container-fluid" :""}> 
      {dashDomain ? (
        <>
          <h1 className="app_header">Monitor Status</h1>
          <div className="row">
            <div className="col">
              <SearchBar
                searchWorkflowId={searchWorkflowId}
                searchWorkflowName={searchWorkflowName}
                selectedStatus={selectedStatus}
                handleSearchWorkflowIdChange={handleSearchWorkflowIdChange}
                handleSearchWorkflowNameChange={handleSearchWorkflowNameChange}
                handleStatusChange={handleStatusChange}
                handleFilterClick={handleFilterClick}
              />
            </div>
          </div>
        </>
      ) : null}
      <div className="row">
        <div className="col">
          <div className="table-responsive">
            <table className="table table-striped">
              <thead className="monitor-table-header">
                <tr>
                  <th>SNo</th>
                  <th>TITLE</th>
                  <th>FILE NAME</th>
                  <th>WORKFLOW NAME</th>
                  <th>WORKFLOW DESCRIPTION</th>
                  <th>
                    STATUS <img src={StatusOrder} alt="order" />
                  </th>
                  <th>CREATION DATE</th>
                  <th>AUTHOR</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {filteredDomainData.slice(0, limit).map((item, index) => (
                  <tr key={item._id}>
                    <td>{index + 1}</td>
                    <td>{item.movieName ? item.movieName : ""}</td>
                    <td>{item.filename ? item.filename : ""}</td>
                    <td>
                      {item?.viewFlag === true ? (
                        <a
                          href="#"
                          onClick={() => {
                            openCenteredWindow(
                              `${cockpit_url}/execution/` + item.workflowId,
                              "Centered Window",
                              700,
                              600
                            );
                          }}
                        >
                          {item.workflowName}
                        </a>
                      ) : (
                        `${item.workflowName}`
                      )}
                    </td>
                    <td>{item.workflow_desc}</td>
                    <td>{item.status}</td>
                    <td className="d-none d-sm-table-cell">
                      {new Intl.DateTimeFormat("en-US", options).format(
                        new Date(item.createdAt)
                      )}
                    </td>
                    <td>{item.createdBy}</td>
                    <td>
                      <span className="frameButton">
                        {/* <button
                        disabled={item.confId ? false : true}
                        className="btnExecute"
                        onClick={() =>
                          history.push({
                            pathname: `/monitorapproval/${item._id}`,
                          })
                        }
                      >
                        Human Task
                      </button> */}
                        <button
                          disabled={item.confId ? false : true}
                          className="btnExecute"
                          onClick={() =>
                            history.push({
                              pathname: `/monitorpreview/${item._id}`,
                            })
                          }
                        >
                          Preview
                        </button>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {dashDomain && (
            <div className="paginator">
              <span>{rangeText}</span>
              <img
                onClick={handlePrevClick}
                disabled={currentPage === 1}
                src={PrevPage}
                alt="previous page"
                className={`paginator-image${currentPage === 1 ? " disabled" : ""}`}
              />
              <img
                onClick={handleNextClick}
                disabled={
                  currentPage ===
                  Math.ceil(filteredDomainData.length / rowsPerPage)
                }
                src={NextPage}
                alt="next page"
                className={`paginator-image${currentPage === Math.ceil(filteredDomainData.length / rowsPerPage) ? " disabled" : ""}`}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DomainStatus;
