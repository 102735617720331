// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer{
  position: static;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 20px 0px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background: var(--Text-Normal, #35363A);
  grid-row-start: 4;
    grid-row-end: 5;
}
.footer p{
color: #FFF;
  text-align: center;

  /* Desktop/body */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
}
@media all and (min-width: 1300px) and (max-width:1450px) {
  .footer {
      max-width: 100vw;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,SAAS;EACT,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,uBAAuB;EACvB,uBAAuB;EACvB,SAAS;EACT,mBAAmB;EACnB,uCAAuC;EACvC,iBAAiB;IACf,eAAe;AACnB;AACA;AACA,WAAW;EACT,kBAAkB;;EAElB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;AACb;AACA;EACE;MACI,gBAAgB;IAClB;AACJ","sourcesContent":[".footer{\r\n  position: static;\r\n  bottom: 0;\r\n  width: 100%;\r\n  display: flex;\r\n  padding: 20px 0px;\r\n  justify-content: center;\r\n  align-items: flex-start;\r\n  gap: 10px;\r\n  align-self: stretch;\r\n  background: var(--Text-Normal, #35363A);\r\n  grid-row-start: 4;\r\n    grid-row-end: 5;\r\n}\r\n.footer p{\r\ncolor: #FFF;\r\n  text-align: center;\r\n\r\n  /* Desktop/body */\r\n  font-family: Inter;\r\n  font-size: 14px;\r\n  font-style: normal;\r\n  font-weight: 400;\r\n  line-height: 140%;\r\n  /* 19.6px */\r\n}\r\n@media all and (min-width: 1300px) and (max-width:1450px) {\r\n  .footer {\r\n      max-width: 100vw;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
