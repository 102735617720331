import * as React from "react";
import { useSnackbar } from "notistack";
import { useEffect, useState, useRef, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { Query } from "@syncfusion/ej2-data";
import { configuration as configData } from "../../redux/configurationSlice";
import Loader from "../Common/Loader.jsx";
import {
  apiData as apiData1,
  apiFileDataContent,
  clearAll,
} from "../../redux/apiFileSlice";
import {
  ClearServiceData,
  insertConfigData,
} from "../../redux/serviceSlice.js";
import "./index.css";
import * as data from "./datasource.json";
import "./editFile.js";
import EditJsonFile from "./editFile.js";
const Cascading = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch1 = useDispatch();
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const apiNameObj = useRef(null);
  const fileObj = useRef(null);
  const [isFileAvailable, setIsFileAvailable] = useState(true);
  const [isNewFile, setIsNewFile] = useState(true);
  const [isApiValidationValid, setIsApiValidationValid] = useState(false);
  const { filecontent } = useSelector((state) => state.apiFile);
  const { loading, configurationData, error } = useSelector(
    (state) => state.service
  );
  const initState = {
    taskSubType: "",
    taskType: "",
    participants: [],
    toolProvider: "",
    vendor: "",
    infrastructure: "",
    conf_file: "",
    api_name: "",
  };
  const configReducer = (state, action) => {
    switch (action.type) {
      case "TASK_NAME":
        return {
          ...state,
          taskName: action.value,
        };
      case "SET_TYPE":
        return {
          ...state,
          taskType: action.value,
        };
      case "SET_SUBTYPE":
        return {
          ...state,
          taskSubType: action.value,
        };
      case "SET_TOOLPROVIDER":
        return {
          ...state,
          toolProvider: action.value,
        };
      case "SET_VENDOR":
        return {
          ...state,
          vendor: action.value,
        };
      case "SET_PARTICIPANTS":
        return {
          ...state,
          participants: [...state.participants, action.value],
        };

      case "SET_INFRASTRUCTURE":
        return {
          ...state,
          infrastructure: action.value,
        };
      case "SET_CONF_FILE":
        return {
          ...state,
          conf_file: action.value,
        };
      case "SET_API":
        return {
          ...state,
          api_name: action.value,
        };
      default:
        break;
    }
  };

  const [state, dispatch] = useReducer(configReducer, initState);
  const {
    taskSubType,
    taskType,
    participants: partic,
    infrastructure,
    toolProvider,
    vendor,
    conf_file,
    api_name,
  } = state;
  const [isAllDropDownValid, setDropDownValid] = useState(false);
  //To get dropdown datas
  useEffect(() => {
    getConfigurationData();
  }, []);
  //To validate if all required fields are filled then get the enable configure button
  useEffect(() => {
    const ignoreprops = ["taskName"];
    const ignoreApiProps = ["taskName", "partic", "conf_file", "api_name"];
    const stateprops = Object.keys(state);
    // const totalProps = stateprops.filter((prop) => !ignoreprops.includes(prop));
    const isvalid = stateprops
      .filter((prop) => !ignoreprops.includes(prop))
      .every((prop) => {
        return state[prop];
      });
    const isapivalid = stateprops
      .filter((prop) => !ignoreApiProps.includes(prop))
      .every((prop) => {
        return state[prop];
      });
    setDropDownValid(isvalid);
    if (isapivalid) {
      setIsApiValidationValid(isapivalid);
      getFileApi();
      setIsNewFile(false);
    }
  }, [
    taskSubType,
    taskType,
    partic,
    conf_file,
    infrastructure,
    toolProvider,
    vendor,
    api_name,
    state,
    isOverlayVisible,
  ]);
  //To display a notification on the basis of configure service
  useEffect(() => {
    if (!loading) {
      if (configurationData) {
        dispatch1(clearAll);

        dispatch1(ClearServiceData);
        resetAll();
        enqueueSnackbar(configurationData.msg, {
          variant: "success",
        });
      }
      if (error) {
        enqueueSnackbar("Service Not Configured Succesfully!", {
          variant: "error",
        });
      }
    }
  }, [loading, configurationData]);
  const addConfiguration = async () => {
    if (!isAllDropDownValid) {
      return;
    }
    dispatch1(insertConfigData(state));
  };
  useEffect(() => {
    if (!filecontent.loading) {
      setJsonData(filecontent.apiFileDataContent);
      setIsFileAvailable(false);
      setIsNewFile(true);
    }
  }, [filecontent.loading]);
  const resetAll = () => {
    state.taskType = "";
    state.taskSubType = "";
    typeObj.current.value = null;
    subTypeObj.current.value = null;
    reset();
  };
  const temp = "toolProviderList";
  const [toolproviderData, setToolProviderData] = useState(data[temp]);
  const fields = { text: "legalName", value: "_id" };
  const fields1 = { text: "legalName", value: "_id" };
  const tempType = "type";
  const infrastructure1 = "infrastructure";
  const participants = "participants";
  const vendors = "vendors";
  const tempSubType = "state";
  const [tempData, setTempData] = useState(data[tempType]);
  const [participantText, setParticpantText] = useState(null);
  const [infrastructuredata, setInfrastructureData] = useState(
    data[infrastructure1]
  );
  const [apiData, setApiData] = useState([]);
  const [selectedSubType, setSelectedSubType] = useState(null);
  const apiFields = { text: "apiName", value: "_id" };
  const subTypeFields = { value: "_id", text: "subTypeName" };
  const allfields = { text: "name", value: "_id" };
  const fileFileds = { text: "metadataFileName", value: "_id" };
  const [filedata, setFileData] = useState([]);
  const [participantsData, setParticpantsData] = useState(data[participants]);
  const [vendorData, setVendorData] = useState(data[vendors]);
  const [stateEnabled, setStateEnabled] = useState(false);
  const [stateQuery, setStateQuery] = useState(null);
  const [toolProviderQuery, setToolProviderQuery] = useState(null);
  const [toolProviderQueryFinal, setToolProviderQueryFinal] = useState(null);
  const [vendorQuery, setVendorQuery] = useState(null);
  const [subTypeText, setSubTypeText] = useState(null);
  const vendorObj = useRef(null);
  const toolProviderObj = useRef(null);
  const typeObj = useRef(null);
  const subTypeObj = useRef(null);
  const [subTypeData, setSubTypeData] = useState(data[tempSubType]);
  const participantObj = useRef(null);
  const infrastructureObj = useRef(null);
  const getFileData = (args) => {
    dispatch({ type: "SET_CONF_FILE", value: args.value });
    if (args.itemData) {
      dispatch1(
        apiFileDataContent({
          id: args.itemData?.metadataFileName,
        })
      );
    }
  };

  const showOverlay = (type) => {
    if (type === "new") {
      setJsonData({
        key: "value",
        anotherKey: "anotherValue",
        // ...more data
      });
    }
    setIsOverlayVisible(true);
  };
  const [jsonData, setJsonData] = useState({});
  const hideOverlay = () => {
    setIsOverlayVisible(false);
  };
  const handleOpenFile = (type) => {
    showOverlay(type);
  };
  const getConfigurationData = async () => {
    const response = await dispatch1(configData());
    const configurationData = response.payload.data;
    setInfrastructureData(configurationData.infrastructure);
    setParticpantsData(configurationData.participant);
    setSubTypeData(configurationData.subtask);
    setTempData(configurationData.task);
    setVendorData(configurationData.vendor);
    setToolProviderData(configurationData.toolprovider);
  };
  //set the value to header template
  const headerTemplate = () => {
    return (
      <div className="header">
        <span>Photo</span> <span className="columnHeader">Tool Info</span>
      </div>
    );
  };
  //set the value to item template
  const itemTemplate = (data) => {
    return (
      <div>
        <img
          className="empImage"
          src={data.name}
          alt={data.legalName}
          height="28px"
          width="28px"
        />
        <div className="ename"> {data.legalName} </div>
        <div className="job"> {data.address} </div>
      </div>
    );
  };
  const valueTemplate = (data) => {
    return (
      <div className="valueTemplate">
        <img
          className="value"
          src={data.name}
          height="28px"
          width="28px"
          alt="employee"
        />
        <div className="name1"> {data.legalName} </div>
      </div>
    );
  };
  const typeChange = (args) => {
    reset();
    setStateEnabled(args.value != null);

    // query the data source based on country DropDownList selected value
    let tempQuery = new Query().where("typeId", "equal", args.value);
    setStateQuery(tempQuery);
    // clear the existing selection.
    setSubTypeText(null);
    // bind the property changes to state DropDownList
    subTypeObj.current.dataBind();
    if (args.itemData) {
      dispatch({ type: "SET_TYPE", value: args.itemData._id });
    }
  };
  const reset = () => {
    state.participants = [];
    state.taskSubType = "";
    state.toolProvider = "";
    state.infrastructure = "";
    state.conf_file = "";
    state.api_name = "";
    participantObj.current.value = null;
    infrastructureObj.current.value = null;
    toolProviderObj.current.value = null;
    vendorObj.current.value = null;
    fileObj.current.value = null;
    apiNameObj.current.value = null;
    fileObj.current.dataBind();
    apiNameObj.current.dataBind();
    setIsFileAvailable(true);
    setFileData([]);
    setApiData([]);
  };
  const changeVendor = (args) => {
    // setToolProviderQueryFinal(toolProviderQuery);
    dispatch({ type: "SET_VENDOR", value: args.itemData?._id });
    let tempQuery = new Query().where("vendorId", "contains", args.value);
    if (toolProvider?.queries) {
      tempQuery.queries.push(toolProviderQuery?.queries[0]);
    }
    setToolProviderQueryFinal(tempQuery);
    toolProviderObj.current.dataBind();
  };
  const getFileApi = async () => {
    setIsFileAvailable(true);
    const response1 = await dispatch1(
      apiData1({
        task: state.taskType,
        subtask: state.taskSubType,
        toolProvider: state.toolProvider,
        vendor: state.vendor,
      })
    );
    if (response1.payload?.data?.fileApiData) {
      const apiInfo2 = response1.payload?.data.fileApiData;

      setFileData(apiInfo2);
    } else {
      setFileData([]);
    }
    if (response1.payload?.data?.apiData) {
      const apiInfo1 = response1.payload?.data.apiData;

      setApiData(apiInfo1);
    } else {
      setApiData([]);
    }
  };
  const subTypeChange = (args) => {
    reset();
    setStateEnabled(args.value != null);
    // query the data source based on type DropDownList selected value
    let tempQuery = new Query().where("subTypeId", "contains", args.value);
    // bind the property changes to state DropDownList
    setSelectedSubType(args.value);
    setVendorQuery(tempQuery);
    setToolProviderQuery(tempQuery);
    vendorObj.current.dataBind();
    dispatch({ type: "SET_SUBTYPE", value: args.itemData?._id });
  };

  const configureButtonStyle = isAllDropDownValid
    ? { padding: "15px" }
    : { padding: "15px", opacity: 0.75, cursor: "not-allowed" };
  return (
    <div className="service-settings-container">
      {isOverlayVisible && (
        <div className="overlay">
          <div className="overlay-content">
            {/* Your overlay content goes here */}
            <EditJsonFile
              onClose={hideOverlay}
              data={jsonData}
              metaData={state}
            />
          </div>
        </div>
      )}

      {loading ? (
        <Loader />
      ) : (
        <div className="containerService">
          <div className="containerServices-frame1">
            <text>Service Configuration</text>
          </div>
          <div className="containerServices-frame2">
            <div className="containerService-frame3">
              <div className="service-module-container">
                <div className="services-module">
                  <h2>Select Service Type</h2>

                  <div className="services">
                    <div className="services-type">
                      <div className="service-type-label">
                        <h4>Type</h4>
                      </div>
                      <DropDownListComponent
                        className="service-type-input"
                        id="type"
                        ref={typeObj}
                        dataSource={tempData}
                        fields={allfields}
                        popupHeight="auto"
                        change={typeChange.bind(this)}
                        placeholder="Select a type"
                      />
                    </div>

                    <div className="services-subtype">
                      <div className="service-subtype-label">
                        <h4>Sub Type</h4>
                      </div>
                      <DropDownListComponent
                        className="service-subtype-input"
                        id="sub_type"
                        dataSource={subTypeData}
                        ref={subTypeObj}
                        fields={subTypeFields}
                        popupHeight="auto"
                        change={subTypeChange.bind(this)}
                        enabled={stateEnabled}
                        placeholder="Select a subtype"
                        query={stateQuery}
                        text={subTypeText}
                      />
                    </div>
                  </div>
                </div>

                <div className="product-configurations">
                  <h2>Select Product Configuration</h2>
                  <div className="product-configurations-frame">
                    <div className="Vendor-prod">
                      <div className="Vendor-prod-label">
                        <h4>Vendor</h4>
                      </div>

                      <DropDownListComponent
                        className="Vendor-prod-input"
                        id="employees"
                        dataSource={vendorData}
                        ref={vendorObj}
                        headerTemplate={headerTemplate}
                        itemTemplate={itemTemplate}
                        valueTemplate={valueTemplate}
                        fields={fields1}
                        popupHeight="160"
                        placeholder="Select a vendor"
                        query={vendorQuery}
                        change={changeVendor}
                      />
                    </div>
                    <div className="tool-prod">
                      <div className="tool-prod-label">
                        <h4>Tool Provider</h4>
                      </div>
                      <DropDownListComponent
                        className="tool-prod-input"
                        id="employees"
                        dataSource={toolproviderData}
                        fields={fields}
                        ref={toolProviderObj}
                        placeholder="Select a Tool Provider"
                        itemTemplate={itemTemplate}
                        valueTemplate={valueTemplate}
                        headerTemplate={headerTemplate}
                        popupHeight="160"
                        query={toolProviderQueryFinal}
                        change={(e) => {
                          dispatch({
                            type: "SET_TOOLPROVIDER",
                            value: e.itemData?._id,
                          });
                        }}
                      />
                    </div>

                    <div className="participant-prod">
                      <div className="participant-prod-label">
                        <h4>Participant</h4>
                      </div>
                      <DropDownListComponent
                        className="participant-prod-input"
                        id="participants"
                        dataSource={participantsData}
                        ref={participantObj}
                        fields={allfields}
                        change={(e) =>
                          dispatch({ type: "SET_PARTICIPANTS", value: e.value })
                        }
                        popupHeight="auto"
                        placeholder="Select a participant"
                        text={participantText}
                      />
                    </div>

                    <div className="infra-prod">
                      <div className="infra-prod-label">
                        <h4>Infrastructure</h4>
                      </div>
                      <DropDownListComponent
                        className="infra-prod-input"
                        id="infrastructure"
                        dataSource={infrastructuredata}
                        ref={infrastructureObj}
                        fields={allfields}
                        change={(e) => {
                          dispatch({
                            type: "SET_INFRASTRUCTURE",
                            value: e.value,
                          });
                        }}
                        popupHeight="auto"
                        placeholder="Select a Infrastructure"
                      />
                    </div>
                  </div>
                </div>
                <div className="Api-file-module">
                  <h2>Select Api/Source File</h2>
                  <div className="Api-file-frame">
                    <div className="api-name">
                      <div className="api-name-label">
                        <h4>Api Name</h4>
                      </div>
                      <DropDownListComponent
                        className="api-name-input"
                        id="api"
                        ref={apiNameObj}
                        dataSource={apiData}
                        fields={apiFields}
                        popupHeight="160"
                        placeholder="Select an Api Name"
                        change={(e) =>
                          dispatch({ type: "SET_API", value: e.value })
                        }
                      />
                    </div>

                    <div className="api-file">
                      <div className="api-file-label">
                        <h4>Api File</h4>
                      </div>
                      <DropDownListComponent
                        className="api-file-input"
                        id="file"
                        ref={fileObj}
                        dataSource={filedata}
                        fields={fileFileds}
                        popupHeight="160"
                        placeholder="Select an Api File"
                        change={(e) => getFileData(e)}
                      />
                    </div>

                    <div className="json-button-container">
                      <button
                        className="open-json-file"
                        onClick={(e) => handleOpenFile("old")}
                        disabled={isFileAvailable}
                      >
                        <div className="open-json-file-label">
                          <text>Open JSON File</text>
                        </div>
                      </button>
                      <button
                        className="new-json-file"
                        onClick={(e) => handleOpenFile("new")}
                        disabled={isNewFile}
                      >
                        <div className="new-json-file-label">
                          <text>New JSON File </text>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    // style={configureButtonStyle}
                    className="congigure-service-button"
                    onClick={() => addConfiguration()}
                    title={
                      isAllDropDownValid
                        ? ""
                        : "Please fill all the dropdowns to proceed"
                    }
                    disabled={!isAllDropDownValid}
                  >
                    <div className="congigure-service-button-label">
                      <text>Configure Service</text>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="services-search-container">
        <div className="services-workflow-id"></div>
        <div className="services-workflow-name"></div>
        <div className="services-status"></div>
        <div className="services-date"></div>
      </div>
    </div>
  );
};
export default Cascading;
