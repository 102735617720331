import "../DomainStatus/index.css";
import { useState, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import Button from "../Common/Button/index.jsx";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useSelector, useDispatch } from "react-redux";
import SearchBar from "../../components/Searchbar/index.js";
import StatusOrder from "../../images/Logos/order_down.png";
import PrevPage from "../../images/Logos/Prev.svg";
import NextPage from "../../images/Logos/Next.svg";
import {
  execution,
  conductor,
  clearAll,
  getExecutionData as getWorkflowsSentForExecution,
  clearAllDomaindata,
  fixScheduler,
} from "../../redux/executionSlice.js";

import Scheduler from "../Common/Scheduler/index.js";
function Orchestrator() {
  
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [searchWorkflowId, setSearchWorkflowId] = useState("");
  const [searchWorkflowName, setSearchWorkflowName] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filteredDomainData, setFilteredDomainData] = useState([]);
  const [props, setProps] = useState(null);

  const backendSchedulerCall = (date, recurring, timeObject,isRepeat) => {
    try {
      dispatch(
        fixScheduler({
          date: date.current.value,
          configId: props.data._id,
          user: user,
          recurring: recurring,
          time: timeObject.current.value,
          repeat:isRepeat
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleSearchWorkflowIdChange = (event) => {
    setSearchWorkflowId(event.target.value);
  };

  const handleSearchWorkflowNameChange = (event) => {
    setSearchWorkflowName(event.target.value);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const applyDateRangeFilter = () => {
    setIsFilterApplied(true);
  };

  const handleFilterClick = (start, end) => {
    setIsFilterApplied(true);
    setStartDate(start);
    setEndDate(end);
  };

  const [dateTime, setDateTime] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { loading, data, error } = useSelector((state) => state.execution);
  const {
    loading: loadingConductor,
    data: dataConductor,
    error: errorConductor,
  } = useSelector((state) => state.execution.conductor);
  const hideOverlay = () => {
    setDateTime(false);
  };
  const {
    loading: loadingDomain,
    data: dataDomain,
    error: errorDomain,
  } = useSelector((state) => state.execution.executionData);
  const {
    loading: loadingScheduler,
    data: dataScheduler,
    error: errorScheduler,
  } = useSelector((state) => state.execution.scheduler);
  const [domainData, setDomainData] = useState([]);
  const buttonClicked = (props) => {
    dispatch(execution({ data: props.data._id }));
    dispatch(conductor({ configId: props.data._id }));
  };
  const schedulerClicked = (props) => {
    setProps(props);
    setDateTime(true);
  };
  useEffect(() => {
    if (!loadingDomain) {
      if (dataDomain) {
        setDomainData(dataDomain.data);
        setFilteredDomainData(dataDomain.data);
        enqueueSnackbar(`Completely Configured Workflows!`, {
          variant: "success",
        });
        console.log("setfilterDomaindata ==>",dataDomain.data);
        dispatch(clearAllDomaindata());
      }
      if (errorDomain) {
        enqueueSnackbar(error);
        dispatch(clearAllDomaindata());
        setDomainData([]);
        setFilteredDomainData([]);
      }
    }
  }, [loadingDomain, dataDomain, errorDomain]);
  useEffect(() => {
    if (!loadingScheduler) {
      if (dataScheduler.length !== 0) {
        enqueueSnackbar(`Scheduled!`, {
          variant: "success",
        });
        hideOverlay();
        clearAll();
      }
      if (errorScheduler) {
        enqueueSnackbar(`Error in Scheduling!`, {
          variant: "error",
        });
      }
    }
  }, [loadingScheduler, dataScheduler, errorScheduler]);
  useEffect(() => {
    getDomainData();
  }, []);

  useEffect(() => {
    if (!loading) {
      if (data) {
        enqueueSnackbar(`Successfully Sent For Excution!`, {
          variant: "success",
        });
        dispatch(clearAll());
      }
      if (error) {
        enqueueSnackbar(`Successfully Sent For Excution!`, {
          variant: "success",
        });
        dispatch(clearAll());
      }
    }
  }, [loading, data]);
  useEffect(() => {
    if (!loadingConductor) {
      if (dataConductor) {
        console.log(dataConductor);
                enqueueSnackbar(`Execution Initiated!`, {
          variant: "success",
        });
        getDomainData();
        dispatch(clearAll());
      }
      if (errorConductor) {
        enqueueSnackbar(`Contact With Tech Team!`, {
          variant: "error",
        });
        clearAll();
      }
    }
  }, [loadingConductor, dataConductor]);

  useEffect(() => {
    const filteredData = domainData.filter(
      (item) =>
        item._id.toLowerCase().includes(searchWorkflowId.toLowerCase()) &&
        item.workflowName
          .toLowerCase()
          .includes(searchWorkflowName.toLowerCase()) &&
        (selectedStatus === "All" ||
          item.status.toLowerCase() === selectedStatus.toLowerCase()) &&
        (!startDate || new Date(item.createdAt) >= startDate) &&
        (!endDate || new Date(item.createdAt) <= endDate)
    );

    setFilteredDomainData(filteredData);
  }, [
    searchWorkflowId,
    searchWorkflowName,
    selectedStatus,
    startDate,
    endDate,
    domainData,
  ]);

  const getDomainData = async () => {
    dispatch(getWorkflowsSentForExecution());
  };

  useEffect(() => {
    if (isFilterApplied) {
      if (startDate && endDate) {
        const filteredResult = domainData.filter((item) => {
          const itemDate = new Date(item.createdAt);
          return itemDate >= startDate && itemDate <= endDate;
        });

        setFilteredDomainData(filteredResult);
      } else {
        setFilteredDomainData(domainData);
      }
      setIsFilterApplied(false); // Reset the flag
    }
  }, [isFilterApplied, startDate, endDate, domainData]);

  const [currentPage, setCurrentPage] = useState(1); // default value is 1
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handlePrevClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(filteredDomainData.length / rowsPerPage))
    ); // increment currentPage by 1, but ensure it doesn't go above the total number of pages
  };

  const totalRows = filteredDomainData.length;
  const rangeStart = (currentPage - 1) * rowsPerPage + 1;
  const rangeEnd = Math.min(currentPage * rowsPerPage, totalRows);
  const rangeText = `${rangeStart} to ${rangeEnd} of ${totalRows}`;
  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    // timeZoneName: "short",
  };

  return (
    <div className="container">
      <SearchBar
        searchWorkflowId={searchWorkflowId}
        searchWorkflowName={searchWorkflowName}
        selectedStatus={selectedStatus}
        handleSearchWorkflowIdChange={handleSearchWorkflowIdChange}
        handleSearchWorkflowNameChange={handleSearchWorkflowNameChange}
        handleStatusChange={handleStatusChange}
        handleFilterClick={handleFilterClick}
      />
      <div>
        {dateTime && (
          <Scheduler
            workflowName={props.data.workflowName}
            onSave={backendSchedulerCall}
            onClose={hideOverlay}
          />
        )}
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead className="monitor-table-header">
            <tr>
              <th>S No.</th>
              <th>WORKFLOW NAME</th>
              <th>WORKFLOW DESCRIPTION</th>
              <th>
                STATUS <img src={StatusOrder} alt="order" />
              </th>
              <th>CREATION DATE</th>
              <th>AUTHOR</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {filteredDomainData.map((item, index) => (
              <tr key={item._id}>
                <td>{index + 1}</td>
                <td>{item.workflowName}</td>
                <td>{item.workflow_desc}</td>
                <td>{item.status}</td>
                <td>
                  {new Intl.DateTimeFormat("en-US", options).format(
                    new Date(item.createdAt)
                  )}
                </td>
                <td>{item.createdBy}</td>
                <td>
                  <Button
                    restProps={{ data: item }}
                    onClick={buttonClicked}
                    onSchedule={schedulerClicked}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="paginator">
        <span>{rangeText}</span>
        <img
          onClick={handlePrevClick}
          disabled={currentPage === 1}
          src={PrevPage}
          alt="previous page"
          className={`paginator-image${
            currentPage === 1 ? " disabled" : ""
          }`}
        />
        <img
          onClick={handleNextClick}
          disabled={
            currentPage ===
            Math.ceil(filteredDomainData.length / rowsPerPage)
          }
          src={NextPage}
          alt="next page"
          className={`paginator-image${
            currentPage ===
            Math.ceil(filteredDomainData.length / rowsPerPage)
              ? " disabled"
              : ""
          }`}
        />
      </div>
    </div>
  );
}

export default Orchestrator;
