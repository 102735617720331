import React, { useState, createContext } from "react";
const AuthCtx = createContext();
const useAuthentication = () => {
  const dataSession = sessionStorage.getItem("user");
const tokensession=sessionStorage.getItem("token");
  const [user, setUser] = useState(
    dataSession ? JSON.parse(dataSession) : null
  );
  const [error, setError] = useState(null);
  const [token, setToken] = useState(tokensession?JSON.parse(tokensession):null);

  const login = (data) => {
    setUser(data.payload.user || null);
    setToken(data.payload.token);
    
    if (data.payload.user) {
      sessionStorage.setItem("user", JSON.stringify(data.payload.user));
      sessionStorage.setItem("token", JSON.stringify(data.payload.token));
    } else {
      setError(data.error ? data.payload : null);
      sessionStorage.clear();
    }
    return true;
  };
  const signUp = (data) => {
    setUser(data.payload.user || null);
    setToken(data.payload.token);
    
    if (data.payload.user) {
      sessionStorage.setItem("user", JSON.stringify(data.payload.user));
      sessionStorage.setItem("token", JSON.stringify(data.payload.token));
    } else {
      setError(data.error ? data.payload : null);
      sessionStorage.clear();
    }
    return true;

    
  };
  const logOut = () => {
    setUser(null);
    setError(null);
    sessionStorage.clear();
  };
  return {
    AuthCtx,
    AuthProvider: ({ children }) => (
      <AuthCtx.Provider value={{ signUp, error, user, login, logOut }}>
        {children}
      </AuthCtx.Provider>
    ),
  };
};

export default useAuthentication;
