import React, { useState, useEffect } from "react";
import "./index.css";
import { Switch } from "antd";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
const AdminUserOverlay = ({ onClose, projectMapping, onUpdate, roleData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { userGroup } = useSelector((state) => state.auth);
  const [isDesigner, setIsDesigner] = useState(
    projectMapping.privileges.includes("6638ac09ad6ff5d096b0d582") ||
      projectMapping.privileges.includes("663a0002ad6ff5d096b0d58e")
      ? true
      : false
  );
  const [isConfigurator, setIsConfigurator] = useState(
    projectMapping.privileges.includes("6638ac55ad6ff5d096b0d584") ||
      projectMapping.privileges.includes("663a0002ad6ff5d096b0d58e")
      ? true
      : false
  );
  const [isOrchestrator, setIsOrchestrator] = useState(
    projectMapping.privileges.includes("6638ac69ad6ff5d096b0d585") ||
      projectMapping.privileges.includes("663a0002ad6ff5d096b0d58e")
      ? true
      : false
  );
  const [isServiceSettings, setIsServiceSettings] = useState(
    projectMapping.privileges.includes("6638acadad6ff5d096b0d588") ||
      projectMapping.privileges.includes("663a0002ad6ff5d096b0d58e")
      ? true
      : false
  );
  const [isHumanTask, setIsHumanTask] = useState(
    projectMapping.privileges.includes("6638ac96ad6ff5d096b0d587") ||
      projectMapping.privileges.includes("663a0002ad6ff5d096b0d58e")
      ? true
      : false
  );
  const [isWorkAllocation, setIsWorkAllocation] = useState(
    projectMapping.privileges.includes("6638acbcad6ff5d096b0d589") ||
      projectMapping.privileges.includes("663a0002ad6ff5d096b0d58e")
      ? true
      : false
  );
  const [isMonitorSettings, setIsMonitorSettings] = useState(
    projectMapping.privileges.includes("6638ac79ad6ff5d096b0d586") ||
      projectMapping.privileges.includes("663a0002ad6ff5d096b0d58e")
      ? true
      : false
  );
  const [contactName, setContactName] = useState(projectMapping.contactName);
  const [primaryEmail, setPrimaryEmail] = useState(projectMapping.primaryEmail);
  const [selectedRole, setSelectedRole] = useState(projectMapping.role._id);
  const [isFormValid, setIsFormValid] = useState(false);
  const [emailError, setEmailError] = useState("");
  const handleDesignerToggle = (checked) => {
    setIsDesigner(checked);
  };
  const handleConfiguratorToggle = (checked) => {
    setIsConfigurator(checked);
  };
  const handleOrchestratorToggle = (checked) => {
    setIsOrchestrator(checked);
  };
  const handleServiceSettingsToggle = (checked) => {
    setIsServiceSettings(checked);
  };
  const handleHumanTaskToggle = (checked) => {
    setIsHumanTask(checked);
  };
  const handleWorkAllocationTaskToggle = (checked) => {
    setIsWorkAllocation(checked);
  };
  const handleMonitorSettings = (checked) => {
    setIsMonitorSettings(checked);
  };
  const validateForm = () => {
    // Validate email and update email error state
    if (!validEmailId(primaryEmail)) {
      setEmailError("Invalid email Address!");
      setIsFormValid(false);
    } else {
      setEmailError("");
      setIsFormValid(true);
    }
  };

  useEffect(() => {
    if (primaryEmail) {
      validateForm();
    }
  }, [primaryEmail, isFormValid]);
  const validEmailId = (email) => {
    const regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEmail.test(String(email).toLowerCase());
  };
  const handleUpdate = () => {
    // Check if required dropdown fields are selected
    if (!selectedRole || !primaryEmail || !contactName) {
      enqueueSnackbar("Please select all required fields!", {
        variant: "error",
      });
   
      return; // Prevent further execution of the function
    }
    if (!isFormValid) {
      enqueueSnackbar("Invalid Email!", {
        variant: "error",
      });
      return;
    }
    onUpdate({
      id: projectMapping._id,
      privileges: {
        designer: isDesigner,
        configurator: isConfigurator,
        orchestrator: isOrchestrator,
        serviceSettings: isServiceSettings,
        workAllocation: isWorkAllocation,
        humanTask: isHumanTask,
        monitorSettings: isMonitorSettings,
      },
      role: selectedRole,
      contactName,
      primaryEmail,
      userGroup: userGroup,
    });
  };

  return (
    <div className="admin-project-edit-container">
      <div className="admin-project-edit-frame">
        <div className="admin-project-edit-heading">
          <text>User</text>
        </div>
        <div className="admin-project-edit-selections">
          <div className="admin-project-edit-selections-frame">
            <div className="admin-project-edit-input">
              <div className="admin-project-edit-input-label">
                <text>email</text>
                <p1>*</p1>
              </div>
              <input
                className="admin-project-edit-inputs"
                placeholder="user@mail.com"
                value={primaryEmail}
                onChange={(e) => setPrimaryEmail(e.value)}
                disabled={true}
              />
            </div>
            <div className="admin-project-edit-input">
              <div className="admin-project-edit-input-label">
                <text>name</text>
                <p1>*</p1>
              </div>
              <input
                className="admin-project-edit-inputs"
                placeholder="User 1"
                value={contactName}
                onChange={(e) => setContactName(e.value)}
              />
            </div>
            <div className="admin-project-edit-input">
              <div className="admin-project-edit-input-label">
                <text>role</text>
                <p1>*</p1>
              </div>
              <DropDownListComponent
                className="setting-diagram-projectClient-input"
                placeholder="Select Role"
                dataSource={roleData} // Set project data as dataSource
                fields={{ text: "roleName", value: "_id" }} // Define the fields to display
                value={selectedRole} // Bind selectedproject state to the value property
                change={(e) => setSelectedRole(e.value)} // Handle change event to update selected project
                style={{ height: "38px" }}
                popupHeight="160"
              />
            </div>
            <div className="admin-project-edit-toggle">
              <div className="admin-project-edit-toggle-switch">
                <Switch
                  id="designerSwitch"
                  checked={isDesigner}
                  onChange={handleDesignerToggle}
                />
                <text>Designer</text>
              </div>
              <div className="admin-project-edit-toggle-switch">
                <Switch
                  id="configuratorSwitch"
                  checked={isConfigurator}
                  onChange={handleConfiguratorToggle}
                />
                <text>Configurator</text>
              </div>
              <div className="admin-project-edit-toggle-switch">
                <Switch
                  id="monitorSettingsSwitch"
                  checked={isMonitorSettings}
                  onChange={handleMonitorSettings}
                />
                <text>Monitor Settings</text>
              </div>
              <div className="admin-project-edit-toggle-switch">
                <Switch
                  id="orchestratorSwitch"
                  checked={isOrchestrator}
                  onChange={handleOrchestratorToggle}
                />
                <text>Orchestrator</text>
              </div>
              <div className="admin-project-edit-toggle-switch">
                <Switch
                  id="serviceSettingsSwitch"
                  checked={isServiceSettings}
                  onChange={handleServiceSettingsToggle}
                />
                <text>Service Settings</text>
              </div>
              <div className="admin-project-edit-toggle-switch">
                <Switch
                  id="humanTaskSwitch"
                  checked={isHumanTask}
                  onChange={handleHumanTaskToggle}
                />
                <text>Human Task</text>
              </div>
              <div className="admin-project-edit-toggle-switch">
                <Switch
                  id="workAllocationSwitch"
                  checked={isWorkAllocation}
                  onChange={handleWorkAllocationTaskToggle}
                />
                <text>Work Allocation</text>
              </div>
            </div>
          </div>
        </div>
        <div className="admin-project-edit-button-frame">
          <button
            className="admin-project-edit-cancel-button"
            onClick={onClose}
          >
            <div className="admin-project-edit-cancel-label">
              <text>Cancel</text>
            </div>
          </button>
          <button
            className="admin-project-edit-create-button"
            onClick={handleUpdate}
          >
            <div className="admin-project-edit-create-label">
              <text>Save</text>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminUserOverlay;
