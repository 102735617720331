import "./index.css";
import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import SearchClient from "../../../images/Logos/search.png";
import { useGetClientsQuery } from "../../../redux/apiSlice";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  useGetProjectsQuery,
  useAddProjectMutation,
  useDeleteProjectMutation,
  useEditProjectMutation,
} from "../../../redux/projectapiSlice";
import Loader from "../../Common/Loader";
import UpdateProject from "./UpdateProject";
const AdminProject = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading: loading, error } = useGetClientsQuery();
  const [projectName, setProjectName] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [addProject] = useAddProjectMutation();
  const {
    data: dataProjects,
    isLoading: loadingProjects,
    error: errorprojects,
  } = useGetProjectsQuery();
  const [
    deleteProject,
    { isLoading: loadingDeleteProject, error: errorDeleteProject },
  ] = useDeleteProjectMutation();
  const [
    editProject,
    { isLoading: loadingEditProject, error: errorEditProject },
  ] = useEditProjectMutation();
  const [submitting, setSubmitting] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [editClicked, setEditClicked] = useState(false);
  const [editdata, setEditData] = useState({});
  const [projectData, setProjectData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  //Get Client Data for client dropdown
  useEffect(() => {
    if (!loading) {
      if (data) {
        setClientData(data.data);
      }
      if (error) {
        enqueueSnackbar(`Failed to load clients`, {
          variant: "error",
        });
      }
    }
  }, [loading, data, error]);
  //On editing Project
  const editProjectFromTable = async (data) => {
    try {
      // Dispatch action to edit  project
      const response = await editProject(data).unwrap();
      if (!loadingEditProject) {
        if (response) {
          enqueueSnackbar(response.message, {
            variant: "success",
          });
        }
        if (errorEditProject) {
          enqueueSnackbar(errorEditProject.message, {
            variant: "error",
          });
        }
      }
    } catch (error) {
      enqueueSnackbar("An unknown error occurred", {
        variant: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };
  const onProjectChange = (e) => {
    setProjectName(e.target.value);
  };

  const clearFields = () => {
    try {
      setProjectName(""); // Resets the project name input field
      setSelectedClient(null); // Resets the client selection
    } catch (e) {
      console.error(e);
    }
  };

  //On deleting project
  const deleteProjectFromTable = async (id) => {
    try {
      // Dispatch action to delete project
      const response = await deleteProject(id).unwrap();
      if (!loadingDeleteProject) {
        if (response) {
          enqueueSnackbar(response.message, {
            variant: "success",
          });
        }
        if (errorDeleteProject) {
          enqueueSnackbar(errorDeleteProject.message, {
            variant: "error",
          });
        }
      }
    } catch (error) {
      enqueueSnackbar("An unknown error occurred", {
        variant: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };
  //on Adding Project
  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (submitting) return;
    try {
      setSubmitting(true);
      // Dispatch action to create new project
      const response = await addProject({
        clientName: selectedClient,
        projectName,
      }).unwrap();
      enqueueSnackbar(response.message, { variant: "success" });
      clearFields();
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Failed to add Project. Please try again.", {
        variant: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };
  //Show edit Window
  const showEditWindow = (data) => {
    setEditClicked(true);
    setEditData(data);
  };
  //On closing edit window
  const onClose = () => {
    setEditClicked(false);
  };
  //On Update Project
  const onUpdate = (project) => {
    editProjectFromTable(project);
    onClose();
  };
  const searchProject = (args) => {
    const filterdData = [...projectData];
    if (args?.target.value !== "") {
      const searchedFound = filterdData?.filter((element) => {
        if (
          element.projectName
            .toLowerCase()
            .includes(args.target.value.toLowerCase())
        ) {
          return element;
        }
      });
      setProjectData(searchedFound);
    } else {
      setProjectData(filteredData);
    }
  };
  //Getting Project table data from backend
  useEffect(() => {
    if (!loadingProjects) {
      if (dataProjects?.data) {
        setProjectData(dataProjects.data);
        setFilteredData(dataProjects.data);
      }
      if (errorprojects) {
        enqueueSnackbar(`Failed to load projects!`, {
          variant: "error",
        });

        setProjectData([]);
      }
    }
  }, [loadingProjects, dataProjects, errorprojects]);
  return (
    <div className="setting-diagram-container-overview">
      <text>Projects</text>
      <div className="setting-diagram-frame">
        <div className="setting-diagram-newProject">
          <text>New Project</text>
          <div className="setting-diagram-projectName-frame">
            <div className="setting-diagram-projectName">
              <div className="setting-diagram-projectName-label">
                <p1>project name</p1>
                <p2>*</p2>
              </div>
              <input
                className="setting-diagram-projectName-input"
                placeholder="Enter project name"
                value={projectName}
                onChange={onProjectChange}
                required
              />
            </div>
            <div className="setting-diagram-projectClient">
              <div className="setting-diagram-projectClient-label">
                <p1>Client</p1>
                <p2>*</p2>
              </div>
              <DropDownListComponent
                className="setting-diagram-projectClient-input"
                placeholder="Select Client"
                dataSource={clientData} // Set client data as dataSource
                fields={{ text: "companyName", value: "_id" }} // Define the fields to display
                value={selectedClient} // Bind selectedClient state to the value property
                change={(e) => setSelectedClient(e.value)} // Handle change event to update selected client
                style={{ height: "38px" }}
              />
            </div>
          </div>
          <button
            className="setting-diagram-create-new-project"
            onClick={(e) => onFormSubmit(e)}
          >
            <div className="setting-diagram-create-new-project-container">
              <text>Create New Project</text>
            </div>
          </button>
        </div>
        <div className="setting-diagram-Projects">
          <text>Projects</text>
          <div className="setting-diagram-admin-search">
            <input
              className="setting-diagram-Projects-search-input"
              onChange={(e) => {
                searchProject(e);
              }}
              placeholder="Search Projects"
            ></input>{" "}
            <span>
              <img src={SearchClient} />
            </span>
          </div>
          <div className="setting-diagram-Projects-table">
            <table>
              <thead>
                <tr className="setting-diagram-Projects-table-hdr">
                  <th className="setting-diagram-Project-hdr">Project</th>
                  <th className="setting-diagram-Project-company">Company</th>
                  <th className="setting-diagram-Project-email">Email</th>
                  <th className="setting-diagram-Project-action">Actions</th>
                </tr>
              </thead>
              <tbody>
                {loadingProjects ? (
                  <Loader />
                ) : (
                  projectData.length > 0 &&
                  projectData.map((item, index) => (
                    <tr
                      key={index}
                      className="setting-diagram-Projects-table-body"
                    >
                      <td className="setting-diagram-body-Project">
                        {item?.projectName}
                      </td>
                      <td className="setting-diagram-company-Project">
                        {item?.clientName.companyName}
                      </td>
                      <td className="setting-diagram-email-Project">
                        {item?.clientName.primaryEmail}
                      </td>
                      <td className="setting-diagram-status-Project">
                        <button
                          className="setting-diagram-status-edit"
                          onClick={() => showEditWindow(item)}
                        >
                          <div className="setting-clients-diagram-status-edit-label">
                            <text>Edit</text>
                          </div>
                        </button>
                        <button
                          className="setting-diagram-status-delete"
                          onClick={() => deleteProjectFromTable(item._id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <div className="setting-client-scrollbar">
              {editClicked && (
                <UpdateProject
                  onClose={onClose}
                  project={editdata}
                  onUpdate={onUpdate}
                  clientData={clientData}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProject;
