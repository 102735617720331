import "./index.css";

const Banner = () => {
  return (
    <div className="banner">
      <div className="heading">
        <div className="heading_texts">
          <p1>MediaMesh</p1>
          <p2>
            The platform for media workflow visualization, analysis and
            automation
          </p2>
          {/* <button></button> */}
        </div>
      </div>
    </div>
  );
};
export default Banner;
