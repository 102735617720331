import * as React from "react";
import { useState, useRef, useContext } from "react";
import { useSnackbar } from "notistack";
import {
  DiagramComponent,
  DiagramTools,
  NodeConstraints,
  DiagramConstraints,
  ConnectorConstraints,
  PrintAndExport,
  Inject,
  Node,
} from "@syncfusion/ej2-react-diagrams";
import ConfigTemplateWindow from "./configTemplateWindow";
import Configuration from "../ConfigWindow";
import env from "react-dotenv";
import { useSelector } from "react-redux/es/hooks/useSelector";
import useConfigData from "../../service/configApi";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { ToolbarComponent } from "@syncfusion/ej2-react-navigations";
import "./material.css";
import "./index.css";
import { DropDownButton } from "@syncfusion/ej2-react-splitbuttons";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import { ListViewComponent } from "@syncfusion/ej2-react-lists";
import arrow from "../../images/arrow_drop_down.png";
import eye from "../../images/remove_red_eye.png";
import task_icon from "../../images/add_task.png";
import participant_icon from "../../images/people_outline.png";
import infra_icon from "../../images/developer_board.png";
import asset_icon from "../../images/Group.png";
import carbon_icon from "../../images/local_florist.png";
const connectors = [];
const nodes = [];

const sample_css = `
#conTypeBtn{
  background-color: transparent;
  border-color: transparent;
}
#exportBtn{
  background-color: transparent;
  border-color: transparent;
}
#shapesBtn{
  background-color: transparent;
  border-color: transparent;
}
#alignBtn{
  background-color: transparent;
  border-color: transparent;
}
#distributeBtn{
  background-color: transparent;
  border-color: transparent;
}
#orderBtn{
  background-color: transparent;
  border-color: transparent;
}
#groupBtn{
  background-color: transparent;
  border-color: transparent;
}
#rotateBtn{
  background-color: transparent;
  border-color: transparent;
}
#flipBtn{
  background-color: transparent;
  border-color: transparent;
}
#btnZoomIncrement{
  background-color: transparent;
  border-color: transparent;
}
`;

let interval;
interval = [
  1, 9, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75,
  0.25, 9.75, 0.25, 9.75, 0.25, 9.75,
];
let gridlines = {
  lineColor: "#e0e0e0",
  lineIntervals: interval,
};

function getContent(content) {
  // Improved function to build table headers
  function buildHeaders(headers) {
    if (!Array.isArray(headers)) return ``;
    return headers.map((header) => `<th rowspan="1">${header}</th>`).join("");
  }

  // Improved function to build table rows
  function buildRows(body, toolNames) {
    if (body.length === 0)
      return "<tr><td rowspan='1'>Not Configured</td></tr>";
    return body
      .map((item, index) => {
        return toolNames[index]
          ? `
      <tr>
        <td class="tdRow" >${item}</td>
        <td class="tdRow">${toolNames[index] || ""}</td>
      </tr>
    `
          : `
    <tr>
      <td class="tdRow" >${item}</td>
    </tr>
  `;
      })
      .join("");
  }

  const tableHtml = `
    <table border="1">
    <div class="tableTitle">
    <text class="tableTitleHeader">${content["header"]}</text>
  </div>
      <tr>${buildHeaders(content.header)}</tr>
      ${buildRows(content.body, content.toolName || [])}
    </table>
  `;

  let tooltipContent = document.createElement("div");
  tooltipContent.innerHTML = `<div class="contentContainer">${tableHtml}</div>`;
  return tooltipContent;
}

function getCarbonContent(content) {
  let totalKgco2e = 0;
  let totalkwh = 0;
  const tableHtml = `
    <table border="1">
    <div class="tableTitle"> 
    <text class="tableTitleHeader">Carbon Footprint</text></div>
    
  ${
    Object.keys(content["body"]).length > 0
      ? `<tr>
  <th rowspan="1"></th>
  <th>KgCO2e</th>
  <th>kWh</th>
</tr>` +
        Object.keys(content["body"])
          .map((element) => {
            totalKgco2e = totalKgco2e + content["body"][element].KgCO2e;
            totalkwh = totalkwh + content["body"][element].kWh;
            var shortName = "";
            if (element === "PremisesSharedFunctions") {
              shortName = "Shared Functions";
            } else if (element === "SharedOrganizationSupportDepartment") {
              shortName = "Shared Organization";
            } else if (element === "SharedCloudConsumption") {
              shortName = "Shared Cloud";
            }
            return `
    <tr>
    <th rowspan="1">${shortName ? shortName : element}</th>
      <td rowspan="1">${content["body"][element].KgCO2e}</td>
      <td rowspan="1">${content["body"][element].kWh}</td>
    </tr>
   
  `;
          })
          .join("") +
        `<tr>
          <th rowspan="1">Total</th>
            <td rowspan="1">${totalKgco2e.toFixed(2)}</td>
            <td rowspan="1">${totalkwh.toFixed(2)}</td>
          </tr>
          
          `
      : "<tr><th rowspan='1'>We are Preparing your carbon score.</th></tr>"
  }
     
      
    </table>
    <div class="tableTitleContainer"> 
    <text class="tableTitleFooter">${(content["totalWorkflow"] ? Object.keys(content["totalWorkflow"]).length > 0 : false) ? "Workflow Score: " + content.totalWorkflow.KgCO2e : " "}</text></div>

  `;

  let tooltipContent = document.createElement("div");
  tooltipContent.innerHTML = `<div class="contentContainer"> <span>${tableHtml} </span> </div>`;
  return tooltipContent;
}
const filterWorkflowData = [
  {
    _id: "Task",
    name: "Task View",
    image: task_icon,
    text: "ACTION ITEMS & MILESTONES",
  },
  {
    _id: "Participant",
    name: "Participant View",
    image: participant_icon,
    text: "CREW & TALENT ALLOCATION",
  },
  {
    _id: "Infrastructure",
    name: "Infrastructure View",
    image: infra_icon,
    text: "TECH & FACILITY NEEDS",
  },
  {
    _id: "Asset",
    name: "Asset View",
    image: asset_icon,
    text: "ASSER DISTRIBUTION & USAGE",
  },
  {
    _id: "Context",
    name: "Carbon Footprint",
    image: carbon_icon,
    text: "ECO IMPACT BY ACTIVITY",
  },
];

let flowshapes = [
  { id: "Infrastructure", shape: { type: "Flow", shape: "Terminator" } },

  { id: "Document", shape: { type: "Flow", shape: "Document" } },

  { id: "Context", shape: { type: "Flow", shape: "ManualOperation" } },

  { id: "Asset", shape: { type: "Event", shape: "Task" } },
  {
    id: "Participant",
    shape: { type: "Flow", shape: "Preparation" },
  },

  { id: "Task", shape: { type: "Flow", shape: "ManualOperation" } },
];
let diagramInstance;
let toolbarEditor;

function Default() {
  const {userGroup } = useSelector((state) => state.auth);
  const { token } = useSelector((state) => state.auth);
  const [apiUrl, setApiUrl] = useState(process.env?.REACT_APP_API_URL);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [data, setData] = useState({});
  const { configCtx } = useConfigData();
  const {
    workflow,
    workflowId,
    configId,
    status,
    apiData,
    saveData,
    workflowData: workflowDetails,
    fileName,
    carbonScore,
  } = useContext(configCtx);

  let toolbarItems = [
    {
      type: "Input",
      tooltipText: "File Name",
      template: `<text >${fileName}</text>`,
      cssClass: "filenameText",
    },
    { prefixIcon: "e-icons e-folder-open", tooltipText: "Open Diagram" },
    // { prefixIcon: "e-icons e-save", tooltipText: "Save Diagram" },
    // { prefixIcon: "e-print e-icons", tooltipText: "Print Diagram" },
    // {
    //   type: "Input",
    //   tooltipText: "Export Diagram",
    //   template: '<button id="exportBtn" style="width:100%;"></button>',
    // },
    // { type: "Separator" },
    // {
    //   disabled: true,
    //   prefixIcon: "e-cut e-icons",
    //   tooltipText: "Cut",
    //   cssClass: "tb-item-middle tb-item-lock-category",
    // },
    // {
    //   disabled: true,
    //   prefixIcon: "e-copy e-icons",
    //   tooltipText: "Copy",
    //   cssClass: "tb-item-middle tb-item-lock-category",
    // },

    { type: "Separator" },
    {
      prefixIcon: "e-pan e-icons",
      tooltipText: "Pan Tool",
      cssClass: "tb-item-start pan-item",
    },
    {
      prefixIcon: "e-mouse-pointer e-icons",
      tooltipText: "Select Tool",
      cssClass: "tb-item-middle tb-item-selected",
    },

    // { type: "Separator" },
    // {
    //   disabled: false,
    //   prefixIcon: "e-icons e-lock",
    //   tooltipText: "Lock",
    //   cssClass: "tb-item-middle tb-item-lock-category",
    // },
    // {
    //   disabled: true,
    //   prefixIcon: "e-trash e-icons",
    //   tooltipText: "Delete",
    //   cssClass: "tb-item-middle tb-item-lock-category",
    // },
    { type: "Separator", align: "Center" },
    {
      disabled: false,
      tooltipText: "Valid Configuration",
      template: '<div id="colorBtnGreen" style="   "></div>',
      cssClass: "tb-item-middle",
    },

    {
      disabled: false,
      tooltipText: "Missing Configuration",
      template: '<div id="colorBtnYellow" style="   "></div>',
      cssClass: "tb-item-middle",
    },
    {
      disabled: false,
      tooltipText: "Invalid Configuration",
      template: '<div id="colorBtnRed" style="   "></div>',
      cssClass: "tb-item-middle",
    },
    {
      disabled: false,
      tooltipText: "Highlight Configuration",
      template: '<div id="colorBtnBlue" style="   "></div>',
      cssClass: "tb-item-middle",
    },
    // {
    //   disabled: true,
    //   type: "Input",
    //   tooltipText: "Distribute Objects",
    //   template: '<button id="distributeBtn" style="width:100%;"></button>',
    //   cssClass: "tb-item-middle tb-item-space-category",
    // },
    // { type: "Separator" },
    // {
    //   disabled: true,
    //   type: "Input",
    //   tooltipText: "Order Commands",
    //   template: '<button id="orderBtn" style="width:100%;"></button>',
    //   cssClass: "tb-item-middle tb-item-lock-category",
    // },
    // { type: "Separator" },
    // {
    //   disabled: true,
    //   type: "Input",
    //   tooltipText: "Group/Ungroup",
    //   template: '<button id="groupBtn" style="width:100%;"></button>',
    //   cssClass: "tb-item-middle tb-item-align-category",
    // },
    // { type: "Separator" },
    // {
    //   disabled: true,
    //   type: "Input",
    //   tooltipText: "Rotate",
    //   template: '<button id="rotateBtn" style="width:100%;"></button>',
    //   cssClass: "tb-item-middle tb-item-lock-category",
    // },
    // { type: "Separator" },
    // {
    //   disabled: true,
    //   type: "Input",
    //   tooltipText: "Flip",
    //   template: '<button id="flipBtn" style="width:100%;"></button>',
    //   cssClass: "tb-item-middle tb-item-lock-category",
    // },
    { type: "Separator" },
    {
      cssClass: "tb-item-end tb-zoom-dropdown-btn",
      template: '<button id="btnZoomIncrement"></button>',
    },
  ];

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const showOverlay = () => {
    setIsOverlayVisible(true);
  };
  const hideOverlay = () => {
    setIsOverlayVisible(false);
  };
  const hideTemplateOverlay = () => {
    setTemplateIsOverlayVisible(false);
  };
  function getPorts(obj) {
    let ports = [
      { id: "port1", shape: "Circle", offset: { x: 0, y: 0.5 } },
      { id: "port2", shape: "Circle", offset: { x: 0.5, y: 1 } },
      { id: "port3", shape: "Circle", offset: { x: 1, y: 0.5 } },
      { id: "port4", shape: "Circle", offset: { x: 0.5, y: 0 } },
    ];
    return ports;
  }
  let isMobile;
  function addEvents() {
    isMobile = window.matchMedia("(max-width:550px)").matches;
    if (isMobile) {
      let paletteIcon = document.getElementById("palette-icon");
      if (paletteIcon) {
        paletteIcon.addEventListener("click", openPalette, false);
      }
    }
  }
  function openPalette() {
    let paletteSpace = document.getElementById("palette-space");
    isMobile = window.matchMedia("(max-width:550px)").matches;
    if (isMobile) {
      if (!paletteSpace.classList.contains("sb-mobile-palette-open")) {
        paletteSpace.classList.add("sb-mobile-palette-open");
      } else {
        paletteSpace.classList.remove("sb-mobile-palette-open");
      }
    }
  }
  function printDiagram(args) {
    let options = {};
    options.mode = "Download";
    options.region = "Content";
    options.multiplePage = diagramInstance.pageSettings.multiplePage;
    options.pageHeight = diagramInstance.pageSettings.height;
    options.pageWidth = diagramInstance.pageSettings.width;
    diagramInstance.print(options);
  }
  function enableItems() {
    toolbarEditor.items[6].disabled = false;
    toolbarEditor.items[7].disabled = false;
    toolbarEditor.items[19].disabled = false;
    toolbarEditor.items[20].disabled = false;
    toolbarEditor.items[25].disabled = false;
    toolbarEditor.items[29].disabled = false;
    toolbarEditor.items[31].disabled = false;
  }
  function disableMultiselectedItems() {
    toolbarEditor.items[22].disabled = true;
    toolbarEditor.items[23].disabled = true;
    toolbarEditor.items[27].disabled = true;
  }
  const showTemplateOverlay = () => {
    setTemplateIsOverlayVisible(true);
  };
  function toolbarClick(args) {
    let item = args.item.tooltipText;
    switch (item) {
      case "Pan Tool":
        diagramInstance.clearSelection();
        diagramInstance.tool = DiagramTools.ZoomPan;
        break;
      case "Lock":
        lockObject(args);
        break;
      case "New Diagram":
        diagramInstance.clear();
        historyChange(args);
        break;
      case "Print Diagram":
        printDiagram(args);
        break;
      case "Save Diagram":
        download(diagramInstance.saveDiagram());
        break;
      case "Open Diagram":
        showTemplateOverlay();
        break;
    }
    diagramInstance.dataBind();
  }
  function zoomChange(args) {
    let zoomCurrentValue =
      document.getElementById("btnZoomIncrement").ej2_instances[0];
    let currentZoom = diagramInstance.scrollSettings.currentZoom;
    let zoom = {};
    switch (args.item.text) {
      case "Zoom In":
        diagramInstance.zoomTo({ type: "ZoomIn", zoomFactor: 0.2 });
        zoomCurrentValue.content =
          (diagramInstance.scrollSettings.currentZoom * 100).toFixed() + "%";
        break;
      case "Zoom Out":
        diagramInstance.zoomTo({ type: "ZoomOut", zoomFactor: 0.2 });
        zoomCurrentValue.content =
          (diagramInstance.scrollSettings.currentZoom * 100).toFixed() + "%";
        break;
      case "Zoom to Fit":
        diagramInstance.fitToPage({ mode: "Page", region: "Content" });
        zoomCurrentValue.content = diagramInstance.scrollSettings.currentZoom;
        break;
      case "Zoom to 50%":
        if (currentZoom === 0.5) {
          currentZoom = 0;
          zoom.zoomFactor = 0.5 / currentZoom - 1;
          diagramInstance.zoomTo(zoom);
        } else {
          zoom.zoomFactor = 0.5 / currentZoom - 1;
          diagramInstance.zoomTo(zoom);
        }
        break;
      case "Zoom to 100%":
        if (currentZoom === 1) {
          currentZoom = 0;
          zoom.zoomFactor = 1 / currentZoom - 1;
          diagramInstance.zoomTo(zoom);
        } else {
          zoom.zoomFactor = 1 / currentZoom - 1;
          diagramInstance.zoomTo(zoom);
        }
        break;
      case "Zoom to 200%":
        if (currentZoom === 2) {
          currentZoom = 0;
          zoom.zoomFactor = 2 / currentZoom - 1;
          diagramInstance.zoomTo(zoom);
        } else {
          zoom.zoomFactor = 2 / currentZoom - 1;
          diagramInstance.zoomTo(zoom);
        }
        break;
    }
    zoomCurrentValue.content =
      Math.round(diagramInstance.scrollSettings.currentZoom * 100) + " %";
  }
  let asyncSettings;
  function onConnectorSelect(args) {
    diagramInstance.clearSelection();
    diagramInstance.drawingObject = { type: args.item.text };
    diagramInstance.tool = DiagramTools.ContinuousDraw;
    diagramInstance.selectedItems.userHandles = [];
    diagramInstance.dataBind();
  }

  //Export the diagraming object based on the format.
  function onselectExport(args) {
    let exportOptions = {};
    exportOptions.format = args.item.text;
    exportOptions.mode = "Download";
    exportOptions.region = "PageSettings";
    exportOptions.fileName = "Export";
    exportOptions.margin = { left: 0, top: 0, bottom: 0, right: 0 };
    diagramInstance.exportDiagram(exportOptions);
  }
  function onSelectGroup(args) {
    if (args.item.text === "Group") {
      diagramInstance.group();
    } else if (args.item.text === "Ungroup") {
      diagramInstance.unGroup();
    }
  }
  function onSelectAlignObjects(args) {
    let item = args.item.text;
    let alignType = item.replace("Align", "");
    let alignType1 = alignType.charAt(0).toUpperCase() + alignType.slice(1);
    diagramInstance.align(alignType1.trim());
  }
  function onSelectDistributeObjects(args) {
    if (args.item.text === "Distribute Objects Vertically") {
      diagramInstance.distribute("BottomToTop");
    } else {
      diagramInstance.distribute("RightToLeft");
    }
  }

  // To flip diagram objects

  function download(data) {
    if (window.navigator.msSaveBlob) {
      let blob = new Blob([data], { type: "data:text/json;charset=utf-8," });
      window.navigator.msSaveOrOpenBlob(blob, "Diagram.json");
    } else {
      let dataString =
        "data:text/json;charset=utf-8," + encodeURIComponent(data);
      let ele = document.createElement("a");
      ele.href = dataString;
      ele.download = "Diagram.json";
      document.body.appendChild(ele);
      ele.click();
      ele.remove();
    }
  }

  //open Diagram
  const openDiagram = async (id) => {
    const filedata = await fetch(`${apiUrl}/api/getFile/${id}`, {
      method: "get",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    });
    try {
      if (filedata.status === 200) {
        const response1 = await filedata.json();

        loadDiagram1(response1.data.workflowJson);
        if (response1?.data.workflowData) {
          saveData(
            selectedworkflow,
            selectedId,
            selectedId,
            "",
            "",
            response1.data?.workflowData,
            response1.data?.name,
            response1.data?.carbonScore ? response1.data?.carbonScore : {}
          );
        } else {
          saveData(
            selectedworkflow,
            selectedId,
            "",
            "",
            "",
            "",
            response1.data?.name,
            response1.data?.carbonScore ? response1.data?.carbonScore : {}
          );
        }
      }
    } catch (error) {
      return "No Resonse from API!";
    }
  };
//getting workflows
  const getWorkflow = async () => {
    const filedata = await fetch(`${apiUrl}/api/getWorkflows/${userGroup}`, {
      method: "get",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }).catch((error) => {
      setWorkflowData([]);
      return "No Resonse from API!";
    });
    try {
      if (filedata.status === 200) {
        const response1 = await filedata.json();
        setWorkflowData(response1.data);
      }
    } catch (error) {
      setWorkflowData([]);
      return "No Resonse from API!";
    }
  };
//getting configured workflows
  const getConfiguredWorkflow = async () => {
    const filedata = await fetch(`${apiUrl}/api/getConfiguredWorkflows/${userGroup}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }).catch((error) => {
      setConfiguredWorkflowData([]);
      return "No Resonse from API!";
    });
    try {
      if (filedata.status === 200) {
        const response1 = await filedata.json();
        setConfiguredWorkflowData(response1.data);
      }
    } catch (error) {
      setConfiguredWorkflowData([]);
      return "No Resonse from API!";
    }
  };
  const cityFields = { text: "name", value: "_id" };
  const [cityText, setCityText] = useState(null);
  const [cityQuery, setCityQuery] = useState(null);
  const cityObj = useRef(null);

  const selectWorkflow = async (args) => {
    hideTemplateOverlay();
    selectedworkflow = args.data.workflowJson;
    selectedId = args.data._id;

    openDiagram(selectedId);
    // loadDiagram1(args.workflowJson);
    // diagramInstance.fitToPage({ mode: "Page", region: "Content" });
    // if (args.workflowData) {
    //   saveData(
    //     selectedworkflow,
    //     selectedId,
    //     selectedId,
    //     "",
    //     "",
    //     args.data?.workflowData,
    //     args.data?.name,
    //     args.data?.carbonScore?args.data?.carbonScore:{},

    //   );
    // } else {
    //   saveData(selectedworkflow, selectedId, "", "", "", "", args.data?.name, args.data?.carbonScore?args.data?.carbonScore:{});
    // }
  };

  const [filter, setFilter] = useState(false);
  const selectFilteredWorkflow = (item) => {
    setFilter(item?._id);
    setIsFilterOpen(false);
  };

  const itemTemplate = (data) => {
    return (
      <div className="workflow-container">
        <div
          className="view-options"
          onClick={() => selectFilteredWorkflow(data)}
        >
          <div className="view-option-button">
            {data.image && <img src={data.image} alt={data.name} />}
            <div className="item-details">
              <div className="task-View">{data.name}</div>
              <div className="sub-task-View">{data.text}</div>
            </div>
          </div>
        </div>
        {data._id === "Asset" && <div className="separator" />}
      </div>
    );
  };
  const filteredData = filter
    ? filterWorkflowData.filter((item) => item._id === filter)
    : filterWorkflowData;

  function lockObject(args) {
    for (let i = 0; i < diagramInstance.selectedItems.nodes.length; i++) {
      let node = diagramInstance.selectedItems.nodes[i];
      if (node.constraints & NodeConstraints.Drag) {
        node.constraints =
          NodeConstraints.PointerEvents | NodeConstraints.Select;
      } else {
        node.constraints = NodeConstraints.Default;
      }
    }
    for (let j = 0; j < diagramInstance.selectedItems.connectors.length; j++) {
      let connector = diagramInstance.selectedItems.connectors[j];
      if (connector.constraints & ConnectorConstraints.Drag) {
        connector.constraints =
          ConnectorConstraints.PointerEvents | ConnectorConstraints.Select;
      } else {
        connector.constraints = ConnectorConstraints.Default;
      }
    }
    diagramInstance.dataBind();
  }
  function refreshOverflow() {
    setTimeout(() => {
      toolbarEditor.refreshOverflow();
    }, 100);
  }
  asyncSettings = {
    saveUrl: "https://ej2.syncfusion.com/services/api/uploadbox/Save",
    removeUrl: "https://ej2.syncfusion.com/services/api/uploadbox/Remove",
  };
  function onUploadSuccess(args) {
    let file = args.file;
    let rawFile = file.rawFile;
    let reader = new FileReader();
    reader.readAsText(rawFile);
    reader.onloadend = loadDiagram;
  }
  function loadDiagram(event) {
    diagramInstance.loadDiagram(event.target.result);
  }
  function loadDiagram1(event) {
    diagramInstance.loadDiagram(event);
  }
  function historyChange(args) {
    if (diagramInstance.historyManager.undoStack.length > 0) {
      toolbarEditor.items[10].disabled = false;
    } else {
      toolbarEditor.items[10].disabled = true;
    }
    if (diagramInstance.historyManager.redoStack.length > 0) {
      toolbarEditor.items[11].disabled = false;
    } else {
      toolbarEditor.items[11].disabled = true;
    }
  }
  let selectedworkflow;
  let selectedId;
  React.useEffect(() => {
    console.log("Clean Up");
    //saveData(null, null, null, null, null, null);
    if (workflow) {
      //loadDiagram1(workflow);
      selectedworkflow = workflow || {};
      selectedId = workflowId || null;
    }
    getWorkflow();
    getConfiguredWorkflow();
  }, []);
  React.useEffect(() => {
    if (workflow && filter) {
      loadDiagram1(workflow);
      diagramInstance.fitToPage({ mode: "Page", region: "Content" });
    }
  }, [filter]);

  const [workflowData, setWorkflowData] = useState([]);
  const [configuredWorkflowData, setConfiguredWorkflowData] = useState([]);
  const [combinedTemplates, setCombinedtemplates] = useState({
    templates: [],
    configuredTemplates: [],
  });
  React.useEffect(() => {
    if (workflow) {
      hideTemplateOverlay();
      loadDiagram1(workflow);
      selectedworkflow = workflow || {};
      selectedId = workflowId || null;
      diagramInstance.fitToPage({ mode: "Page", region: "Content" });
    }
    setCombinedtemplates({
      templates: workflowData,
      configuredTemplates: configuredWorkflowData,
    });
  }, [workflowData, configuredWorkflowData]);

  const { enqueueSnackbar } = useSnackbar();

  const handleOverlayClick = (event) => {
    // Close the overlay if the click is outside the overlay
    if (event.target.id === "templateoverlay") {
      hideTemplateOverlay();
    }
  };
  const [isTemplateOverlayVisible, setTemplateIsOverlayVisible] =
    useState(true);
  const workflowObj = useRef(null);
  const workflowFields = { text: "name", value: "_id" };
  const [workflowText] = useState(null);
  const [workflowQuery] = useState(null);
  return (
    <div className="control-pane1">
      <div>
        {isOverlayVisible && (
          <div className="overlay">
            {/* Your overlay content goes here */}
            <Configuration onClose={hideOverlay} data={data} />
          </div>
        )}
      </div>

      <div>
        {isTemplateOverlayVisible &&
          combinedTemplates.configuredTemplates.length > 0 && (
            <div
              id="templateoverlay"
              className="overlay"
              onClick={handleOverlayClick}
            >
              <div>
                {/* Your template overlay content goes here */}
                <ConfigTemplateWindow
                  onClose={hideOverlay}
                  workflowData={combinedTemplates}
                  workflowObj={workflowObj}
                  workflowFields={workflowFields}
                  workflowText={workflowText}
                  workflowQuery={workflowQuery}
                  selectWorkflow={selectWorkflow}
                />
              </div>
            </div>
          )}
      </div>
      <style>{sample_css}</style>
      <div className="control-section">
        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
          <div style={{ display: "none" }}>
            <UploaderComponent
              id="fileUpload"
              type="file"
              showFileList={false}
              asyncSettings={asyncSettings}
              success={onUploadSuccess}
            ></UploaderComponent>
          </div>
          <div className="db-toolbar-container">
            <ToolbarComponent
              ref={(toolbar) => (toolbarEditor = toolbar)}
              id="toolbar_diagram"
              created={() => {
                if (diagramInstance !== undefined) {
                  let conTypeBtn = new DropDownButton({
                    items: [
                      { text: "Straight", iconCss: "e-icons e-line" },
                      {
                        text: "Orthogonal",
                        iconCss: "sf-diagram-icon-orthogonal",
                      },
                      { text: "Bezier", iconCss: "sf-diagram-icon-bezier" },
                    ],
                    iconCss: "e-diagram-icons1 e-diagram-connector e-icons",
                    select: function (args) {
                      onConnectorSelect(args);
                    },
                  });
                  conTypeBtn.appendTo("#conTypeBtn");
                  let btnZoomIncrement = new DropDownButton({
                    items: [
                      { text: "Zoom In" },
                      { text: "Zoom Out" },
                      { text: "Zoom to Fit" },
                      { text: "Zoom to 50%" },
                      { text: "Zoom to 100%" },
                      { text: "Zoom to 200%" },
                    ],
                    content:
                      Math.round(
                        diagramInstance.scrollSettings.currentZoom * 100
                      ) + " %",
                    select: zoomChange,
                  });
                  btnZoomIncrement.appendTo("#btnZoomIncrement");
                  let shapesBtn = new DropDownButton({
                    items: [
                      { text: "Rectangle", iconCss: "e-rectangle e-icons" },
                      { text: "Ellipse", iconCss: " e-circle e-icons" },
                      { text: "Polygon", iconCss: "e-line e-icons" },
                    ],
                    iconCss: "e-shapes e-icons",
                  });
                  shapesBtn.appendTo("#shapesBtn");
                  let exportBtn = new DropDownButton({
                    items: [{ text: "JPG" }, { text: "PNG" }, { text: "SVG" }],
                    iconCss: "e-icons e-export",
                    select: function (args) {
                      onselectExport(args);
                    },
                  });
                  exportBtn.appendTo("#exportBtn");
                  let groupBtn = new DropDownButton({
                    items: [
                      { text: "Group", iconCss: "e-icons e-group-1" },
                      { text: "Ungroup", iconCss: "e-icons e-ungroup-1" },
                    ],
                    iconCss: "e-icons e-group-1",
                    select: function (args) {
                      onSelectGroup(args);
                    },
                  });
                  groupBtn.appendTo("#groupBtn");
                  let alignBtn = new DropDownButton({
                    items: [
                      {
                        iconCss: "sf-diagram-icon-align-left-1",
                        text: "Align Left",
                      },
                      {
                        iconCss: "sf-diagram-icon-align-center-1",
                        text: "Align Center",
                      },
                      {
                        iconCss: "sf-diagram-icon-align-right-1",
                        text: "Align Right",
                      },
                      {
                        iconCss: "sf-diagram-icon-align-top-1",
                        text: "Align Top",
                      },
                      {
                        iconCss: "sf-diagram-icon-align-middle-1",
                        text: "Align Middle",
                      },
                      {
                        iconCss: "sf-diagram-icon-align-bottom-1",
                        text: "Align Bottom",
                      },
                    ],
                    iconCss: "e-icons e-restart-at-1",
                    select: function (args) {
                      onSelectAlignObjects(args);
                    },
                  });
                  alignBtn.appendTo("#alignBtn");
                  let distributeBtn = new DropDownButton({
                    items: [
                      {
                        iconCss: "sf-diagram-icon-distribute-horizontal",
                        text: "Distribute Objects Vertically",
                      },
                      {
                        iconCss: "sf-diagram-icon-distribute-vertical",
                        text: "Distribute Objects Horizontally",
                      },
                    ],
                    iconCss: "e-icons e-stroke-width",
                    select: function (args) {
                      onSelectDistributeObjects(args);
                    },
                  });
                  distributeBtn.appendTo("#distributeBtn");
                  let orderBtn = new DropDownButton({
                    items: [
                      {
                        iconCss: "e-icons e-bring-forward",
                        text: "Bring Forward",
                      },
                      {
                        iconCss: "e-icons e-bring-to-front",
                        text: "Bring To Front",
                      },
                      {
                        iconCss: "e-icons e-send-backward",
                        text: "Send Backward",
                      },
                      {
                        iconCss: "e-icons e-send-to-back",
                        text: "Send To Back",
                      },
                    ],
                    iconCss: "e-icons e-order",
                  });
                  orderBtn.appendTo("#orderBtn");
                }
              }}
              clicked={toolbarClick}
              items={toolbarItems}
              overflowMode={"Scrollable"}
              width={"75%"}
            ></ToolbarComponent>
            <div
              id="filter-container"
              className="filter-container"
              onClick={toggleFilter}
            >
              <div id="inner-container" class="inner-container">
                <div class="filter-icon">
                  <div className="icon">
                    <img src={eye}></img>
                  </div>
                  <div className="text">
                    {isFilterOpen && cityObj
                      ? cityObj?.current?.value
                        ? "Task View"
                        : "Filter View"
                      : "Filter View"}
                  </div>
                  <div className="icon">
                    <img src={arrow}></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="listViewContainer">
              {isFilterOpen && (
                <div id="list-view-container" className="listView">
                  <ListViewComponent
                    id="filter"
                    dataSource={filterWorkflowData}
                    template={itemTemplate}
                    ref={cityObj}
                    fields={cityFields}
                    headerTitle="Filter View"
                    text={cityText}
                    query={cityQuery}
                    select={selectFilteredWorkflow.bind(this)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="sb-mobile-palette-bar">
            <div
              id="palette-icon"
              style={{ float: "right" }}
              className="e-ddb-icons1 e-toggle-palette"
            ></div>
          </div>

          <div id="diagram-space" className="sb-mobile-diagram-configurator">
            <DiagramComponent
              id="diagram"
              ref={(diagram) => (diagramInstance = diagram)}
              width={"100%"}
              height={"500px"}
              click={(args) => {
                console.log(args);
                if (
                  args?.actualObject?.properties?.annotations[0]?.properties
                    ?.content &&
                  (args?.actualObject?.properties?.shape.properties.shape ===
                    "ManualOperation" ||
                    args?.actualObject?.properties?.shape.properties.type ===
                      "Path")
                ) {
                  if (workflow && (workflowId || configId)) {
                    const state = {
                      name: args.actualObject?.properties?.annotations[0]
                        ?.properties?.content,
                      nodeId: args.actualObject?.properties.id,
                    };
                    setData(state);
                    showOverlay(state);
                  }
                } else {
                  enqueueSnackbar("Please select Tasks for Configuration.", {
                    variant: "error",
                  });
                }
              }}
              created={() => {
                diagramInstance.fitToPage({
                  mode: "Page",
                  region: "Content",
                  margin: {
                    bottom: 10,
                  },
                  canZoomIn: false,
                });
              }}
              constraints={
                DiagramConstraints.Default | DiagramConstraints.Tooltip
              }
              nodes={nodes}
              connectors={connectors} //Sets the default values of a node
              pageSettings={{
                // Sets the PageOrientation for the diagram to page
                orientation: "Landscape",
                width: 1050,
                height: 600,
              }}
              getNodeDefaults={(node) => {
                let obj = {};
                if (obj.width === undefined) {
                  obj.width = 145;
                } else {
                  let ratio = 100 / obj.width;
                  obj.width = 100;
                  obj.height *= ratio;
                }
                obj.style = { fill: "#357BD2", strokeColor: "black" };
                obj.annotations = [
                  { style: { color: "black", fill: "transparent" } },
                ];

                //Cases for filtering
                if (filter) {
                  const filterShape = flowshapes.filter((element) => {
                    return element["id"] === filter;
                  });
                  if (node.shape.shape === filterShape[0]["shape"]["shape"]) {
                    node.style.strokeColor = "rgb(94, 94, 239)";
                    node.style.strokeWidth = "4px";
                  }

                  if (filter === "Infrastructure") {
                    if (
                      node.shape.shape === "ManualOperation" ||
                      node.shape.source ===
                        "/static/media/Task.ecee3fd3e058fc039a6fe22a0efbbdcd.svg"
                    ) {
                      const name = node.annotations[0].content;
                      if (workflowDetails) {
                        const nodeRequired = workflowDetails?.filter(
                          (element) => {
                            return element["taskName"] === name;
                          }
                        );
                        node.tooltip = {
                          //Sets the content of the Tooltip
                          content: getContent({
                            header: ["Infrastructures", "ToolName"],
                            body: nodeRequired[0]
                              ? [nodeRequired[0]?.infrastructureName].concat(
                                  nodeRequired[0]?.infrastructureName_other
                                    ? nodeRequired[0]?.infrastructureName_other
                                    : []
                                )
                              : [],
                            toolName: nodeRequired[0]
                              ? [nodeRequired[0]?.toolProviderName].concat(
                                  nodeRequired[0]?.toolProviderName_other
                                    ? nodeRequired[0]?.toolProviderName_other
                                    : []
                                )
                              : [],
                          }),
                          //Sets the tooltip position relative to the node
                          relativeMode: "Mouse",
                        };
                      }
                    }
                  }
                  if (filter === "Participant") {
                    if (
                      node.shape.shape === "ManualOperation" ||
                      node.shape.source ===
                        "/static/media/Task.ecee3fd3e058fc039a6fe22a0efbbdcd.svg"
                    ) {
                      const name = node.annotations[0].content;
                      if (workflowDetails) {
                        const nodeRequired = workflowDetails?.filter(
                          (element) => {
                            return element["taskName"] === name;
                          }
                        );

                        node.tooltip = {
                          //Sets the content of the Tooltip
                          content: getContent({
                            header: "Participants",
                            body: nodeRequired[0]
                              ? nodeRequired[0]?.participantName
                                ? nodeRequired[0]?.participantName
                                : []
                              : [],
                          }),
                          //Sets the tooltip position relative to the node
                          relativeMode: "Mouse",
                        };
                      }
                    }
                  }
                  if (filter === "Asset") {
                    if (
                      node.shape.shape === "ManualOperation" ||
                      node.shape.source ===
                        "/static/media/Task.ecee3fd3e058fc039a6fe22a0efbbdcd.svg"
                    ) {
                      const name = node.annotations[0].content;
                      if (workflowDetails) {
                        const nodeRequired = workflowDetails?.filter(
                          (element) => {
                            return element["taskName"] === name;
                          }
                        );
                        node.tooltip = {
                          //Sets the content of the Tooltip
                          content: getContent({
                            header: "Assets",
                            body: nodeRequired[0]
                              ? [
                                  nodeRequired[0]?.outputLocation ||
                                    nodeRequired[0]?.inputLocation,
                                ]
                              : [],
                          }),

                          //Sets the tooltip position relative to the node
                          relativeMode: "Mouse",
                        };
                      }
                    }
                  }
                  if (filter === "Context") {
                    if (
                      node.shape.shape === "ManualOperation" ||
                      node.shape.source ===
                        "/static/media/Task.ecee3fd3e058fc039a6fe22a0efbbdcd.svg"
                    ) {
                      const name = node.annotations[0].content;
                      if (workflowDetails) {
                        const nodeRequired = workflowDetails?.filter(
                          (element) => {
                            return element["taskName"] === name;
                          }
                        );
                        node.tooltip = {
                          //Sets the content of the Tooltip
                          content: getCarbonContent({
                            header: "Carbon Score",
                            body: nodeRequired[0]?.carbonScore || {},
                            totalWorkflow: carbonScore ? carbonScore : {},
                          }),
                          //Sets the tooltip position relative to the node
                          relativeMode: "Mouse",
                        };
                      }
                    }
                  }
                  // node.shape = filterShape[0]["shape"];
                }
                //Set ports
                obj.ports = getPorts(node);
                node.constraints =
                  NodeConstraints.PointerEvents |
                  NodeConstraints.Select |
                  NodeConstraints.Tooltip;

                return obj;
              }} //Sets the default values of a connector
              getConnectorDefaults={(obj) => {
                obj.constraints =
                  ConnectorConstraints.PointerEvents |
                  ConnectorConstraints.Select;
                if (obj.id.indexOf("connector") !== -1) {
                  obj.targetDecorator = {
                    shape: "Arrow",
                    width: 10,
                    height: 10,
                  };
                }
              }}
              scrollChange={(args) => {
                if (args.panState !== "Start") {
                  let zoomCurrentValue =
                    document.getElementById("btnZoomIncrement")
                      .ej2_instances[0];
                  zoomCurrentValue.content =
                    Math.round(
                      diagramInstance.scrollSettings.currentZoom * 100
                    ) + " %";
                }
              }}
            >
              <Inject services={[PrintAndExport]} />
            </DiagramComponent>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Default;
