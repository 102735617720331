import React, { useState } from "react";
import "./updateClient.css";
import { useSnackbar } from "notistack";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
const UpdateProject = ({ onClose, project, onUpdate, clientData }) => {
  const [projectName, setProjectName] = useState(project.projectName);
  const [selectedClient, setSelectedClient] = useState(project.clientName._id);
  const { enqueueSnackbar } = useSnackbar();
  const handleUpdate = () => {
    // Check if required dropdown fields are selected
    if (!projectName || !selectedClient   ) {
      enqueueSnackbar("Please select all required fields!", {
        variant: "error",
      });
     
      return; // Prevent further execution of the function
    }
   
    onUpdate({
      projectName,
      clientName: selectedClient,
      id: project._id,
    })
   
  }
  return (
    <div className="assignment-container">
      <div className="assignment-container-frame1">
        <text>Update Project</text>
      </div>
      <div className="assignment-container-frame2">
        <div className="assignment-container-frame3">
          <div className="assignment-module-container">
            <div className="assignment-module1">
              <div className="assignment-module-title">
                <div className="project-label">
                  <text>Project Name</text>
                  <p1>*</p1>
                </div>
                <input
                  className="project-input"
                  placeholder="Project Name"
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                />
              </div>

              <div className="assignment-module-title">
                <div className="project-label">
                  <text>Client Name</text>
                  <p1>*</p1>
                </div>
                <DropDownListComponent
                  className="setting-diagram-projectClient-input"
                  placeholder="Select Client"
                  dataSource={clientData} // Set client data as dataSource
                  fields={{ text: "companyName", value: "_id" }} // Define the fields to display
                  value={selectedClient} // Bind selectedClient state to the value property
                  change={(e) => setSelectedClient(e.value)} // Handle change event to update selected client
                  style={{ height: "38px" }}
                />
              </div>
            </div>
            <div className="assignment-button-frame">
              <button className="assignment-cancel-button" onClick={onClose}>
                <div className="assignment-cancel-button-label">
                  <text>Cancel</text>
                </div>
              </button>
              <button
                className="assignment-create-button"
                onClick={
                  handleUpdate
                }
              >
                <div className="assignment-create-button-label">
                  <text>Update</text>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateProject;
