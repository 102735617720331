import React, { createRef, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { login as loginAuth, clearErrors } from "../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import "./index.css";

const Login = () => {
  const { enqueueSnackbar } = useSnackbar();
  const name = createRef();
  const pass = createRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { user, error, loading } = useSelector((state) => state.auth);

  const { from } = (location && location.state) || {
    from: { pathname: "/dashboard" },
  };

  const [errorsEmail, setErrorsEmail] = useState({});
  const [errorsPass, setErrorsPass] = useState({});
  const handleChange = () => {
    const email = name.current.value;
    const password = pass.current.value;

    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email) {
      setErrorsEmail({ email: "Field can not be empty!" });
    } else {
      if (!regEmail.test(email)) {
        setErrorsEmail({ email: "Invalid Email" });
      } else {
        setErrorsEmail({ email: "" });
      }
    }

    const passwordRegex = /(?=.*[0-9])/;
    if (!password) {
      setErrorsPass({ password: "Field can not be empty!" });
    } else if (password.length < 8) {
      setErrorsPass({ password: "Password must be 8 characters long." });
    } else if (!passwordRegex.test(password)) {
      setErrorsPass({ password: "Invalid password. Must contain one number." });
    } else {
      setErrorsPass({ password: "" });
    }
  };

  useEffect(() => {
    if (!loading) {
      if (user) {
        enqueueSnackbar("Authenticated!", { variant: "success" });
        const searchParams = new URLSearchParams(from.search);
        const id = searchParams.get("open");
        if (id) {
          from.search = "";
          from.search = `?open=${id}`;
        } else {
          from.search = "";
        }
      }
      if (error) {
        enqueueSnackbar(error, { variant: "error" });
        dispatch(clearErrors());
      }

      user && history.replace(from);
    }
  }, [user, from, history, loading]);

  const loginUser = async () => {
    const email = name.current.value;
    const password = pass.current.value;
    await dispatch(loginAuth({ email: email, password: password }));
  };
  return (
    <div className="Auth-form-container">
      <div className="Auth-container">
        <div className="Auth-img-container">
          <img
            src="https://www.riverenza.net/wp-content/uploads/2022/09/Reasons-Why-You-Should-Use-Animated-Video-to-Promote-Your-Business.jpg"
            alt="login"
            width="100%"
            height="100%"
          />
        </div>
        <form className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Login</h3>
            <div className="form-group">
              <label>Email Address</label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter email"
                ref={name}
                onChange={handleChange}
              />
              {errorsEmail ? (
                <div className="error_login">{errorsEmail.email}</div>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter password"
                ref={pass}
                onChange={handleChange}
              />

              {errorsPass ? (
                <div className="error_login">{errorsPass.password}</div>
              ) : (
                ""
              )}
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={loginUser}
              >
                Submit
              </button>
              <p className="forgot-password ">
                Forgot <a href="#">password?</a>
              </p>
            </div>
            <p className="forgot-password text-center error_login">
              {" "}
              {error ? error : null}
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Login;
