import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const apiUrl = process.env.REACT_APP_API_URL;
const conductorUrl = process.env.CONDUCTOR_BACKEND_URL;
const initialState = {
  configuration: {
    loading: false,
    configurationData: "",
    error: null,
  },
  insertConfiguration: {
    loading: false,
    configurationData: "",
    error: null,
  },
};

export const configuration = createAsyncThunk(
  "configuration",
  async (data, thunkAPI) => {
    const {token} = thunkAPI.getState().auth;
    const response = await fetch(`${apiUrl}/api/getConfigurationData`, {
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`
      },
      method: "get",
      body: JSON.stringify(data),
    }).catch((error) => {
      return thunkAPI.rejectWithValue("No Resonse from API!");
    });

    try {
      if (response.status === 200) {
        const response1 = await response.json();
        return response1;
      }
      return thunkAPI.rejectWithValue("No data");
    } catch (error) {
      return thunkAPI.rejectWithValue("No Resonse from API!");
    }
  }
);
export const insertConfigurtion = createAsyncThunk(
  "insertConfiguration",
  async (data, thunkAPI) => {
    const {token,userGroup} = thunkAPI.getState().auth;
    const response = await fetch(
      `${conductorUrl}/dynamicWorkflow/backendApiCall/insertConfigData/${userGroup}`,
      {
        mode: "cors",
        method: "post",
        body: data,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        },
      }
    ).catch((error) => {
      return "No Resonse from API!";
    });
    try {
      if (response.status === 200) {
        const response1 = await response.json();
        return response1;
      }
      return thunkAPI.rejectWithValue("No data");
    } catch (error) {
      return thunkAPI.rejectWithValue("No Resonse from API!");
    }
  }
);
export const ClearConfigurationData = () => (dispatch) => {
  dispatch({ type: "CLEARALL" });
}
const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(configuration.pending, (state) => {
        state.configuration.loading = true;
        state.configuration.configurationData = "";
        state.configuration.error = null;
      })
      .addCase(configuration.fulfilled, (state, action) => {
        state.configuration.loading = false;
        state.configuration.configurationData = action?.payload;
        state.configuration.error = null;
      })
      .addCase(configuration.rejected, (state, action) => {
        state.configuration.loading = false;
        state.configuration.configurationData = "";
        state.configuration.error = action?.payload;
      })
      .addCase(insertConfigurtion.pending, (state) => {
        state.insertConfiguration.loading = true;
        state.insertConfiguration.configurationData = "";
        state.insertConfiguration.error = null;
      })
      .addCase(insertConfigurtion.fulfilled, (state, action) => {
        state.insertConfiguration.loading = false;
        state.insertConfiguration.configurationData = action?.payload;
        state.insertConfiguration.error = null;
      })
      .addCase(insertConfigurtion.rejected, (state, action) => {
        state.insertConfiguration.loading = false;
        state.insertConfiguration.configurationData = "";
        state.insertConfiguration.error = action?.payload;
      })
      .addCase("CLEARALL", (state, action) => {
        state.insertConfiguration.loading = null;
        state.insertConfiguration.configurationData = "";
        state.insertConfiguration.error = null;
      });
  },
});

export default configurationSlice.reducer;
