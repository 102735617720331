import React, { useState,useEffect } from "react";
import "./updateClient.css";
import { useSnackbar } from "notistack";
const UpdateClient = ({ onClose, client, onUpdate }) => {
  const [clientName, setClientName] = useState(client.companyName);
  const [contactName, setContactName] = useState(client.contactName);
  const [primaryEmail, setPrimaryEmail] = useState(client.primaryEmail);
  const [isFormValid, setIsFormValid] = useState(false);
  const [emailError, setEmailError] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const validEmailId = (email) => {
    const regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEmail.test(String(email).toLowerCase());
  };
  const validateForm = () => {
    // Validate email and update email error state
    if (!validEmailId(primaryEmail)) {
      setEmailError("Invalid email Address!");
      setIsFormValid(false);
    } else {
      setEmailError("");
      setIsFormValid(true);
    }
  };

  useEffect(() => {
    if (primaryEmail) {
      validateForm();
    }
  
  }, [primaryEmail]);
  const onPrimaryEmailChange = (e) => {
    const email = e.target.value;
    setPrimaryEmail(email);
   
  };
  const onContactNameChange = (e) => {
    setContactName(e.target.value);
  };
  
  const handleUpdate = () => {
    // Check if required dropdown fields are selected
    if (!clientName || !contactName ||  !primaryEmail  ) {
      enqueueSnackbar("Please select all required fields!", {
        variant: "error",
      });
     
      return; // Prevent further execution of the function
    }
    if (!isFormValid) {
       
      enqueueSnackbar("Invalid Email!", {
        variant: "error",
      });
      return;
    }
    onUpdate({
      clientName,
      contactName,
      primaryEmail,
      id: client._id,
    })
   
  }
  return (
    <div className="assignment-container">
      <div className="assignment-container-frame1">
        <text>Update Client</text>
      </div>
      <div className="assignment-container-frame2">
        <div className="assignment-container-frame3">
          <div className="assignment-module-container">
            <div className="assignment-module1">
              <div className="assignment-module-title">
                <div className="project-label">
                  <text>Client</text>
                  <p1>*</p1>
                </div>
                <input
                  className="project-input"
                  placeholder="Client Name"
                  value={clientName}
                  onChange={(e) => setClientName(e)}
                />
              </div>

              <div className="assignment-module-title">
                <div className="project-label">
                  <text>Contact Name</text>
                  <p1>*</p1>
                </div>
                <input
                  className="project-input"
                  placeholder="Company Name"
                  value={contactName}
                  onChange={(e) => onContactNameChange(e)}
                />
              </div>

              <div className="assignment-module-title">
                <div className="project-label">
                  <text>Primary Email</text>
                  <p1>*</p1>
                </div>
                <input
                  className="project-input"
                  placeholder="Primary email"
                  value={primaryEmail}
                  onChange={(e) => onPrimaryEmailChange(e)}
                />
              </div>
            </div>
            <div className="assignment-button-frame">
              <button className="assignment-cancel-button" onClick={onClose}>
                <div className="assignment-cancel-button-label">
                  <text>Cancel</text>
                </div>
              </button>
              <button
                className="assignment-create-button"
                onClick={handleUpdate}
              >
                <div className="assignment-create-button-label">
                  <text>Update</text>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateClient;
