import * as React from "react";
import { useEffect, useState, useRef, useReducer, useContext } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import {
  DropDownListComponent,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { Query } from "@syncfusion/ej2-data";
import Loader from "../Common/Loader.jsx";
import env from "react-dotenv";
import { configuration as configData } from "../../redux/configurationSlice";
import { apiFileData as apiData1, clearAll } from "../../redux/apiFileSlice";
import { useLocation } from "react-router-dom";
import "./index.css";
import useConfigData from "../../service/configApi";
import dataValues from "./datasource.json";
import { Switch } from "antd";
const Cascading = ({ onClose, data }) => {
  const [resolutionOptions] = useState(["2K", "4K", "6K"]);
  const [isStereoscopic, setIsStereoscopic] = useState(false);
  const [isHdr, setIsHdr] = useState(false);

  const handleStereoscopicToggle = (checked) => {
    setIsStereoscopic(checked);
    dispatch({
      type: "SET_STEREOSCOPIC_VALUE",
      value: checked,
    });
  };

  const handleHdrToggle = (checked) => {
    setIsHdr(checked);
    dispatch({
      type: "SET_HDR_VALUE",
      value: checked,
    });
  };
  const quantity = useRef(null);
  const [countryCodeOptions, setCountryCodeOptions] = useState({
    text: "location",
    value: "location_id",
  });
  const handleCountryCodeChange = (e) => {
    //setSelectedCountryCode(e.value); // Use e.value to get the selected value

    dispatch({
      type: "SET_TASK_LOCATION",
      value: e.value, // Use e.value to get the selected value
      name: e.text, // Use e.text to get the selected text
      id: e.itemData.ref_id, //FXdmz reference id
    });
  };

  const { token, userGroup } = useSelector((state) => state.auth);
  const conductorUrl = env.CONDUCTOR_BACKEND_URL;
  const { enqueueSnackbar } = useSnackbar();
  const [filedata, setFileData] = useState([]);
  const dispatch1 = useDispatch();
  const location = useLocation();
  const { configCtx } = useConfigData();
  const inputObj = useRef(null);
  const taskLocationObj = useRef(null);
  const masterResolutionObj = useRef(null);
  const [edit, setEdit] = useState(false); //It is set false when we need to prepopulate data from workflow data else it is true (Also dependency dropdown dooesnt function in case of edit stage )
  var { workflow, workflowId, configId, saveData, workflowData } =
    useContext(configCtx); // The data of the workflow selected
  const [nodeId] = useState(location?.state?.nodeId || data.nodeId);
  const apiNameObj = useRef(null);
  const fileObj = useRef(null);
  const [task] = useState(location?.state?.name || data.name);
  const [presetNode, setPresetNode] = useState(null);
  const [toolProviderQueryFinal, setToolProviderQueryFinal] = useState(null);
  const [infrastructureQuery, setInfrastructureQuery] = useState(null);
  const [toolProviderQueryFinalOther] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    loading: loadingApi,
    apiFileData,
    error: errorApi,
  } = useSelector((state) => state.apiFile.api);

  const insert = async () => {
    setLoading(true);
    const insertConfigData = await fetch(
      `${conductorUrl}/dynamicWorkflow/backendApiCall/insertConfigData/${userGroup}`,
      {
        mode: "cors",
        method: "post",
        body: JSON.stringify({
          workflowJson: workflow,
          configId: configId,
          workflowId: workflowId,
          status: "Pending",
          apidata: state,
          nodeId: nodeId,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch((error) => {
      return "No Resonse from API!";
    });
    try {
      if (insertConfigData.status === 200) {
        const response1 = await insertConfigData.json();
        if (response1) {
          saveData(
            response1.workflowJson,
            workflowId,
            response1.configId,
            "Configuration Started",
            state,
            response1.workflowData
          );

          onClose();
          enqueueSnackbar(`Configuration Mapped for ${task}`, {
            variant: "success",
          });
          setLoading(false);
        } else {
          enqueueSnackbar(`No response from api`, {
            variant: "error",
          });
        }
      }
    } catch (error) {
      enqueueSnackbar(`Configuration could not be mapped`, {
        variant: "error",
      });
    }
  };
  //When we fetch Api file dropdown data
  useEffect(() => {
    if (!loadingApi) {
      if (apiFileData.data) {
        setFileData(apiFileData.data);
        if (presetNode && edit === false) {
          if (filedata.length !== 0) {
            fileObj.current.value = presetNode.conf_file;
            dispatch1(clearAll());
          }
          setEdit(true);
        } else {
          dispatch1(clearAll());
        }
      }

      if (errorApi) {
        setFileData([]);
        apiNameObj.current.value = null;
        enqueueSnackbar(`Api File data Could not be loaded!`);
      }
    }
  }, [loadingApi, apiFileData, presetNode, filedata]);
  const initState = {
    taskSubType: "",
    taskType: "",
    participants: [],
    infrastructure_other: [],
    toolProvider_other: [],
    vendor_other: [],
    toolProviderName_other: [],
    vendorName_other: [],
    infrastructureName_other: [],
    toolProvider: "",
    workflowId: workflowId,
    vendor: "",
    infrastructure: "",
    inputLocation: "",
    outputLocation: "",
    conf_file: "",
    taskName: task,
    infrastructureName: "",
    participantName: "",
    taskSubTypeName: "",
    toolProviderName: "",
    vendorName: "",
    hdr: false,
    steroscopic_value: false,
    master_resolution: "",
    task_location: "",
    quantity: "",
  };
  const changeFile = (args) => {
    dispatch({ type: "SET_CONF_FILE", value: args.value });
    if (args.itemData) {
      apiNameObj.current.value = args.itemData?.apiUrlRef?.apiName;
    }
  };
  const configReducer = (state, action) => {
    switch (action.type) {
      case "TASK_NAME":
        return {
          ...state,
          taskName: action.value,
        };
      case "SET_TYPE":
        return {
          ...state,
          taskType: action.value,
        };
      case "SET_SUBTYPE":
        return {
          ...state,
          taskSubType: action.value,
          taskSubTypeName: action.name,
        };
      case "SET_TOOLPROVIDER":
        return {
          ...state,
          toolProvider: action.value,
          toolProviderName: action.name,
        };
      case "SET_VENDOR":
        return {
          ...state,
          vendor: action.value,
          vendorName: action.name,
        };
      case "SET_PARTICIPANTS":
        return {
          ...state,
          participants: action.value,
          participantName: [],
        };

      case "SET_INFRASTRUCTURE":
        return {
          ...state,
          infrastructure: action.value,
          infrastructureName: action.name,
        };
      case "SET_TOOLPROVIDER_INFRA":
        return {
          ...state,
          toolProvider_other: [...state.toolProvider_other, action.value],
          toolProviderName_other: [
            ...state.toolProviderName_other,
            action.name,
          ],
        };
      case "SET_VENDOR_INFRA":
        return {
          ...state,
          vendor_other: [...state.vendor_other, action.value],
          vendorName_other: [...state.vendorName_other, action.name],
        };

      case "SET_OTHER_INFRASTRUCTURE":
        return {
          ...state,
          infrastructure_other: [...state.infrastructure_other, action.value],
          infrastructureName_other: [
            ...state.infrastructureName_other,
            action.name,
          ],
        };
      case "SET_WORKFLOW_ID":
        return {
          ...state,
          workflowId: action.value,
        };
      case "SET_INPUT_LOC":
        return {
          ...state,
          inputLocation: action.value,
        };
      case "SET_OUTPUT_LOC":
        return {
          ...state,
          outputLocation: action.value,
        };
      case "SET_CONF_FILE":
        return {
          ...state,
          conf_file: action.value,
        };
      case "SET_STEREOSCOPIC_VALUE":
        return {
          ...state,
          steroscopic_value: action.value,
        };
      case "SET_HDR_VALUE":
        return {
          ...state,
          hdr: action.value,
        };
      case "SET_TASK_LOCATION":
        return {
          ...state,
          task_location: {
            value: action.value,
            name: action.text,
            id: action.id,
          },
        };
      case "SET_MASTER_RESOLUTION":
        return {
          ...state,
          master_resolution: action.value,
        };
      case "SET_QUANTITY":
        return {
          ...state,
          quantity: action.value,
        };
      default:
        break;
    }
  };
  //Called in case of edit state or prepopulating data
  const populateConfigurationData = async () => {
    return new Promise((resolve, reject) => {
      try {
        if (workflowData && workflowData.length !== 0) {
          workflowData.forEach((element) => {
            if (element.taskName === task) {
              // Checking the task name selected
              typeObj.current.value = element.taskType;
              subTypeObj.current.value = element.taskSubType;
              if (apiNameObj) {
                apiNameObj.current.value = "";
              }
              infrastructureObj.current.value = element.infrastructure;
              toolProviderObj.current.value = element.toolProvider;
              vendorObj.current.value = element.vendor;
              participantObj.current.value = element.participants;
              quantity.current.value = element.quantity;
              dispatch({
                type: "SET_QUANTITY",
                value: element.quantity,
              });
              setIsHdr(element.hdr);
              setIsStereoscopic(element.steroscopic_value);
              setPresetNode(element);
              taskLocationObj.current.value = element.task_location.value;
              masterResolutionObj.current.value = element.master_resolution;
              resolve(true);
            }
          });
          resolve(false);
        } else {
          resolve(false);
        }
      } catch (error) {
        reject(error);
      }
    });
  };
  //Function to populate all the dropdowns on page load
  const getConfigurationData = async () => {
    const response = await dispatch1(configData());
    const configurationData = response.payload.data;
    setInfrastructureData(configurationData.infrastructure);
    setParticpantsData(configurationData.participant);
    setSubTypeData(configurationData.subtask);
    setTempData(configurationData.task);
    setVendorData(configurationData.vendor);
    setToolProviderData(configurationData.toolprovider);
    populateConfigurationData()
      .then((result) => {
        // Handle the result
        if (!result) {
          setEdit(true);
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  };

  const [state, dispatch] = useReducer(configReducer, initState);
  const [isAllDropDownValid, setDropDownValid] = useState(false);
  const [isApiValidationValid, setIsApiValidationValid] = useState(false);
  const {
    taskSubType,
    taskType,
    participants: partic,
    infrastructure,
    toolProvider,
    vendor,
    outputLocation,
    inputLocation,
    conf_file,
  } = state;
  useEffect(() => {
    getConfigurationData();
  }, []);
  //This use effect for checking the required fields for fetching api dropdown and enabling Save button
  useEffect(() => {
    const ignoreprops = [
      "taskName",
      "hdr",
      "steroscopic_value",
      "master_resolution",
      "task_location",
      "quantity",
      "vendor_other",
      "vendorName_other",
      "toolProvider_other",
      "toolProviderName_other",
      "infrastructure_other",
      "infrastructureName_other",
      "participantName",
    ];
    const ignoreApiProps = [
      "taskName",
      "inputLocation",
      "outputLocation",
      "partic",
      "conf_file",
      "hdr",
      "steroscopic_value",
      "master_resolution",
      "task_location",
      "quantity",
      "vendor_other",
      "vendorName_other",
      "toolProvider_other",
      "toolProviderName_other",
      "infrastructure_other",
      "infrastructureName_other",
      "participantName",
    ];
    if (state.taskType == "651ec184a3df58ffe8f1ea61") {
      ignoreprops.push("inputLocation");
      ignoreprops.push("outputLocation");
    }
    if (state.taskType == "651ec112a3df58ffe8f1ea5f") {
      ignoreprops.push("outputLocation");
      ignoreprops.push("conf_file");
    }
    if (state.taskType == "651ec15ba3df58ffe8f1ea60") {
      ignoreprops.push("inputLocation");
      ignoreprops.push("conf_file");
    }

    const stateprops = Object.keys(state);
    const totalProps = stateprops.filter((prop) => !ignoreprops.includes(prop));

    const isvalid = stateprops
      .filter((prop) => !ignoreprops.includes(prop))
      .every((prop) => {
        return state[prop];
      });
    const isapivalid = stateprops //Checking for getting api drodown
      .filter((prop) => !ignoreApiProps.includes(prop))
      .every((prop) => {
        return state[prop];
      });

    setDropDownValid(isvalid);
    if (isapivalid) {
      setIsApiValidationValid(isapivalid);
      if (state.taskType == "651ec184a3df58ffe8f1ea61") {
        //When i am selecting service
        let editedInfrastructures = [];
        if (edit === false) {
          //If we are not in editable stage
          if (
            presetNode.infrastructure_other &&
            presetNode.toolProvider_other &&
            presetNode.vendor_other
          ) {
            presetNode.infrastructure_other.forEach((infrastructure, index) => {
              editedInfrastructures[index] = {
                toolProviderObj: { ...othertoolProviderObj },
                vendorObj: { ...othervendorObj },
                infrastructureObj: { ...otherinfrastructureObj },
                toolProviderQuery: toolProviderQueryFinalOther,
                vendorQuery: vendorQuery,
              };
            });
            // console.log("editedInfrastructures");
            // console.log(editedInfrastructures);
            if (
              editedInfrastructures.length ===
              presetNode.infrastructure_other.length
            ) {
              setInfrastructures(editedInfrastructures);
            }
          }
        }
        getFileApi();
      } else {
        //When i am selecting input/output
        if (presetNode && presetNode.length !== 0) {
          if (inputObj.current && edit === false) {
            //If we are not in editable stage
            inputObj.current.value =
              presetNode.inputLocation || presetNode.outputLocation;
            if (presetNode.taskType === "651ec112a3df58ffe8f1ea5f") {
              //Whether it is input or output location
              dispatch({
                type: "SET_INPUT_LOC",
                value: presetNode.inputLocation,
              });
            } else if (presetNode.taskType == "651ec15ba3df58ffe8f1ea60") {
              dispatch({
                type: "SET_OUTPUT_LOC",
                value: presetNode.outputLocation,
              });
            }
          }

          let editedInfrastructures = [];
          if (
            edit === false &&
            presetNode.infrastructure_other &&
            presetNode.toolProvider_other &&
            presetNode.vendor_other
          ) {
            //If we are not in editable stage
            presetNode.infrastructure_other.forEach((infrastructure, index) => {
              editedInfrastructures[index] = {
                toolProviderObj: { ...othertoolProviderObj },
                vendorObj: { ...othertoolProviderObj },
                infrastructureObj: { ...othertoolProviderObj },
                toolProviderQuery: toolProviderQueryFinal,
                vendorQuery: vendorQuery,
              };
            });

            if (
              editedInfrastructures.length ===
              presetNode.infrastructure_other.length
            ) {
              setInfrastructures(editedInfrastructures);
            }
          } else {
            setEdit(true);
          }
        }
      }
    }
  }, [
    taskSubType,
    taskType,
    partic,
    conf_file,
    infrastructure,
    outputLocation,
    toolProvider,
    inputLocation,
    vendor,
    state,
  ]);

  const addConfiguration = async () => {
    if (!isAllDropDownValid) {
      return;
    }
    insert();
  };
  const temp = "toolProviderList";
  // define the JSON of data
  const [toolproviderData, setToolProviderData] = useState(data[temp]);
  // maps the appropriate column to fields property
  const fields = { text: "legalName", value: "_id" };
  const fields1 = { text: "legalName", value: "_id" };

  //set the value to header template
  const headerTemplate = () => {
    return (
      <div className="header1">
        <span>Photo</span> <span className="columnHeader1">Tool Info</span>
      </div>
    );
  };
  //set the value to item template
  const itemTemplate = (data) => {
    return (
      <div>
        <img className="image" src={data.name} alt={data.legalName} />
        <div className="name"> {data.legalName} </div>
        <div className="job"> {data.address} </div>
      </div>
    );
  };
  const valueTemplate = (data) => {
    return (
      <div className="valueTemplate">
        <img
          className="value"
          src={data.name}
          height="30px"
          width="30px"
          alt="image"
        />
        <div className="name"> {data.legalName} </div>
      </div>
    );
  };
  const subTypeObj = useRef(null);
  const tempType = "type";
  const infrastructure1 = "infrastructure";
  const participants = "participants";
  const vendors = "vendors";
  //define the country DropDownList data
  const [tempData, setTempData] = useState(data[tempType]);
  const [participantText, setParticpantText] = useState(null);
  const tempSubType = "state";
  const [infrastructuredata, setInfrastructureData] = useState(
    data[infrastructure1]
  );
  const [participantsData, setParticpantsData] = useState(data[participants]);
  const [locationData, setLocationData] = useState([
    {
      location: "California, United States",
      location_id: "5332921",
      ref_id: "adaea102-de54-452f-b6d2-befd20d9ef71",
    },
    {
      location: "Canada",
      location_id: "6251999",
      ref_id: "566892ec-7d04-466f-b63f-4323c0d743ae",
    },
    {
      location: "France",
      location_id: "3017382",
      ref_id: "28aadb12-b362-40f4-b068-c3818bbe4bf8",
    },
    {
      location: "India",
      location_id: "1269750",
      ref_id: "b207b69a-47bd-4fb5-8f9c-fd9e881932f2",
    },
    {
      location: "Mexico",
      location_id: "3996063",
      ref_id: "6da34315-a75a-4a48-8b19-c219194e44e2",
    },
    {
      location: "Poland",
      location_id: "798544",
      ref_id: "20339884-fabe-45b3-a451-5973abcc561e",
    },
  ]);
  const [vendorData, setVendorData] = useState(data[vendors]);
  //define the state DropDownList data
  const [subTypeData, setSubTypeData] = useState(data[tempSubType]);
  const participantObj = useRef(null);
  const infrastructureObj = useRef(null);
  const otherinfrastructureObj = useRef(null);
  // maps the state column to fields property
  const subTypeFields = { value: "_id", text: "subTypeName" };
  // maps the city column to fields property
  const allfields = { text: "name", value: "_id" };
  const fileFileds = {
    text: "metadataFileName.metadataFileName",
    value: "_id",
  };
  const [stateEnabled, setStateEnabled] = useState(false);
  const [stateQuery, setStateQuery] = useState(null);
  const [toolProviderQuery, setToolProviderQuery] = useState(null);
  const [vendorQuery, setVendorQuery] = useState(null);
  const [subTypeText, setSubTypeText] = useState(null);
  const vendorObj = useRef(null);
  const toolProviderObj = useRef(null);
  const othervendorObj = useRef(null);
  const othertoolProviderObj = useRef(null);
  const [inputEnabled, setInputEnabled] = useState(false);
  const [apiEnabled, setApiEnabled] = useState(true);
  const typeObj = useRef(null);
  const callHistory = () => {
    saveData(workflow, workflowId, configId, "", "", workflowData);
    onClose();
  };
  //reset all to blank or null
  const reset = () => {
    state.participants = [];
    state.taskSubType = "";
    state.toolProvider = "";
    state.inputLocation = "";
    state.outputLocation = "";
    state.infrastructure = "";
    state.conf_file = "";
    participantObj.current.value = null;
    infrastructureObj.current.value = null;
    toolProviderObj.current.value = null;
    vendorObj.current.value = null;
    fileObj.current.value = null;
    if (apiNameObj.current) {
      apiNameObj.current.value = null;
    }
    if (inputObj.current) {
      inputObj.current.value = "";
    }
    setFileData([]);
  };
  // On calling type change
  const typeChange = (args) => {
    if (edit) {
      reset();
      setStateEnabled(args.value != null);
      // query the data source based on type DropDownList selected value
      let tempQuery = new Query().where("typeId", "equal", args.value);
      setStateQuery(tempQuery);
      // clear the existing selection.
      setSubTypeText(null);
      // bind the property changes to state DropDownListus
      subTypeObj.current.dataBind();
    }

    dispatch({ type: "SET_TYPE", value: args.itemData._id });

    //Enable input output
    if (
      args.value === "651ec112a3df58ffe8f1ea5f" ||
      args.value === "651ec15ba3df58ffe8f1ea60"
    ) {
      setInputEnabled(true);

      setApiEnabled(false);
    } else {
      setInputEnabled(false);

      setApiEnabled(true);
    }
  };
  //function to be called on change vendor
  const changeVendor = (args) => {
    dispatch({
      type: "SET_VENDOR",
      value: args.itemData?._id,
      name: args.itemData?.legalName,
    });
    if (edit) {
      let tempQuery = new Query()
        .where("vendorId", "contains", args.value)
        .where("subTypeId", "contains", subTypeObj.current.value);
      setToolProviderQueryFinal(tempQuery);
      toolProviderObj.current.dataBind();
    }
  };
  //function to be called on change tool provider
  const changeToolProvider = (args) => {
    dispatch({
      type: "SET_TOOLPROVIDER",
      value: args.itemData?._id,
      name: args.itemData?.legalName,
    });
    if (edit) {
      let tempQuery = new Query()
        .where("toolprovider", "contains", args.value)
        .where("subTypeId", "contains", subTypeObj.current.value);
      setInfrastructureQuery(tempQuery);
      infrastructureObj.current.dataBind();
    }
  };
  //function to be called on change infrasructure
  const changeInfra = (e) => {
    dispatch({
      type: "SET_INFRASTRUCTURE",
      value: e.value,
      name: e.itemData?.name,
    });

    if (edit) {
      const selectedInfrastructure = infrastructuredata.find((element) => {
        return element["_id"] === e.value;
      });

      if (selectedInfrastructure) {
        let tempQuery = new Query()
          .where("infrastructure", "contains", e.value)
          .where("subTypeId", "contains", subTypeObj.current.value);
        let vendorQuery = new Query()
          .where("infrastructure", "contains", e.value)
          .where("subTypeId", "contains", subTypeObj.current.value);
        setToolProviderQueryFinal(tempQuery);
        setVendorQuery(vendorQuery);
        toolProviderObj.current.dataBind();
      }
    }
  };
  //function to be called on changing other  infra vendor
  const changeInfraVendor = (args, index) => {
    dispatch({
      type: "SET_VENDOR_INFRA",
      value: args.itemData?._id,
      name: args.itemData?.legalName,
    });
    if (edit) {
      let tempQuery = new Query().where("vendorId", "contains", args.value);
      if (toolProvider?.queries) {
        tempQuery.queries.push(toolProviderQuery?.queries[0]);
      }
      infrastructures[index].toolProviderQuery = tempQuery;
    }
  };
  //function to be called on changing other  infra tool provider
  const changeInfraToolProvider = (args, index) => {
    console.log(args.itemData?.legalName);
    dispatch({
      type: "SET_TOOLPROVIDER_INFRA",
      value: args.itemData?._id,
      name: args.itemData?.legalName,
    });
    //If we edit is enabled then filter the infrastructure dropdown options on change
    if (edit) {
      let tempQuery = new Query()
        .where("toolprovider", "contains", args.value)
        .where("subTypeId", "contains", subTypeObj.current.value);
      infrastructures[index].infrastructureQuery = tempQuery;
    }
  };
  //function to be called on changing other  infrastructure
  const changeOtherInfrastructure = (e, index) => {
    dispatch({
      type: "SET_OTHER_INFRASTRUCTURE",
      value: e.value,
      name: e.itemData?.name,
    });
    if (edit) {
      const selectedInfrastructure = infrastructuredata.find((element) => {
        return element["_id"] === e.value;
      });
      if (selectedInfrastructure) {
        let tempQuery = new Query()
          .where("infrastructure", "contains", e.value)
          .where("subTypeId", "contains", subTypeObj.current.value);
        let vendorQuery = new Query()
          .where("infrastructure", "contains", e.value)
          .where("subTypeId", "contains", subTypeObj.current.value);
        infrastructures[index].toolProviderQuery = tempQuery;
        infrastructures[index].vendorQuery = vendorQuery;
      }
    }
  };

  // Function to get api metadata file
  const getFileApi = async () => {
    await dispatch1(
      apiData1({
        task: state.taskType,
        subtask: state.taskSubType,
        toolProvider: state.toolProvider,
        vendor: state.vendor,
      })
    );
  };
  const [infrastructures, setInfrastructures] = useState([]);
  useEffect(() => {
    if (presetNode) {
      if (
        edit === false &&
        infrastructures?.length === presetNode?.infrastructure_other.length
      ) {
        if (state.taskType !== "651ec184a3df58ffe8f1ea61") {
          if (
            presetNode?.infrastructure_other &&
            presetNode?.toolProvider_other &&
            presetNode?.vendor_other
          ) {
            infrastructures.forEach((infrastructure, index) => {
              console.log(index);
              infrastructure.toolProviderObj.current.value =
                presetNode.toolProvider_other[index];
              infrastructure.vendorObj.current.value =
                presetNode.vendor_other[index];
              infrastructure.infrastructureObj.current.value =
                presetNode.infrastructure_other[index];
            });
            setEdit(true);
          }
        } else {
          if (
            presetNode?.infrastructure_other &&
            presetNode?.toolProvider_other &&
            presetNode?.vendor_other
          ) {
            infrastructures.forEach((infrastructure, index) => {
              infrastructure["toolProviderObj"]["current"]["value"] =
                presetNode.toolProvider_other[index];
              infrastructure.vendorObj.current.value =
                presetNode.vendor_other[index];
              infrastructure.infrastructureObj.current.value =
                presetNode.infrastructure_other[index];
            });
          }
        }
      }
    }
  }, [infrastructures]);
  // Called in case of adding more than one infrastructure
  const addInfrastructure = () => {
    setInfrastructures([
      ...infrastructures,
      {
        toolProviderObj: othertoolProviderObj,
        vendorObj: othervendorObj,
        infrastructureObj: otherinfrastructureObj,
        toolProviderQuery: toolProviderQueryFinalOther,
        vendorQuery: vendorQuery,
        infrastructureQuery: infrastructureQuery,
      },
    ]);
  };
  // Called in case of removing more than one infrastructure
  const removeInfrastructure = (index) => {
    const newInfrastructures = [...infrastructures];
    newInfrastructures.splice(index, 1);
    state.infrastructureName_other.splice(index, 1);
    state.infrastructure_other.splice(index, 1);
    state.toolProviderName_other.splice(index, 1);
    state.toolProvider_other.splice(index, 1);
    state.vendorName_other.splice(index, 1);
    state.vendor_other.splice(index, 1);
    setInfrastructures(newInfrastructures);
  };
  // Called in case of sub type change
  const subTypeChange = (args) => {
    if (edit) {
      reset();
    }
    // bind the property changes to state DropDownList
    let tempQuery = new Query().where("subTypeId", "contains", args.value);
    setVendorQuery(tempQuery);
    setToolProviderQuery(tempQuery);
    if (edit) {
      toolProviderObj.current.dataBind();
      vendorObj.current.dataBind();
    }
    // query the data source based on country DropDownList selected value

    dispatch({
      type: "SET_SUBTYPE",
      value: args.itemData?._id,
      name: args.itemData?.subTypeName,
    });
  };

  const setInput = (args) => {
    if (state.taskType === "651ec112a3df58ffe8f1ea5f") {
      dispatch({ type: "SET_INPUT_LOC", value: args.value });
    } else if (state.taskType == "651ec15ba3df58ffe8f1ea60") {
      dispatch({ type: "SET_OUTPUT_LOC", value: args.value });
    }
  };

  const configureButtonStyle = isAllDropDownValid
    ? { padding: "5px", margin: "5px" }
    : { padding: "5px", opacity: 0.75, cursor: "not-allowed", margin: "5px" };
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="container2">
          <div className="HeaderConfigFrame">
            <h1 className="HeaderConfigTemplate">Add "{task}" Configuration</h1>
            <span className="mandatory_label">
              {" "}
              '* - Denotes a mandatory configuration required'{" "}
            </span>
          </div>

          <div className="outer-frame1">
            <div className="outer-frame2">
              <div className="modules-container">
                <div className="modules">
                  <h3>Service Type</h3>
                  <div className="module-types">
                    <div className="frames">
                      <div className="frame-label">
                        <h4>Type </h4>
                        <text className="mandatory">*</text>
                      </div>
                      <div className="label-input">
                        <DropDownListComponent
                          id="type"
                          dataSource={tempData}
                          ref={typeObj}
                          fields={allfields}
                          popupHeight="auto"
                          change={typeChange.bind(this)}
                          placeholder="Select a type"
                        />
                      </div>
                    </div>

                    <div className="frames">
                      <div className="frame-label">
                        <h4>Sub Type </h4>
                        <text className="mandatory">*</text>
                      </div>
                      <div>
                        <DropDownListComponent
                          class="label-input"
                          id="sub_type"
                          dataSource={subTypeData}
                          ref={subTypeObj}
                          fields={subTypeFields}
                          popupHeight="160"
                          change={subTypeChange.bind(this)}
                          enabled={stateEnabled}
                          placeholder="Select a subtype"
                          query={stateQuery}
                          text={subTypeText}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modules">
                  <h3>Product Configuration</h3>
                  <div className="module-types">
                    <div className="frames">
                      <div className="frame-label">
                        <h4>Vendor </h4>
                        <text className="mandatory">*</text>
                      </div>
                      <div>
                        <DropDownListComponent
                          id="employees"
                          dataSource={vendorData}
                          ref={vendorObj}
                          headerTemplate={headerTemplate}
                          itemTemplate={itemTemplate}
                          valueTemplate={valueTemplate}
                          fields={fields1}
                          popupHeight="160"
                          placeholder="Select a vendor"
                          query={vendorQuery}
                          change={changeVendor}
                          cssClass="fixed-input-width"
                        />
                      </div>
                    </div>
                    <div className="frames">
                      <div className="frame-label">
                        <h4>Tool Name </h4>
                        <text className="mandatory">*</text>
                      </div>
                      <div>
                        <DropDownListComponent
                          id="toolProvider"
                          dataSource={toolproviderData}
                          fields={fields}
                          ref={toolProviderObj}
                          placeholder="Select a tool"
                          itemTemplate={itemTemplate}
                          valueTemplate={valueTemplate}
                          headerTemplate={headerTemplate}
                          popupHeight="160"
                          query={toolProviderQueryFinal}
                          change={changeToolProvider}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="module-types">
                    <div className="frames">
                      <div className="frame-label">
                        <h4>Participants </h4>
                        <text className="mandatory">*</text>
                      </div>
                      <div>
                        <MultiSelectComponent
                          id="participants"
                          dataSource={participantsData}
                          ref={participantObj}
                          fields={allfields}
                          change={(e) => {
                            dispatch({
                              type: "SET_PARTICIPANTS",
                              value: e.value,
                              name: e.itemData?.name,
                            });
                          }}
                          popupHeight="160"
                          placeholder="Select participants"
                          text={participantText}
                        />
                      </div>
                    </div>

                    <div className="frames">
                      <div className="frame-label">
                        <h4>Infrastructure </h4>
                        <text className="mandatory">*</text>
                      </div>
                      <div>
                        <DropDownListComponent
                          id="infrastructure"
                          dataSource={infrastructuredata}
                          ref={infrastructureObj}
                          fields={allfields}
                          query={infrastructureQuery}
                          change={(e) => {
                            changeInfra(e);
                          }}
                          popupHeight="auto"
                          placeholder="Select an Infrastructure"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Add-more-infra">
                  <button type="button" onClick={addInfrastructure}>
                    Add More Infrastructure
                  </button>
                </div>
                {infrastructures.map((infrastructure, index) => (
                  <div className="modules" key={index}>
                    <h3>Other Infrastructure Configuration</h3>
                    <div className="module-types">
                      <div className="frames">
                        <div className="frame-label">
                          <h4>Vendor </h4>
                        </div>
                        <div>
                          <DropDownListComponent
                            id={"employees"}
                            dataSource={vendorData}
                            ref={infrastructure.vendorObj}
                            itemTemplate={itemTemplate}
                            valueTemplate={valueTemplate}
                            fields={fields1}
                            popupHeight="160"
                            placeholder="Select a vendor"
                            query={infrastructure.vendorQuery}
                            change={(e) => changeInfraVendor(e, index)}
                          />
                        </div>
                      </div>
                      <div className="frames">
                        <div className="frame-label">
                          <h4>Tool Name </h4>
                        </div>
                        <div>
                          <DropDownListComponent
                            id="employees"
                            dataSource={toolproviderData}
                            fields={fields}
                            ref={infrastructure.toolProviderObj}
                            placeholder="Select a tool"
                            itemTemplate={itemTemplate}
                            valueTemplate={valueTemplate}
                            headerTemplate={headerTemplate}
                            popupHeight="160"
                            query={infrastructure.toolProviderQuery}
                            change={(e) => changeInfraToolProvider(e, index)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="module-types">
                      <div className="frames">
                        <div className="frame-label">
                          <h4>Infrastructure </h4>
                        </div>
                        <div>
                          <DropDownListComponent
                            id="infrastructure"
                            dataSource={infrastructuredata}
                            ref={infrastructure.infrastructureObj}
                            fields={allfields}
                            change={(e) => changeOtherInfrastructure(e, index)}
                            popupHeight="auto"
                            placeholder="Select an Infrastructure"
                            query={infrastructureQuery}
                          />
                        </div>
                      </div>
                      <div className="frames-button">
                        <button
                          type="button"
                          onClick={(e) => {
                            removeInfrastructure(index);
                          }}
                          className="removeButton"
                        >
                          Remove Infrastructure
                        </button>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="modules">
                  <h3>Api / Source File</h3>
                  <div className="module-types">
                    <div className="frames">
                      {inputEnabled === true ? (
                        <>
                          <div className="frame-label">
                            <h4 className="api-file-text">
                              Enter Source/Destination Folder{" "}
                            </h4>
                            <text className="mandatory">*</text>
                            {/* why <super></super> used? */}
                          </div>
                          <TextBoxComponent
                            ref={inputObj}
                            placeholder="Enter Input/Output Location"
                            cssClass="e-outline"
                            floath4Type="Auto"
                            onChange={setInput.bind(this)}
                            enabled={inputEnabled}
                          />
                        </>
                      ) : (
                        <>
                          <div className="frame-label">
                            <h4 className="api-file-text">Api Name</h4>
                            <text className="mandatory">*</text>
                          </div>
                          <TextBoxComponent
                            ref={apiNameObj}
                            placeholder="Selected Api"
                            cssClass="e-outline"
                            floath4Type="Auto"
                            enabled={false}
                          />
                        </>
                      )}
                    </div>

                    <div className="frames">
                      <h4 className="api-file-text">
                        API file <super className="mandatory">*</super>
                      </h4>
                      <DropDownListComponent
                        id="file"
                        ref={fileObj}
                        dataSource={filedata}
                        fields={fileFileds}
                        popupHeight="auto"
                        placeholder="Select an Api file"
                        change={(e) => changeFile(e)}
                        enabled={apiEnabled}
                      />
                    </div>
                  </div>
                </div>
                <div className="carbon-modules">
                  <h3>Carbon Score</h3>
                  <div className="carbon-frames">
                    <div className="carbon-frames-input">
                      <div className="carbon-frames-label">
                        <h4>MASTER RESOLUTION </h4>
                        <text></text>
                      </div>
                      <div>
                        <DropDownListComponent
                          className="carbon-input"
                          dataSource={resolutionOptions}
                          placeholder="Select resolution"
                          ref={masterResolutionObj}
                          change={(e) => {
                            dispatch({
                              type: "SET_MASTER_RESOLUTION",
                              value: e.value,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="carbon-frames-input">
                      <div className="carbon-frames-label">
                        <h4>TASK LOCATION </h4>
                        <text></text>
                      </div>
                      <div>
                        <DropDownListComponent
                          className="carbon-input"
                          fields={countryCodeOptions}
                          ref={taskLocationObj}
                          change={handleCountryCodeChange}
                          dataSource={locationData}
                          popupHeight="150"
                          placeholder="Country Code"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="carbon-frame2">
                    <div className="carbon-switch-types">
                      <div className="carbon-switch-frame">
                        <div className="carbon-switch">
                          <Switch
                            id="stereoscopicSwitch"
                            checked={isStereoscopic}
                            onChange={handleStereoscopicToggle}
                          />
                        </div>
                      </div>
                      <h4>STEREOSCOPIC</h4>
                    </div>
                    <div className="carbon-switch-types">
                      <div className="carbon-switch-frame">
                        <div className="carbon-switch">
                          <Switch
                            id="hdrSwitch"
                            checked={isHdr}
                            onChange={handleHdrToggle}
                          />
                        </div>
                      </div>
                      <h4>HDR</h4>
                    </div>
                  </div>
                  <div className="carbon-quality-frames">
                    <div className="quality-label">
                      <h4>QUANTITY </h4>
                      <text></text>
                    </div>

                    <TextBoxComponent
                      className="quality-input"
                      ref={quantity}
                      placeholder="Enter Quantity"
                      floath4Type="auto"
                      onChange={(e) => {
                        dispatch({
                          type: "SET_QUANTITY",
                          value: e.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="back-save-container">
                  <button onClick={() => callHistory()} className="back-button">
                    Back{" "}
                  </button>
                  <button
                    className="save-button"
                    style={configureButtonStyle}
                    onClick={() => addConfiguration()}
                    title={
                      isAllDropDownValid
                        ? ""
                        : "Please fill all the dropdowns to proceed"
                    }
                    disabled={!isAllDropDownValid}
                  >
                    <div className="save-button-container">
                      <text>Save Changes </text>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Cascading;
