import React from "react";
function Button(props) {
  const { onClick,  onSchedule,restProps } = props;
 // console.log(restProps);
  return (
    <>
      {restProps.data.status === "Configuration Completed" ? (
        <span className="frameButton">
          <button className=" btnExecute" onClick={() => onClick(restProps)}>
            Execute
          </button>
          <button className="btnExecute" onClick={() => onSchedule(restProps)}>
            Schedule
          </button>
        </span>
      ) : (
        ""
      )}

    </>
  );
}
export default Button;

