import "./index.css";
import React, { useState, useEffect } from "react";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";

const SearchBar = ({
  searchWorkflowId,
  searchWorkflowName,
  selectedStatus,
  handleSearchWorkflowIdChange,
  handleSearchWorkflowNameChange,
  handleStatusChange,
  handleFilterClick,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleFilterButtonClick = () => {
    handleFilterClick(startDate, endDate);
  };

  useEffect(() => {
    if (startDate && endDate) {
      handleFilterButtonClick();
    }
  }, [startDate, endDate]);

  return (
    <div className="SearchBar">
      <div className="workflow-id">
        <div className="workflow-id-frame">
          <div className="workflow-id-frame-label">WORKFLOW ID</div>
          <text className="workflow-id-frame-sublabel">*</text>
        </div>

        <input
          className="workflow-id-input"
          type="text"
          placeholder="Enter Workflow ID"
          value={searchWorkflowId}
          onChange={handleSearchWorkflowIdChange}
        />
      </div>

      <div className="workflow-name">
        <div className="workflow-name-frame">
          <div className="workflow-name-frame-label">WORKFLOW NAME</div>
          <text className="workflow-name-frame-sublabel">*</text>
        </div>
        <input
          className="workflow-name-input"
          type="text"
          placeholder="Enter Workflow Name"
          value={searchWorkflowName}
          onChange={handleSearchWorkflowNameChange}
        />
      </div>

      <div className="workflow-id">
        <div className="workflow-id-frame">
          <div className="workflow-id-frame-label">STATUS</div>
          <text className="workflow-id-frame-sublabel">*</text>
        </div>

        <select
          className="workflow-id-input"
          value={selectedStatus}
          onChange={handleStatusChange}
        >
          <option value="All">All</option>
          <option value="Execution In Progress">Execution In Progress</option>
          <option value="Execution Completed">Execution Completed</option>
          <option value="Pending">Pending</option>
          <option value="Execution Terminated">Execution Terminated</option>
        </select>
      </div>

      {/* <div className="workflow-date">
        <div className="workflow-date-label">DATE RANGE</div>

        <DateRangePickerComponent
          startDate={startDate}
          endDate={endDate}
          placeholder="Select Date Range"
          change={(args) => {
            setStartDate(args.startDate);
            setEndDate(args.endDate);
          }}
        />
      </div> */}
    </div>
  );
};

export default SearchBar;
