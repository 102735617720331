import React from "react";
import "./index.css";
import UserProfile from "../../../images/Logos/UserProfile.png";

function AdminUser() {
  return (
    <div className="profile-diagram-container">
      <div className="profile-diagram-container-frame">
        <div className="profile-diagram-container-total">
          <img src={UserProfile} alt="Aavtar" />
          <div className="profile-diagram-change-Aavtar">
            <div className="profile-diagram-change-Aavtar-frame">
              <text>Change your avatar</text>
            </div>
          </div>
          <div className="profile-diagram-about-container">
            <div className="profile-diagram-about-name">
              <div className="profile-diagram-about-name-label">
                <p4>Name</p4>
                <p5>*</p5>
              </div>
              <div className="profile-diagram-about-name-input">
                <text>Steve jobs</text> {/*temporary, pass actual name here */}
              </div>
            </div>
            <div className="profile-diagram-about-email">
              <div className="profile-diagram-about-email-label">
                <p4>Email Address</p4>
                <p5></p5>
              </div>
              <div className="profile-diagram-about-email-input">
                <text>steve@apple.com</text>{" "}
                {/*temporary, pass actual email here */}
              </div>
            </div>
            <div className="profile-diagram-about-title">
              <div className="profile-diagram-about-title-label">
                <p4>Title</p4>
                <p5></p5>
              </div>
              <div className="profile-diagram-about-title-input">
                <text>Workflow Owners</text>{" "}
                {/*temporary, pass actual title here */}
              </div>
            </div>
            <div className="profile-diagram-about-org">
              <div className="profile-diagram-about-org-label">
                <p4>Organisation</p4>
                <p5></p5>
              </div>
              <div className="profile-diagram-about-org-input">
                <text>Entertainment Technologist Inc.</text>{" "}
                {/*temporary, pass actual location here */}
              </div>
            </div>
            <div className="profile-diagram-about-location">
              <div className="profile-diagram-about-location-label">
                <p4>Location</p4>
                <p5></p5>
              </div>
              <div className="profile-diagram-about-location-input">
                <text>California</text>{" "}
                {/*temporary, pass actual location here */}
              </div>
            </div>
            <button className="profile-diagram-about-save">
              <div className="profile-diagram-about-save-label">
                <text>Save</text>
              </div>
            </button>
            <div className="profile-diagram-about-rectangle" />
            <div className="profile-diagram-want-delete-ac">
              Want to delete your account?
            </div>
            <div className="profile-diagram-remove-me">
              Remove me from MediaMesh
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminUser;
