import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const apiBaseUrl = process.env.REACT_APP_API_URL;

console.log(process.env.REACT_APP_API_URL);

const initialState = {
  loading: false,
  data: "",
  error: null,
  createclient: {
    loading: false,
    data: "",
    error: null,
    clientMessage: null,
  },
  clientData: {
    loading: false,
    data: [],
    error: null,
    clientMessage: null,
  },
};

export const createNewClient = createAsyncThunk(
  "createNewClient",
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;
    const response = await fetch(`${apiBaseUrl}/accountsettings/uploadClient`, {
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
      method: "post",
      body: JSON.stringify(data),
    }).catch((error) => {
      return thunkAPI.rejectWithValue("No Resonse from Server!");
    });

    try {
      if (response.status) {
        if (response.status === 200) {
          const response1 = await response.json();
          return response1;
        } else {
          const response1 = await response.json();
          return thunkAPI.rejectWithValue(response1);
        }
      } else {
        return thunkAPI.rejectWithValue("No Resonse from Server!");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: "Failed to create new client!",
        message: error.message, //optional
      });
    }
  }
);

export const getclients = createAsyncThunk(
  " clientData",
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;
    console.log("called");
    const clientData = await fetch(`${apiBaseUrl}/accountsettings/getClients`, {
      method: "get",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }).catch((error) => {
      return thunkAPI.rejectWithValue("No response from server!");
    });
    try {
      if (clientData.status === 200) {
        const adminClients = await clientData.json();
        return adminClients;
      } else {
        throw new Error("Failed to fetch clients");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const clearAllClientData = () => async (dispatch) => {
  dispatch({ type: "CLEAR_All_CLIENT_DATA" });
};
export const saveCreatedClient = createAsyncThunk();

const authSlice = createSlice({
  name: "client",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createNewClient.pending, (state) => {
        state.createclient.loading = true;
        state.createclient.data = "";
        state.createclient.error = null;
      })
      .addCase(createNewClient.fulfilled, (state, action) => {
        state.createclient.loading = false;
        state.createclient.data = action?.payload;
        state.createclient.error = null;
        state.clientMessage = action.payload.message;
      })
      .addCase(createNewClient.rejected, (state, action) => {
        state.createclient.loading = false;
        state.createclient.data = "";
        state.createclient.error = action?.payload;
        state.clientMessage = action.payload.message;
      })
      .addCase(getclients.pending, (state) => {
        state.clientData.loading = true;
        state.clientData.data = [];
        state.clientData.error = null;
      })
      .addCase(getclients.fulfilled, (state, action) => {
        state.clientData.loading = false;
        state.clientData.data = action?.payload;
        state.clientData.error = null;
        state.clientData.clientMessage = action.payload.message;
      })
      .addCase(getclients.rejected, (state, action) => {
        state.clientData.loading = false;
        state.clientData.data = [];
        state.clientData.error = action?.payload;
        state.clientData.clientMessage = action.payload.message;
      })
      .addCase("CLEAR_All", (state, action) => {
        state.loading = "";
        state.error = null;
        state.data = "";
        state.createclient.loading = false;
        state.createclient.error = null;
        state.createclient.data = "";
        state.createclient.clientMessage = null;
        state.clientData.loading = false;
        state.clientData.error = null;
        state.clientData.data = [];
        state.clientData.clientMessage = null;
      })
      .addCase("CLEAR_All_CLIENT_DATA", (state, action) => {
        state.clientData.data = "";
        state.clientData.error = null;
        state.clientData.loading = false;
        state.clientData.clientMessage = null;
      });
  },
});

export default authSlice.reducer;
