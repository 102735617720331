import React from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  Link,
  useLocation,
} from "react-router-dom";
import SettingsUser from "../../images/Logos/setting-user.png";
import AdminGroup from "../../images/Logos/adminGroup.png";
import ProjectConfig from "../../images/Logos/supervised_user_circle.png";
import "./index.css";
import AccountSettingsProjects from "./AdminProject";
import AccountSettingsClient from "./AdminClient";
import AccountSettingsUserProfile from "./AdminProfile";
import AccountSettingsAdminUsers from "./AdminAdminUser";
import AccountSettingsUser from "./AdminUser";
import { useSelector } from "react-redux";
import AccountSettingsProjectConfig from "./ProjectConfig"

const MenuPanel = () => {
  const { path } = useRouteMatch();
  const location = useLocation();

  const isActive = (pathName) => {
    return location.pathname === pathName ? "active" : "";
  };
  const { userRole } = useSelector((state) => state.auth);
  return (
    <div className="account-setting-browser-container">
      <div className="settingConatiner">
        <div className="setting-content-container">
          <div className="setting-menu-container">
            <div className="account-section">
              <div className="account-section-header">
                <div className="account-section-hdr-title">
                  <img src={SettingsUser} alt="Account Settings logo" />
                  <p>Account Settings</p>
                </div>
              </div>
              <div className="account-section-options">
                <div
                  className={`setting-user-detail ${isActive(`${path}/profile`)}`}
                >
                  <Link
                    to={`${path}/profile`}
                    className={isActive(`${path}/profile`)}
                  >
                    <span>User Details</span>
                  </Link>
                </div>
                <div className="setting-user-detail">
                  <span>Notification</span>
                </div>
                <div className="setting-user-detail">
                  <span>Invoices</span>
                </div>
              </div>
            </div>
            { (userRole === `Super Admin`) && <div className="admin-section">
              <div className="admin-section-header">
                <div className="admin-section-hdr-title">
                  <img src={AdminGroup} alt="Admin Settings logo" />
                  <p>Admin Settings</p>
                </div>
              </div>
              <div className="admin-section-options">
                <div
                  className={`setting-user-detail ${isActive(`${path}/client`)}`}
                >
                  <Link
                    to={`${path}/client`}
                    className={isActive(`${path}/client`)}
                  >
                    <span>Clients</span>
                  </Link>
                </div>
                <div
                  className={`setting-user-detail ${isActive(`${path}/projects`)}`}
                >
                  <Link
                    to={`${path}/projects`}
                    className={isActive(`${path}/projects`)}
                  >
                    <span>Projects</span>
                  </Link>
                </div>
                <div
                  className={`setting-user-detail ${isActive(`${path}/admin-user`)}`}
                >
                  <Link
                    to={`${path}/admin-user`}
                    className={isActive(`${path}/admin-user`)}
                  >
                    <span>Admin Users</span>
                  </Link>
                </div>
              </div>
            </div>}
            { (userRole === `Super Admin` || userRole === `Team Lead` ) && <div className="project-section">
              <div className="project-section-header">
                <div className="project-section-hdr-title">
                  <img src={ProjectConfig} alt="Project Settings logo" />
                  <p>Project Settings</p>
                </div>
              </div>
              <div className="project-section-options">
                <div
                  className={`setting-user-detail ${isActive(`${path}/projectConfig`)}`}
                >
                  <Link
                    to={`${path}/projectConfig`}
                    className={isActive(`${path}/projectConfig`)}
                  >
                    <span>Project Config</span>
                  </Link>
                </div>
                <div
                  className={`setting-user-detail ${isActive(`${path}/users`)}`}
                >
                  <Link
                    to={`${path}/users`}
                    className={isActive(`${path}/users`)}
                  >
                    <span>Users</span>
                  </Link>
                </div>
              </div>
            </div>}
          </div>
          <div className="setting-client-diagram-container">
            <Switch>
              <Route path={`${path}/users`}>
                <AccountSettingsUser />
              </Route>
              <Route path={`${path}/projectConfig`}>
                <AccountSettingsProjectConfig />
              </Route>
              <Route path={`${path}/admin-user`}>
                <AccountSettingsAdminUsers />
              </Route>
              <Route path={`${path}/projects`}>
                <AccountSettingsProjects />
              </Route>
              <Route path={`${path}/client`}>
                <AccountSettingsClient />
              </Route>

              <Route path={`${path}/`}>
                <AccountSettingsUserProfile />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuPanel;
