import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import env from 'react-dotenv';
const apiUrl=process.env.REACT_APP_API_URL;

const initialState = {
  api: { loading: true, apiFileData: "", error: null },

  filecontent: {
    apiFileDataContent: "",
    error:null,
    loading: true,
  },
  saveFileContent:{
    data:"",
    error:"",
    loading:"",
    msg:""
  }
};
export const apiFileData = createAsyncThunk(
  "apiFile",
  async (data, thunkAPI) => {
    const {token} = thunkAPI.getState().auth;
    const response = await fetch(`${apiUrl}/api/getFileApi`, {
      
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`
      },
      method: "post",
      body: JSON.stringify(data),
    }).catch((error) => {
      return thunkAPI.rejectWithValue("No Resonse from API!");
    });

    try {
      if (response.status === 200) {
        const response1 = await response.json();
        return response1;
      }
      return thunkAPI.rejectWithValue("No data");
    } catch (error) {
      console.log("No Response");
      return thunkAPI.rejectWithValue("No Resonse from API!");
    }
  }
);
export const apiData = createAsyncThunk("api", async (data, thunkAPI) => {
  const {token} = thunkAPI.getState().auth;
  const response = await fetch(`${apiUrl}/api/getApiData`, {
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`
    },
    method: "post",
    body: JSON.stringify(data),
  }).catch((error) => {
    return thunkAPI.rejectWithValue("No Resonse from API!");
  });

  try {
    if (response.status === 200) {
      const response1 = await response.json();
      return response1;
    }
    return thunkAPI.rejectWithValue("No data");
  } catch (error) {
    return thunkAPI.rejectWithValue("No Resonse from API!");
  }
});
export const apiFileDataContent = createAsyncThunk(
  "apiFileContent",
  async (data, thunkAPI) => {
    const {token} = thunkAPI.getState().auth;
    const response = await fetch(
      `${apiUrl}/api/getFileApiContent`,
      {
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`
        },
        method: "post",
        body: JSON.stringify(data),
      }
     
    ).catch((error) => {
      return thunkAPI.rejectWithValue("No Resonse from API!");
    });

    try {
      if (response.status === 200) {
        const response1 = await response.json();
        
        return response1;
      }
      return thunkAPI.rejectWithValue("No data");
    } catch (error) {
      console.log("No Response");
      return thunkAPI.rejectWithValue("No Resonse from API!");
    }
  }
);
export const saveFileDataContent = createAsyncThunk(
  "saveFileContent",
  async (data, thunkAPI) => {
    const {token} = thunkAPI.getState().auth;
    const response = await fetch(
      `${apiUrl}/api/saveFileApiContent`,
      {
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`
        },
       
        method: "post",
        body: JSON.stringify(data),
      }
     
    ).catch((error) => {
      return thunkAPI.rejectWithValue("No Resonse from API!");
    });

    try {
      if (response.status === 200) {
        const response1 = await response.json();
        return response1;
      }
      else{
        const response1 = await response.json();
        return thunkAPI.rejectWithValue(response1);
      }
      
    } catch (error) {
      
      return thunkAPI.rejectWithValue("No Resonse from API!");
    }
  }
);
export const clearAll = () => (dispatch) => {
  dispatch({ type: "CLEARDATA" });
}
const apiDataSlice = createSlice({
  name: "apiFile",
  initialState,
 
  extraReducers: (builder) => {
    builder
      .addCase(apiFileData.pending, (state) => {
        state.api.loading = true;
        state.api.apiFileData = "";
        state.api.error = null;
      })
      .addCase(apiFileData.fulfilled, (state, action) => {
        state.api.loading = false;
        state.api.apiFileData = action?.payload;
        state.api.error = null;
      })
      .addCase(apiFileData.rejected, (state, action) => {
        state.api.loading = false;
        state.api.apiFileData = "";
        state.api.error = action?.payload;
      })
      .addCase(apiFileDataContent.fulfilled, (state, action) => {
       
        state.filecontent.loading = false;
        state.filecontent.apiFileDataContent = action?.payload?.data;
        state.filecontent.error = null;
      })
      .addCase(apiFileDataContent.pending, (state, action) => {
        state.filecontent.loading = true;
        state.filecontent.apiFileDataContent = "";
        state.filecontent.error = action?.payload;
      })
      .addCase(apiFileDataContent.rejected, (state, action) => {
        state.filecontent.loading = false;
        state.filecontent.apiFileDataContent = "";
        state.filecontent.error = action?.payload.data.msg;
      })
      .addCase(saveFileDataContent.fulfilled, (state, action) => {
       
        state.saveFileContent.loading = false;
        state.saveFileContent.data = action?.payload?.data;
        state.saveFileContent.error = null;
        state.saveFileContent.msg = action?.payload?.msg;
      })
      .addCase(saveFileDataContent.pending, (state, action) => {
        state.saveFileContent.loading = true;
        state.saveFileContent.data = "";
        state.saveFileContent.error = action?.payload;
      })
      .addCase(saveFileDataContent.rejected, (state, action) => {
        state.saveFileContent.loading = false;
        state.saveFileContent.data = "";
        state.saveFileContent.error = action?.payload;
      })
      .addCase("CLEARDATA", (state, action) => {
        state.saveFileContent.loading = null;
        state.saveFileContent.data = "";
        state.saveFileContent.error = null;
        state.filecontent.loading=null;
        state.filecontent.data="";
        state.filecontent.error=null;
        state.api.loading = null;
        state.api.apiFileData = "";
        state.api.error = null;
      });
  },
});

export default apiDataSlice.reducer;
export const { clearSavedContent } = apiDataSlice.actions;