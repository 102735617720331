import React from "react";
import { Route, Redirect } from "react-router-dom";

const Protected = ({ user,  privileges,children,navigateTo,...rest }) => {

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? ( ((  privileges).includes("All")||(  privileges).includes(navigateTo)|| (navigateTo ==="Dashboard"))?
          children: <Redirect 
          to={{
            pathname: "/dashboard",
            state: { from: location },
          }}
        />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default Protected;
