import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const apiBaseUrl = process.env?.REACT_APP_API_URL;

const initialState = {
  loading: false,
  data: null, // Changed to null for better initialization
  error: null,
};

export const preview = createAsyncThunk(
  "preview",
  async (execId, thunkAPI) => {
    const {token} = thunkAPI.getState().auth;
    try {
      const response = await fetch(
        `${apiBaseUrl}/api/preview/${execId}`,
        {
          mode: "cors",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`
          },
          method: "GET",
          
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      return await response.json();
    } catch (error) {
        console.error(error);
      throw new Error("Failed to fetch data");
    }
  }
);

const previewSlice = createSlice({
  name: "preview",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(preview.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(preview.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
        state.error = null;
      })
      .addCase(preview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearErrors } = previewSlice.actions;

export default previewSlice.reducer;
