import "./index.css";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import NewWorkflow from "../../images/dashboard/design-new.png";
import Settings from "../../images/dashboard/settings.png";
import RunWorkflow from "../../images/dashboard/run-workflows.png";
import Monitor from "../../images/dashboard/monitor.png";
import RecentWorkflows from "../DomainStatus";
import WorkAllocation from "../WorkAllocation";

const Dashboard = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const isLoggedIn = user !== null;
  const userName = isLoggedIn ? user.name : "";

  return (
    <div className="dashboard-container">
      <div className="dashboard-frame">
        <div className="welcome-container">
          <text className="capitalize-first-letter"> Hi, {userName}</text>
        </div>
        <div className="quick-stat-overview-conatiner">
          <text>Summary</text>
          <div className="quick-stat-frame">
            <div className="total-workflows">
              <div className="total-workflows-label">
                <text>Total Workflows</text>
                <a href="/domain" className="total-workflows-button">
                  <div className="total-workflows-button-label">
                    <text>View All Workflows</text>
                  </div>
                </a>
              </div>
              <div className="total-workflows-count">
                <text>35</text>
              </div>
            </div>
            <div className="active-workflow">
              <div className="active-workflow-label">
                <text>Active workflows</text>
                <a href="/domain" className="active-workflow-button">
                  <div className="active-workflow-button-label">
                    <text>View Active</text>
                  </div>
                </a>
              </div>
              <div className="active-workflow-count">
                <text>7</text>
              </div>
            </div>
            <div className="completed-workflow">
              <div className="completed-workflow-label">
                <text>Completed Workflows</text>
                <a href="/domain" className="completed-workflow-button">
                  <div className="completed-workflow-button-label">
                    <text>View Completed</text>
                  </div>
                </a>
              </div>
              <div className="completed-workflow-count">
                <text>1</text>
              </div>
            </div>
            <div className="pending-actions">
              <div className="pending-actions-label">
                <text>Pending Actions</text>
                <a href="/domain" className="pending-actions-button">
                  <div className="pending-actions-button-label">
                    <text>View Active</text>
                  </div>
                </a>
              </div>
              <div className="pending-actions-count">
                <text>7</text>
              </div>
            </div>
          </div>
        </div>
        <div className="quick-action-overview-conatiner">
          <text>Quick Action</text>
          <div className="quick-action-frame">
            <div className="design-new-workflow-frame">
              <div className="design-new-workflow">
                <img src={NewWorkflow} alt="design new workflow" />
                <p>Design New Workflow</p>
              </div>
              <text>
                Tap into creativity: Design your new, efficient media workflow
                now!
              </text>
              <div className="create-new-workflow">
                <a href="/designer" className="create-new-workflow-label">
                  <text>Create a new Workflow</text>
                </a>
              </div>
            </div>
            <div className="configure-workflow-frame">
              <div className="configure-workflow">
                <img src={Settings} alt="configure workflow" />
                <p>Configure a Workflow</p>
              </div>
              <text>
                Configure for precision: Tailor workflows to fit your unique
                requirements!
              </text>
              <div className="configure-them">
                <a href="/configure" className="configure-them-label">
                  <text>Configure them</text>
                </a>
              </div>
            </div>
            <div className="run-workflow-frame">
              <div className="run-workflow">
                <img src={RunWorkflow} alt="run workflow" />
                <p>Run workflows</p>
              </div>
              <text>
                Activate and manage: Launch your workflows with seamless
                automation
              </text>
              <div className="execute-workflow">
                <a href="/orchestrator" className="execute-workflow-label">
                  <text>Execute Workflows</text>
                </a>
              </div>
            </div>
            <div className="monitor-workflow-frame">
              <div className="monitor-workflow">
                <img src={Monitor} alt="monitor workflow" />
                <p>Monitor them</p>
              </div>
              <text>
                Stay informed: Track your workflow's performance in real time
              </text>
              <div className="monitor-them">
                <a href="/domain" className="monitor-them-label">
                  <text>Monitor them</text>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-job-table">
          <p8>Recent workflows</p8>
          <RecentWorkflows dashDomain={false} limit={5} />

          <div className="view-all-workflows">
            <a href="/domain" className="view-all-workflows-frame">
              <div className="view-all-workflows-label">
                <text>View All</text>
              </div>
            </a>
          </div>
        </div>
        <div className="dashboard-job-table">
          <p8>Assignments</p8>
          <WorkAllocation create={false} limit={5} />
          <div className="view-all-workflows">
            <a href="/workAllocation" className="view-all-workflows-frame">
              <div className="view-all-workflows-label">
                <text>View All</text>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
