import React, { useEffect, useState } from "react";
import "./index.css";
import Calendar from "../../images/WorkAllocation/calendar.svg";
import { useSelector, useDispatch } from "react-redux";
import UserPro from "../../images/WorkAllocation/user.svg";
import StatusOrder from "../../images/Logos/order_down.png";
import PrevPage from "../../images/Logos/Prev.svg";
import NextPage from "../../images/Logos/Next.svg";
import CreateAssignments from "../CreateAssignment";
import { getDesignedWorkflow } from "../../redux/designerSlice";
import { getExecutionData } from "../../redux/executionSlice";
import { getConfiguredWorkflow } from "../../redux/configurationWorkflowSlice";
import { useGetProjectsQuery } from "../../redux/projectapiSlice";
import { useGetUserGroupQuery } from "../../redux/userSlice";
import { useSnackbar } from "notistack";
import {
  useGetJobAllocationsQuery,
  useEditJobAllocationsMutation,
} from "../../redux/jobAllocationSlice";
import Loader from "../Common/Loader";

const WorkAllocation = ({ create, limit }) => {
  const { projectName } = useSelector((state) => state.auth);
  const [updatedItemStatus, setUpdatedItemStatus] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getExecutionData());
    dispatch(getDesignedWorkflow());
    dispatch(getConfiguredWorkflow());
  }, []);
  const [projectData, setProjectData] = useState([]);
  const [designerData, setDesignerData] = useState([]);
  const { getDesignedWorkflows } = useSelector((state) => state.design);
  const [configData, setConfigData] = useState([]);
  const { getConfiguredWorkflows } = useSelector(
    (state) => state.configWorkflow
  );
  const [executionData, setExecutionData] = useState([]);
  const {executionData:domainData } = useSelector((state) => state.execution);

  const {
    data: getprojectData,
    isLoading: projectLoading,
    error: projectError,
  } = useGetProjectsQuery();

  const {
    data: dataUserGroup,
    isLoading: loadingUserGroup,
    error: errorUserGroup,
  } = useGetUserGroupQuery();

  const [selectedUserGroup, setSelectedUserGroup] = useState("");
  const [userNames, setUserNames] = useState([]);

  //designed Workflows:
  useEffect(() => {
    if (!getDesignedWorkflows.loading) {
      if (getDesignedWorkflows.designedWorkflows.data)
        setDesignerData(getDesignedWorkflows.designedWorkflows.data);
      // console.log("df:", designerData);
    }
  }, [getDesignedWorkflows.designedWorkflows.data, designerData]);

  //configured workflows:
  useEffect(() => {
    if (!getConfiguredWorkflows.loading) {
      if (getConfiguredWorkflows.configuredWorkflows.data)
        setConfigData(getConfiguredWorkflows.configuredWorkflows.data);
      // console.log("cf:", configData);
    }
  }, [getConfiguredWorkflows.configuredWorkflows.data, configData]);

  //executed workflows:
  useEffect(() => {
    if (!domainData.loading) {
      console.log(domainData);
      if (domainData.data.data) setExecutionData(domainData.data.data);
      console.log("ex:", executionData);
    }
  }, [domainData.data.data, executionData]);

  //projects:
  useEffect(() => {
    if (!projectLoading && !projectError) {
      if (getprojectData) {
        setProjectData(getprojectData.data);
      }
      if (projectError) {
        enqueueSnackbar(`Failed to load projects`, {
          variant: "error",
        });

        setProjectData([]);
      }
    }
  }, [projectLoading, getprojectData, projectError]);
  console.log("pr:", projectData);

  //Get User Roles:
  useEffect(() => {
    if (!loadingUserGroup && !errorUserGroup) {
      if (dataUserGroup) {
        setUserNames(dataUserGroup.data);
      }
      if (errorUserGroup) {
        enqueueSnackbar(`Failed to load user roles`, {
          variant: "error",
        });

        setUserNames([]);
      }
    }
  }, [loadingUserGroup, dataUserGroup, errorUserGroup]);

  console.log("usr:", userNames);

  const executedWorkflows = useSelector((state) => state.execution);
  const configWorkflows = useSelector((state) => state.configWorkflow);
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading: loading, error } = useGetJobAllocationsQuery(projectName._id);
  const [jobData, setJobData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // default value is 1
  const [rowsPerPage] = useState(10);
  const [showCreateAssignment, setShowCreateAssignment] = useState(false);
  const [showUpdateAssignment, setShowUpdateAssignment] = useState(false);
  const [editJobs, { isLoading: loadingEditJobs, error: errorEditJobs }] =
    useEditJobAllocationsMutation();
  const [submitting, setSubmitting] = useState(false);
  const [editdata, setEditData] = useState({});

  const handlePrevClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(rowsPerPage))
    ); // increment currentPage by 1, but ensure it doesn't go above the total number of pages
  };
  const totalRows = "";
  const rangeStart = (currentPage - 1) * rowsPerPage + 1;
  const rangeEnd = Math.min(currentPage * rowsPerPage, totalRows);
  const rangeText = `${rangeStart} to ${rangeEnd} of ${totalRows}`;

  const handleCreateAssignment = () => {
    setShowCreateAssignment(true);
  };

  const hideOverlay = () => {
    setShowCreateAssignment(false);
  };

  //show Edit window
  const handleUpdateAssignment = (data) => {
    setShowUpdateAssignment(true);
    setEditData(data);
    setUpdatedItemStatus(data.status);
  };

  //On closing edit window
  const hideOverlay2 = () => {
    setShowUpdateAssignment(false);
  };

  //On Update Project
  const onUpdate = (project) => {
    editJobFromTable(project);
    hideOverlay2();
  };

  useEffect(() => {
    if (!loading) {
      if (data) {
        // console.log(data.data);
        setJobData(data.data);
      }
      if (error) {
        enqueueSnackbar(`Failed to load Assignments`, {
          variant: "error",
        });
        setJobData([]);
      }
    }
  }, [loading, data, error]);
  console.log("jobdata:", data);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (!dateString) {
      return "No due date";
    }

    // Define months array
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Get month, day, and year
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
  };

  //Edit Jobs
  const editJobFromTable = async (data) => {
    try {
      // Dispatch action to edit  jobs
      const response = await editJobs(data).unwrap();
      if (!loadingEditJobs) {
        if (response) {
          enqueueSnackbar(response.message, {
            variant: "success",
          });
        }
        if (errorEditJobs) {
          enqueueSnackbar(errorEditJobs.message, {
            variant: "error",
          });
        }
      }
    } catch (error) {
      enqueueSnackbar("An unknown error occurred", {
        variant: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  // const editStatus = jobData
  //     ? jobData.map((status) => status.status)
  //     : [];

  //     console.log("editStatus:", editStatus)
  return (
    <div className="work-allocation-container">
      {create && (
        <div className="workAllocation-search-container">
          {designerData?.length >= 0 &&
          configData?.length >= 0 &&
          executionData?.length >= 0 &&
          projectData?.length >= 0 &&
          userNames?.length >= 0 &&
          configWorkflows.getConfiguredWorkflows?.configuredWorkflows?.data &&
          executedWorkflows.executionData?.data?.data ? (
            <button
              onClick={handleCreateAssignment}
              className="workAllocation-search-button"
            >
              <div className="workAllocation-label">
                <text>Create New Assignment</text>
              </div>
            </button>
          ) : (
            <Loader />
          )}
        </div>
      )}
      {showCreateAssignment && (
        <CreateAssignments
          onClose={hideOverlay}
          designedWorkflows={designerData}
          configWorkflows={configData}
          executedWorkflows={executionData}
          projectAssign={projectData}
          dataUserGroup={userNames}
          heading={"New Assignment"}
          jobAllocation={"Initiated"}
        />
      )}
      <div className={create ? "work-assignment-container" : ""}>
        {create && <h1>Assignments</h1>}
        <div className="row">
          <div className="col">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead className="project-header-container">
                  <tr>
                    <th id="pr1">Status</th>
                    <th id="pr2">Title</th>
                    <th id="pr3">
                      DUE DATE <img src={StatusOrder} alt="order" />
                    </th>
                    <th id="pr4">USERNAME</th>
                    <th id="pr5">PROJECT</th>
                    <th id="pr6">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <Loader />
                  ) : (
                    jobData.length > 0 &&
                    jobData.slice(0, limit).map((item, index) => (
                      <tr key={index}>
                        <td> {item?.status}</td>
                        <td>
                          {
                            item?.title
                          }
                        </td>
                        <td className="project-calendar">
                          <img src={Calendar} alt="calender-logo" />
                          <text>{formatDate(item?.dueDate)}</text>
                        </td>
                        <td>
                          <span className="project-User">
                            <img src={UserPro} alt="user-profile-logo" />
                            <text>{item?.assignedTo?.name}</text>
                          </span>
                        </td>
                        <td>
                          {item.projectName
                            ? item.projectName.projectName
                            : "No project"}
                        </td>
                        <td>
                          <span className="frameButton">
                            <button
                              onClick={() => handleUpdateAssignment(item)}
                              // onClick={() => showEditWindow(item)}
                              className="btnExecute"
                            >
                              Edit Item
                            </button>
                          </span>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <div className="setting-workallocation-scrollbar"></div>
              {/* <text className="myCompletedAssignments">
                Show my completed assignment
              </text> */}
            </div>
          </div>
        </div>
      </div>
      {create && (
        <div className="paginator">
          <span>{rangeText}</span>
          <img
            onClick={handlePrevClick}
            disabled={currentPage === 1}
            src={PrevPage}
            alt="previous page"
            className={`paginator-image${currentPage === 1 ? " disabled" : ""}`}
          />
          <img
            onClick={handleNextClick}
            disabled={currentPage === Math.ceil(rowsPerPage)}
            src={NextPage}
            alt="next page"
            className={`paginator-image${currentPage === Math.ceil(rowsPerPage) ? " disabled" : ""}`}
          />
        </div>
      )}
      {showUpdateAssignment && (
        <CreateAssignments
          onClose={hideOverlay2}
          project={editdata}
          onUpdate={onUpdate}
          designedWorkflows={designerData}
          configWorkflows={configData}
          executedWorkflows={executionData}
          projectAssign={projectData}
          dataUserGroup={userNames}
          heading={"Assignment"}
          jobAllocation={updatedItemStatus}
        />
      )}
    </div>
  );
};

export default WorkAllocation;
