import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const apiBaseUrl = process.env.REACT_APP_API_URL;

export const catalogApi = createApi({
  reducerPath: "catalogApi",
  baseQuery: customFetchBaseQuery,
  endpoints: (build) => ({
    getClients: build.query({
      query: () => "accountsettings/getClients",
      providesTags: ["Clients"],
    }),
    addClient: build.mutation({
      query: (data) => {
        return {
          url: `/accountsettings/uploadClient`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Clients"],
    }),
    deleteClient: build.mutation({
      query: (data) => {
        return {
          url: `/accountsettings/deleteClient/${data}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Clients"],
    }),
    editClient: build.mutation({
      query: (data) => {
        return {
          url: `/accountsettings/editClient/${data.id}`,
          method: "PUT",
          body:data
        };
      },
      invalidatesTags: ["Clients"],
    }),
  }),
});

export const {
  useGetClientsQuery,
  useAddClientMutation,
  useDeleteClientMutation,
  useEditClientMutation
} = catalogApi;

function customFetchBaseQuery(args, api, extraOptions) {
  const { getState } = api; // Access getState from the api object
  const { token } = getState().auth;
  return fetchBaseQuery({
    baseUrl: apiBaseUrl,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
    ...extraOptions,
  })(args, api, extraOptions);
}
