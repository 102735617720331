import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducer"; // Assuming this is a combined reducer setup
import { catalogApi } from "./apiSlice";
import { projectApi } from "./projectapiSlice";
import { jobAllocationApi } from "./jobAllocationSlice";
import { userApi } from "./userSlice";

const store = configureStore({
  reducer: rootReducer, // Directly use the combined reducer
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      catalogApi.middleware,
      projectApi.middleware,
      jobAllocationApi.middleware,
      userApi.middleware
    ),
});

export default store;
