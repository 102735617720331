import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import env from "react-dotenv";
const apiUrl = process.env.REACT_APP_API_URL;
const initialState = {
  loading: null,
  configurationData: "",
  error: null,
};
export const insertConfigData = createAsyncThunk(
  "insertService",
  async (state, thunkAPI) => {
    const {token} = thunkAPI.getState().auth;
    const response = await fetch(`${apiUrl}/api/insertServiceData`, {
      mode: "cors",
      method: "post",
      body: JSON.stringify({
        apidata: state,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`
      },
    }).catch((error) => {
      return thunkAPI.rejectWithValue("No Response from Server!");
    });
    try {
      if (response.status) {
        if (response.status === 200) {
          const response1 = await response.json();
          return response1;
        } else {
          return thunkAPI.rejectWithValue("Service Not Configured!");
        }
      } else {
        return thunkAPI.rejectWithValue("No Resonse from Server!");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Service Not Configured!");
    }
  }
);
export const ClearServiceData = () => async(dispatch) => {
  
  dispatch({ type: "CLEARALL" });
};

const serviceSlice = createSlice({
  name: "service",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(insertConfigData.pending, (state) => {
        state.loading = true;
        state.configurationData = "";
        state.error = null;
      })
      .addCase(insertConfigData.fulfilled, (state, action) => {
        state.loading = false;
        state.configurationData = action?.payload;
        state.error = null;
      })
      .addCase(insertConfigData.rejected, (state, action) => {
        state.loading = false;
        state.configurationData = "";
        state.error = action?.payload;
      })
      .addCase("CLEARALL", (state, action) => {
        console.log("Clesr is called");
        state.loading = null;
        state.configurationData = "";
        state.error = null;
      });
  },
});
export default serviceSlice.reducer;
