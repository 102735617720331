import "./index.css";

const Card = ({ heading, data, classColor }) => {
  return (
    <div className={`card-container ${classColor}`}>
      {data.map((item, index) => (
        <>
          <img key={index} src={item.image} alt={`Image ${index}`} />
          <span>
            <h3 key={index}>{heading}</h3>
            <p key={index}>{item.description}</p>
          </span>
        </>
      ))}
    </div>
  );
};

export default Card;
