import React from "react";
import { SnackbarProvider } from 'notistack'
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { registerLicense } from "@syncfusion/ej2-base";
import useAuthentication from "./service/useAuthentication";
import useConfigData from "./service/configApi";
import storeNew from "./redux/store";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import ErrorBoundary from "./components/ErrorBoundary";
// Registering Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NHaF1cWmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjWH9WcndQQGRfVkNxVg=="
);

function ConnectedApp() {
  const { AuthProvider } = useAuthentication();
  const { ConfigProvider } = useConfigData();
  return (
    <Provider store={storeNew}>
       <SnackbarProvider
        maxSnack={2}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration={2000}
      >
      <AuthProvider>
        <ConfigProvider>
        <ErrorBoundary>
       
          <App />
         
        </ErrorBoundary>
        </ConfigProvider>
      </AuthProvider>
      </SnackbarProvider>
    </Provider>
  );
}
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<ConnectedApp />);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
