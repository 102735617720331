import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import env from "react-dotenv";
const apiBaseUrl = process.env?.REACT_APP_API_URL;

const initialState = {
  loading: false,
  user: sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user"))
    : null,
  error: null,
  token: sessionStorage.getItem("token")
    ? JSON.parse(sessionStorage.getItem("token"))
    : null,
  userRole: sessionStorage.getItem("userRole")
    ? (sessionStorage.getItem("userRole"))
    : null,
  priviliges: sessionStorage.getItem("privileges")
    ? (sessionStorage.getItem("privileges"))
    : [],
  userGroup: sessionStorage.getItem("userGroup")
    ? JSON.parse(sessionStorage.getItem("userGroup"))
    : null,
  projectName: sessionStorage.getItem("projectName")
    ? JSON.parse((sessionStorage.getItem("projectName")))
    : "",
};
export const login = createAsyncThunk(
  "login",

  async (data, thunkAPI) => {
    console.log(`${apiBaseUrl}`);
    const response = await fetch(`${apiBaseUrl}/api/login`, {
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      method: "post",
      body: JSON.stringify(data),
    }).catch((error) => {
      return thunkAPI.rejectWithValue("No Resonse from Server!");
    });

    try {
      if (response.status) {
        if (response.status === 200) {
          const response1 = await response.json();
          return response1;
        } else {
          return thunkAPI.rejectWithValue("Invalid Credentials");
        }
      } else {
        return thunkAPI.rejectWithValue("No Resonse from Server!");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("No Response from Server!");
    }
  }
);
export const logout = () => (dispatch) => {
  dispatch({ type: "LOGOUT" });
};

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: "CLEAR_ERRORS" });
};
export const set_user = (data) => async (dispatch) => {
  console.log(data);
  dispatch({ type: "SETUSER",data });
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.user = null;
        state.error = null;
        sessionStorage.clear();
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action?.payload.user;
        state.error = null;
        state.token = action?.payload.token;
        state.userGroup = action?.payload?.user?.groups["0"]
          ? action.payload.user.groups["0"]["_id"]
          : "";
        state.priviliges = action?.payload?.user.groups["0"]
          ? (action?.payload?.user?.groups["0"]["privileges"])
          : [];
        state.userRole = action?.payload?.user.groups["0"]
          ?(action?.payload?.user?.groups["0"]?.userRole)
          : "";
        state.projectName = action?.payload?.user.groups["0"]
          ? action?.payload?.user.groups["0"]["projectId"]
          : "No Project Allocated";

        sessionStorage.setItem("user", JSON.stringify(action?.payload.user));
        sessionStorage.setItem("token", JSON.stringify(action?.payload.token));
        sessionStorage.setItem(
          "userGroup",
          action?.payload?.user.groups["0"]
            ? JSON.stringify(action?.payload?.user?.groups["0"]["_id"])
            : ""
        );
        sessionStorage.setItem(
          "userRole",
          action?.payload?.user.groups["0"]
            ? (action?.payload?.user?.groups["0"]["userRole"])
            : ""
        );
        sessionStorage.setItem(
          "privileges",
          action?.payload?.user.groups["0"]
            ? (action?.payload?.user?.groups["0"]["privileges"])
            : []
        );
        sessionStorage.setItem(
          "projectName",
          action?.payload?.user.groups["0"]
            ? (
              JSON.stringify(action?.payload?.user.groups["0"]["projectId"])
              )
            : null
        );
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        state.error = action?.payload;
        sessionStorage.clear();
      })
      .addCase("LOGOUT", (state, action) => {
        state.loading = false;
        state.user = null;
        state.error = null;
        state.token = null;
        sessionStorage.clear();
      })
      .addCase("CLEAR_ERRORS", (state, action) => {
        state.loading = false;
        state.error = null;
        sessionStorage.clear();
      })
      .addCase("SETUSER", (state, action) => {
        console.log(action);
        state.userGroup = action.data?.group ? action.data?.group["_id"] : "";
        state.priviliges = action.data?.group
          ? (action.data?.group["privileges"])
          : [];
        state.userRole = action.data?.group
          ? (action.data?.group?.userRole)
          : "";
        state.projectName = action.data?.group
          ?  (action.data?.group["projectId"])
          : ("No Project Allocated");

        sessionStorage.setItem(
          "userGroup",
          action.data?.group ? JSON.stringify(action.data?.group["_id"]) : ""
        );
        sessionStorage.setItem(
          "userRole",
          action.data?.group ? (action.data?.group?.userRole) : ""
        );
        sessionStorage.setItem(
          "privileges",
          action.data?.group
            ? (action.data?.group["privileges"])
            : []
        );
        sessionStorage.setItem(
          "projectName",
          action.data?.group
            ? JSON.stringify(action.data?.group["projectId"])
            : ("No Project Allocated")
        );
      });
  },
});
export default authSlice.reducer;
