import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import "./index.css";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { enableRipple } from "@syncfusion/ej2-base";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { useSelector, useDispatch } from "react-redux";
import {
  DatePickerComponent,
  TimePickerComponent,
} from "@syncfusion/ej2-react-calendars/index.js";
import { useGetProjectsQuery } from "../../redux/projectapiSlice";
import { useGetUserGroupQuery } from "../../redux/userSlice";
import { useAddJobAllocationsMutation } from "../../redux/jobAllocationSlice";
import Loader from "../Common/Loader";
import Flag from "../../images/Logos/flag.png";
import Vector from "../../images/Logos/Vector.png";
import Arrow from "../../images/Logos/arrow_drop_down.png";

const CreateAssignments = ({
  onClose,
  project,
  projectAssign,
  designedWorkflows,
  executedWorkflows,
  configWorkflows,
  dataUserGroup,
  heading,
  jobAllocation,
  onUpdate,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  //const items = ["Initiated", "In Progress", "In Review", "Completed"];
  const [selectedOption, setSelectedOption] = useState(jobAllocation);
  let options = [
    {
      text: "Initiated",
    },
    {
      text: "In Progress",
    },
    {
      text: "In Review",
    },
    {
      text: "Completed",
    },
  ];
  const handleOptionChange = (e) => {
    console.log(e);
    setSelectedOption(e.item.properties.text);
  };

  const [projectAssignment] = useState(
    projectAssign
      ? projectAssign.map((pro) => ({
          projectName: pro.projectName,
          projectId: pro._id,
        }))
      : []
  );

  const [designerWorkflowNames] = useState(
    designedWorkflows ? designedWorkflows.map((workflow) => workflow.name) : []
  );
  const [configWorkflowNames] = useState(
    configWorkflows ? configWorkflows.map((config) => config.name) : []
  );

  const [executedWorkflowNames] = useState(
    executedWorkflows ? executedWorkflows.map((exec) => exec.workflowName) : []
  );

  const [userNames, setUserNames] = useState(
    dataUserGroup.users
      ? dataUserGroup?.users.map((user) => ({
          name: user.name,
          _id: user._id,
        }))
      : []
  );
  // console.log("ur:", userNames);

  const [submitting, setSubmitting] = useState(false);
  const [addJob] = useAddJobAllocationsMutation();

  const [selectedProjects, setSelectedProjects] = useState(
    project?.projectName?._id || ""
  );

  const [selectedUserGroup, setSelectedUserGroup] = useState(
    project?.assignedTo?._id || ""
  );

  const [selectedDesigner, setSelectedDesigner] = useState(
    project?.workflowName?.designer || ""
  );

  const [selectedExecutor, setSelectedExecutor] = useState(
    project?.workflowName?.executor || ""
  );
  const [selectedConfigurator, setSelectedConfigurator] = useState(
    project?.workflowName.configurator || ""
  );
  const [title, setTitle] = useState(project?.title || "");

  const [selectedDueDate, setSelectedDueDate] = useState(
    project?.dueDate || ""
  );
  const [notes, setNotes] = useState(project?.notes || "");
  useEffect(() => {
    if (dataUserGroup) {
      const userGroupData = dataUserGroup;
      let users = [];
      const userGroupsData = userGroupData?.map((group) => {
        if (selectedProjects === group.projectId) {
          users.push(...group.users);
        }

        return users;
      });

      // console.log("users", userGroupsData);
      setUserNames(users.flat());
    }
  }, [selectedProjects]);
  //upload job:
  const clearFields = () => {
    setSelectedProjects(null);
    setSelectedDesigner(null);
    setSelectedConfigurator(null);
    setSelectedExecutor(null);
    setSelectedDueDate(""); // Clear the selected due date
    setTitle("");
    setNotes("");
    setSelectedUserGroup(null);
  };
  const onTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const onNotesChange = (e) => {
    setNotes(e.target.value);
  };
  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (submitting) return;
    try {
      setSubmitting(true);
      // Dispatch action to create new project
      if (heading === "Assignment") {
        onUpdate({
          id:project._id,
          projectName: selectedProjects,
          designer: selectedDesigner,
          configurator: selectedConfigurator,
          executor: selectedExecutor,
          title: title,
          assignedTo: selectedUserGroup,
          dueDate: selectedDueDate,
          notes: notes,
          status: selectedOption,
        });
      } else {
        const response = await addJob({
          projectName: selectedProjects,
          designer: selectedDesigner,
          configurator: selectedConfigurator,
          executor: selectedExecutor,
          title: title,
          assignedTo: selectedUserGroup,
          dueDate: selectedDueDate,
          notes: notes,
          status: "Initiated",
        }).unwrap();
        enqueueSnackbar(response.message, { variant: "success" });
      }
      clearFields();
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Failed to add jobs. Please try again.", {
        variant: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleProjectDependency = (args) => {
    setSelectedProjects(args.value);
  };

  return (
    <div className="assignment-update-overlay">
      <div className="assignment-container">
        <div className="assignment-update-container-frame1">
          <text>{heading}</text>
          <div className="assignment-update-progress">
            <div className="assignment-update-progress-container">
              <div className="progress-container-status">
                <img id="flag" src={Flag} alt="flag icon" />
                <img id="vector" src={Vector} alt="progres vector" />
              </div>
              {/* <select
                className="status-dropdown"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                {options.map((option) => (
                  <option
                    className="status-dropdown-option"
                    key={option}
                    value={option}
                  >
                    {option}
                  </option>
                ))}
              </select> */}
              <div>
                <DropDownButtonComponent
                  items={options}
                  iconCss="e-edit"
                  value={selectedOption}
                  content={selectedOption}
                  select={handleOptionChange}
                />
              </div>
            </div>
            {/* <div className="assignment-update-progress-update">
              <img src={Arrow} />
            </div> */}
          </div>
        </div>
        <div className="subnote">
          * Denotes a mandatory configuration required
        </div>
        <div className="assignment-container-frame2-edit">
          <div className="assignment-container-frame3">
            <div className="assignment-module-container-edit">
              <div className="assignment-module1-edit">
                <div className="assignment-module-project">
                  <div className="project-label">
                    <text>Project</text>
                    <p1>*</p1>
                  </div>

                  <DropDownListComponent
                    className="project-input"
                    placeholder="Select Project to create assignment"
                    dataSource={projectAssignment}
                    fields={{ text: "projectName", value: "projectId" }}
                    value={selectedProjects} // Bind selectedProject state to the value property
                    change={(e) => handleProjectDependency(e)} // Handle change event to update selected projects
                    style={{ height: "38px" }}
                  />
                </div>
                <div className="assignment-module-project">
                  <div className="project-label">
                    <text>Workflows</text>
                  </div>
                  <div className="subnotes">
                    Please select a reference workflow for this task
                  </div>
                </div>
                <div className="date-and-assignment">
                  <div className="assignment-to">
                    <div className="assignment-to-label">
                      <text> Designer</text>
                    </div>
                    <DropDownListComponent
                      className="project-input"
                      placeholder="Select Designer Workflow"
                      dataSource={designerWorkflowNames}
                      value={selectedDesigner}
                      change={(e) => setSelectedDesigner(e.value)}
                      style={{ height: "38px" }}
                     
                    />
                  </div>
                  <div className="assignment-due-date">
                    <text>Configurator</text>
                    <DropDownListComponent
                      className="project-input"
                      placeholder="Select Configurator Workflow"
                      dataSource={configWorkflowNames}
                      value={selectedConfigurator}
                      change={(e) => setSelectedConfigurator(e.value)}
                      style={{ height: "38px" }}
                    
                    />
                  </div>
                </div>
                <div className="assignment-module-title">
                  <div className="project-label">
                    <text>Executor</text>
                  </div>
                  <DropDownListComponent
                    className="project-input"
                    placeholder="Select Executed Workflow"
                    dataSource={executedWorkflowNames}
                    value={selectedExecutor}
                    change={(e) => setSelectedExecutor(e.value)}
                    style={{ height: "38px" }}
               
                  />
                </div>

                <div className="assignment-module-title">
                  <div className="project-label">
                    <text>Title</text>
                    <p1>*</p1>
                  </div>
                  <input
                    className="project-input"
                    placeholder="Describe the assignment"
                    value={title}
                    onChange={onTitleChange}
                    required
                  />
                </div>
                <div className="date-and-assignment">
                  <div className="assignment-to">
                    <div className="assignment-to-label">
                      <text>Assigned to</text>
                      <p1>*</p1>
                    </div>
                    <DropDownListComponent
                      className="assignment-to-input"
                      placeholder="Select a User to Assign"
                      dataSource={userNames}
                      fields={{ text: "name", value: "_id" }}
                      value={selectedUserGroup} // Bind selectedUserRole state to the value property
                      change={(e) => setSelectedUserGroup(e.value)} // Handle change event to update selected userRole
                      style={{ height: "38px" }}
                    />
                  </div>
                  <div className="assignment-due-date">
                    <text>Due Date</text>
                    <DatePickerComponent
                      className="assignment-due-date-calendar"
                      placeholder="Selecta a Date"
                      value={selectedDueDate}
                      change={(e) => setSelectedDueDate(e.value)}
                    />
                  </div>
                </div>
                <div className="assignment-module-note">
                  <div className="note-label">
                    <text>Notes</text>
                    <p1></p1>
                  </div>
                  <input
                    className="notes-input"
                    placeholder="Add extra details"
                    value={notes}
                    onChange={onNotesChange}
                    required
                  />
                </div>
              </div>
              <div className="assignment-button-frame">
                <button className="assignment-cancel-button" onClick={onClose}>
                  <div className="assignment-cancel-label">
                    <text>Cancel</text>
                  </div>
                </button>
                <button
                  className="assignment-create-button"
                  onClick={(e) => onFormSubmit(e)}
                >
                  <div className="assignment-create-label">
                    <text>save</text>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAssignments;
