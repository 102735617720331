import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const apiBaseUrl = process.env.REACT_APP_API_URL;

function customFetchBaseQuery(args, api, extraOptions) {
  const { getState } = api;
  const { token } = getState().auth;
  return fetchBaseQuery({
    baseUrl: apiBaseUrl,
    prepareHeaders: (headers) => {
      headers.set("content-type", "application/json");
      headers.set("Accept", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
    ...extraOptions,
  })(args, api, extraOptions);
}

export const jobAllocationApi = createApi({
  reducerPath: "jobAllocationApi",
  baseQuery: customFetchBaseQuery,
  endpoints: (build) => ({
    getJobAllocations: build.query({
      query: (projectName) => `api/getJobAllocations/${projectName}`,
      providesTags: ["Jobs"],
    }),
    addJobAllocations: build.mutation({
      query: (data) => {
        return {
          url: `/api/uploadJobAllocations`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Jobs"],
    }),
    editJobAllocations: build.mutation({
      query: (data) => {
        return {
          url: `/api/uploadJobAllocations/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["Jobs"],
    }),
  }),
});

export const {
  useGetJobAllocationsQuery,
  useAddJobAllocationsMutation,
  useEditJobAllocationsMutation,
} = jobAllocationApi;
