import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import env from "react-dotenv";
const apiBaseUrl = process.env.REACT_APP_API_URL;
const initialState = {
  getConfiguredWorkflows: {
    loading: false,
    configuredWorkflows: [],
    error: null,
  },
};


export const getConfiguredWorkflow = createAsyncThunk(
  "getConfiguredWorkflows",
  async (data, thunkAPI) => {
    const { token, userGroup } = thunkAPI.getState().auth;
    const response = await fetch(
      `${apiBaseUrl}/api/getConfiguredWorkflows/${userGroup}`,
      {
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
        method: "get",
        body: JSON.stringify(data),
      }
    ).catch((error) => {
      return thunkAPI.rejectWithValue("No Resonse from API!");
    });

    try {
      if (response.status === 200) {
        const response1 = await response.json();
        return response1;
      }
      return thunkAPI.rejectWithValue("No data");
    } catch (error) {
      return thunkAPI.rejectWithValue("No Resonse from API!");
    }
  }
);

export const clearAll = () => async (dispatch) => {
  dispatch({ type: "CLEAR_All" });
};

const authSlice = createSlice({
  name: "configurator",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getConfiguredWorkflow.pending, (state) => {
        state.getConfiguredWorkflows.loading = true;
        state.getConfiguredWorkflows.configuredWorkflows = [];
        state.getConfiguredWorkflows.error = null;
      })
      .addCase(getConfiguredWorkflow.fulfilled, (state, action) => {
        state.getConfiguredWorkflows.loading = false;
        state.getConfiguredWorkflows.configuredWorkflows = action?.payload;
        state.getConfiguredWorkflows.error = null;
      })
      .addCase(getConfiguredWorkflow.rejected, (state, action) => {
        state.getConfiguredWorkflows.loading = false;
        state.getConfiguredWorkflows.configuredWorkflows = [];
        state.getConfiguredWorkflows.error = action?.payload;
      })
      .addCase("CLEARALL", (state, action) => {
        state.getConfiguredWorkflows.loading = null;
        state.getConfiguredWorkflows.configuredWorkflows = "";
        state.getConfiguredWorkflows.error = null;
      });
  },
});
export default authSlice.reducer;
