import React, { useRef, useEffect, useState } from "react";
import JSONEditor from "jsoneditor";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import {
  saveFileDataContent as saveData,
  clearAll,
} from "../../redux/apiFileSlice";
import "jsoneditor/dist/jsoneditor.min.css"; // Import the CSS for styling

const JsonEditor = ({ jsonData, onChange, metaData }) => {
  const { token } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const [isValid, setIsValid] = useState(false);
  const { saveFileContent } = useSelector((state) => state.apiFile);
  const dispatch = useDispatch();
  const jsonEditorRef = useRef(null);
  const onSave = () => {
    const fileName = prompt("Enter the File name");
    dispatch(
      saveData({
        fileName: fileName,
        fileContent: UpdatedJson,
        state: metaData,
        token:token
      })
    );
  };

  useEffect(() => {
    if (!saveFileContent.loading) {
      if (saveFileContent?.data) {

        enqueueSnackbar(saveFileContent?.msg, { variant: "success" });
        dispatch(clearAll());
        onChange();
      } 
      if (saveFileContent?.error) { 
        enqueueSnackbar(saveFileContent?.error?.message||"File Could not be saved", { variant: "error" });
        dispatch(clearAll());
        onChange();
      }
     
    }
  }, [saveFileContent.loading,saveFileContent.data]);
  const [UpdatedJson, setUpdateJson] = useState(jsonData);

  useEffect(() => {
    // Initialize the JSON editor when the component mounts

    const containerElement = document.getElementById("jsoneditor");
    if (containerElement) {
      const options = {
        mode: "text", // You can customize the available modes
        onChange: () => {
          // Get the updated JSON content and pass it to the parent component
          try {
            const updatedJson = JSON.parse(jsonEditorRef.current.getText());

            if (updatedJson) {
              setUpdateJson(updatedJson);
              setIsValid(true);
            }
          } catch (error) {
            console.log(error);
          }
        }, // Callback function for changes
      };

      jsonEditorRef.current = new JSONEditor(containerElement, options);

      // Set the initial JSON data
      jsonEditorRef.current.setText(JSON.stringify(jsonData, null, 2));

      return () => {
        // Cleanup on component unmount

        if (jsonEditorRef.current) {
          jsonEditorRef.current.destroy();
        } else {
          console.log(jsonEditorRef);
        }
      };
    } else {
      console.error("Container element not found");
    }
  }, [jsonData, onChange]);
  return (
    <>
      <div id="jsoneditor" style={{ height: "500px", width: "600px" }} />
      <div className="row custom-margin custom-padding-5 material">
        <button
          className="col-xs-6 col-sm-6 col-lg-6 col-md-6 green"
          disabled={!isValid}
          onClick={(e) => {
            onSave();
          }}
        >
          Save
        </button>
        <button
          className="col-xs-6 col-sm-6 col-lg-6 col-md-6 red "
          onClick={onChange}
        >
          Close
        </button>
      </div>
    </>
  );
};

export default JsonEditor;
