import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./index.css";
import { set_user } from "../../../redux/authSlice";
import ChangeProject from "./changeProject";
const UserDropDown = ({
  userName,
  onLogout,
  projectName,
  userRole,
  user,
  userRef,
  onClose,
}) => {
  const dispatch = useDispatch();

  const [showOverlay, setShowOverlay] = useState(false);
  const [projectData] = useState(getProjectData(user));
  function getProjectData(user) {
    if (user?.groups) {
      const data = user.groups.map((element) => element.projectId);
      return data;
    }
  }
  const history = useHistory();
  const onAccountSettingsClick = () => {
    history.push("/account_settings/profile");
    onClose();
  };
  const onAdminSettingsClick = () => {
    history.push("/account_settings/client");
    onClose();
  };
  const onProjectSettingsClick = () => {
    history.push("/account_settings/users");
    onClose();
  };

  //On closing edit window
  const onCloseEditWindow = () => {
    setShowOverlay(false);
  };
  //On Update Project
  const onUpdate = (project) => {
    if (user?.groups) {
      const userGroup = user.groups.find(
        (element) => element.projectId._id === project.id
      );
      dispatch(set_user({ group: userGroup }));
    }
    onCloseEditWindow();
    onClose();
  };
  return (
    <>
      <div className="setting-client-scrollbar">
        {showOverlay && (
          <ChangeProject
            onClose={onCloseEditWindow}
            onUpdate={onUpdate}
            projectData={projectData}
            project={projectName}
          />
        )}
      </div>
      <div>
        <div className="user-dropdown">
          <div className="user-modal">
            <div className="user-dropdown-btn">
              <text className="capitalize-first-letter">Hi, {userName}</text>
            </div>

            <div
              className="project-user"
              onClick={() => {
                setShowOverlay(true);
              }}
            >
              <text>{projectName.projectName}</text>
              <p1>Change Project</p1>
            </div>

            <div className="user-dropdown-content">
              <div
                className="account-settings-elements"
                onClick={onAccountSettingsClick}
              >
                <text>Account Settings</text>
              </div>
              {userRole === "Super Admin" && (
                <div
                  onClick={onAdminSettingsClick}
                  className="account-settings-elements"
                >
                  <text>Admin Settings</text>
                </div>
              )}
              {(userRole === "Super Admin" || userRole === "Team Lead") && (
                <div
                  onClick={onProjectSettingsClick}
                  className="account-settings-elements"
                >
                  <text>Project Settings</text>
                </div>
              )}
              <div className="account-settings-elements">
                <text>help</text>
              </div>
              <div onClick={onLogout} className="account-settings-elements">
                <text>Logout</text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDropDown;
