import "./index.css";
import { useRef } from "react";

const SaveDialog = ({ title, header,onSave,onClose}) => {
  const nameRef=useRef(null);
  const descRef=useRef(null);
  return (
    <div className="dialogInnerContainer">
      <div className="headerContainer">
        <text className="title">{title}</text>
        <text className="subHeading">
          Are you ready to save your design to the mediamesh cloud? This will
          secure your workflow, allowing you to access and edit it anytime,
          anywhere.
        </text>
      </div>
      <div className="main-container">
        <div className="main-sub-container">
          <div className="header-span"><text className="headerName">{header}</text><text className="span">*</text></div>
          <input
            type="text"
            ref={nameRef}
            className="inputContainer"
            placeholder="Enter Filename"
          ></input>
          <div className="header-span"><text className="headerName ">Description(Optional)</text><text className="span"></text></div>
          <input
            type="text"
            className="inputContainer"
            ref={descRef}
            placeholder="Use this description to identify properly your workflow"
          ></input>
        </div>
        <div className="buttonContainer">
        <button className="back-button" onClick={()=>onClose()}>Cancel  </button>
        <button className="save-button" onClick={()=>onSave(nameRef?.current?.value,descRef?.current?.value)}>
          <div className="save-button-container">
            <text>Save </text>
          </div>
        </button>
      </div>
      </div>
     
    </div>
  );
};
export default SaveDialog;
