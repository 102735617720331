import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Drawer } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/authSlice";
import UserDropDown from "../Common/UserDropDown";
import MediaMesh_logo from "../../images/Logos/MediaMeshLogo.png";
import North_east from "../../images/Logos/north_east.png";
import Menu from "../../images/Logos/menu.png";
// import "@fortawesome/fontawesome-free/css/all.css";

import "./index.css";

const MenuItem = ({
  to,
  children,
  selected,
  onClick,
  mobileVisible,
  mobileHidden,
}) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <Link
      to={to}
      className={`menu-item ${hovered || selected ? "hovered" : ""} ${selected ? "selected" : ""}${selected ? "selected" : ""} ${mobileVisible ? "mobileVisible" : ""} ${mobileHidden ? "mobileHidden" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

const Header = () => {
  const {
    user,
    projectName,
    priviliges: privileges,
    userRole,
  } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const isLoggedIn = user !== null;
  const userName = isLoggedIn ? user.name : "";
  //const projectName=(user?.groups[0]) ? user?.groups[0]["projectId"]["projectName"]:"No Project Allocated";
  const [showDrawer, setShowDrawer] = useState(false);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const userDropdownRef = useRef(null);
  const history = useHistory();

  const handleShowDrawer = () => {
    setShowDrawer(true);
  };
  const handleCloseDrawer = () => {
    setShowDrawer(false);
  };

  const handleUserDropdownClick = () => {
    setShowUserDropdown(!showUserDropdown);
  };

  const handleLogout = () => {
    dispatch(logout());
    setShowUserDropdown(false);
    history.push("/");
    // setShowDrawer(false);
  };

  const handleClickOutside = (event) => {
    if (
      userDropdownRef.current &&
      !userDropdownRef.current.contains(event.target)
    ) {
      setShowUserDropdown(false);
    }
  };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const [selectedPage, setSelectedPage] = useState("/");

  const handleMenuItemClick = (page) => {
    setSelectedPage(page);

    setShowDrawer(false);
  };
  // const showDrawer = screens.xs;

  useEffect(() => {
    const handleResize = () => {
      setShowDrawer(window.innerWidth <= 300); // Show drawer if window width is less than or equal to 300px
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav className="header-container">
      <div className="logo-container">
        <Link to="/">
          <img src={MediaMesh_logo} alt="Logo" />{" "}
        </Link>
      </div>
      {isLoggedIn && (
        <>
          <div className="menu-container">
            <MenuItem
              to="/dashboard"
              selected={selectedPage === "/dashboard"}
              onClick={() => handleMenuItemClick("/dashboard")}
              className="custom-menu-item"
              mobileHidden={!showDrawer}
              mobileVisible={showDrawer}
            >
              Dashboard
            </MenuItem>
            {(privileges.includes("All") ||
              privileges.includes("Designer")) && (
              <MenuItem
                to="/designer"
                selected={selectedPage === "/designer"}
                onClick={() => handleMenuItemClick("/designer")}
              >
                Designer
              </MenuItem>
            )}
            {(privileges.includes("All") ||
              privileges.includes("Service Settings")) && (
              <MenuItem
                to="/serviceConfigure"
                selected={selectedPage === "/serviceConfigure"}
                onClick={() => handleMenuItemClick("/serviceConfigure")}
              >
                Service Settings
              </MenuItem>
            )}
            {(privileges.includes("All") ||
              privileges.includes("Configurator")) && (
              <MenuItem
                to="/configure"
                selected={selectedPage === "/configure"}
                onClick={() => handleMenuItemClick("/configure")}
              >
                Configurator
              </MenuItem>
            )}
            {(privileges.includes("All") ||
              privileges.includes("Orchestrator")) && (
              <MenuItem
                to="/orchestrator"
                selected={selectedPage === "/orchestrator"}
                onClick={() => handleMenuItemClick("/orchestrator")}
              >
                Orchestrator
              </MenuItem>
            )}
            {(privileges.includes("All") ||
              privileges.includes("Monitor Status")) && (
              <MenuItem
                to="/domain"
                selected={selectedPage === "/domain"}
                onClick={() => handleMenuItemClick("/domain")}
              >
                Monitor Status
              </MenuItem>
            )}
            {(privileges.includes("All") ||
              privileges.includes("Work Allocation")) && (
              <MenuItem
                to="/workAllocation"
                selected={selectedPage === "/workAllocation"}
                onClick={() => handleMenuItemClick("/workAllocation")}
              >
                Work Allocation
              </MenuItem>
            )}
          </div>
          <div className="mobile-menu-container">
            <button onClick={handleShowDrawer}>
              <img className="menu-image" src={Menu} alt="Menu Icon" />
              {/* <i className="fas fa-bars" /> */}
            </button>
            <Drawer
              className="mobileVisible"
              title="Menu"
              placement="right"
              onClose={handleCloseDrawer}
              visible={showDrawer}
              width="280px"
            >
              <MenuItem
                to="/dashboard"
                selected={selectedPage === "/dashboard"}
                onClick={() => handleMenuItemClick("/dashboard")}
                className="custom-menu-item"
                mobileHidden={!showDrawer}
                mobileVisible={showDrawer}
              >
                Dashboard
              </MenuItem>
              <MenuItem
                to="/designer"
                selected={selectedPage === "/designer"}
                onClick={() => handleMenuItemClick("/designer")}
              >
                Designer
              </MenuItem>
              <MenuItem
                to="/configure"
                selected={selectedPage === "/configure"}
                onClick={() => handleMenuItemClick("/configure")}
              >
                Configurator
              </MenuItem>
              <MenuItem
                to="/orchestrator"
                selected={selectedPage === "/orchestrator"}
                onClick={() => handleMenuItemClick("/orchestrator")}
              >
                Orchestrator
              </MenuItem>
              <MenuItem
                to="/domain"
                selected={selectedPage === "/domain"}
                onClick={() => handleMenuItemClick("/domain")}
              >
                Monitor Status
              </MenuItem>
              <MenuItem
                to="/workAllocation"
                selected={selectedPage === "/workAllocation"}
                onClick={() => handleMenuItemClick("/workAllocation")}
              >
                Work Allocation
              </MenuItem>
            </Drawer>
          </div>
        </>
      )}
      {isLoggedIn ? (
        <div className="icon-container" ref={userDropdownRef}>
          <ul>
            <div onClick={handleUserDropdownClick}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="39"
                height="40"
                viewBox="0 0 39 40"
                fill="none"
              >
                <path
                  d="M19.5 20.5C21.9853 20.5 24 18.4853 24 16C24 13.5147 21.9853 11.5 19.5 11.5C17.0147 11.5 15 13.5147 15 16C15 18.4853 17.0147 20.5 19.5 20.5Z"
                  stroke="#636262"
                />
                <path
                  d="M19.5 33.5C26.9558 33.5 33 27.4558 33 20C33 12.5442 26.9558 6.5 19.5 6.5C12.0442 6.5 6 12.5442 6 20C6 27.4558 12.0442 33.5 19.5 33.5Z"
                  stroke="#636262"
                />
                <path
                  d="M27 30.5C26.8001 26.4519 25.6878 23.5 19.5 23.5C13.3123 23.5 12.2 26.4519 12 30.5"
                  stroke="#636262"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            {showUserDropdown && (
              <UserDropDown
                userName={userName}
                onLogout={handleLogout}
                projectName={projectName}
                userRole={userRole}
                user={user}
                userRef={userDropdownRef}
                onClose={handleUserDropdownClick}
              />
            )}
          </ul>
        </div>
      ) : (
        <div className="icon-container2">
          <div className="link-container">
            <Link className="link-text" to="/login">
              Login
            </Link>
            <img src={North_east} alt="Login" />
          </div>
          <div className="link-container">
            <Link className="link-text" to="/signup">
              Register
            </Link>
            <img src={North_east} alt="Login" />
          </div>
        </div>
      )}
    </nav>
  );
};

export default Header;
