import React, { useState, useRef } from "react";
import lock from "../../images/lock.png";
import PropTypes from "prop-types";
import { ListViewComponent } from "@syncfusion/ej2-react-lists";
function deepCopy(obj) {
  if (typeof obj !== 'object' || obj === null) {
    // If obj is not an object or is null, return it as is
    return obj;
  }

  let copiedObj = Array.isArray(obj) ? [] : {};

  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // Recursively copy nested objects and arrays
      copiedObj[key] = deepCopy(obj[key]);
    }
  }

  return copiedObj;
}
const ConfigTemplateWindow = ({
  workflowData,
  workflowFields,
  workflowText,
  workflowQuery,
  selectWorkflow,
}) => {
const newWorkflowData=deepCopy(workflowData)
  const selectedTemplateFilter = useRef(null);
  const [filteredWorkflow, setFilteredworkflow] = useState(
    newWorkflowData.configuredTemplates
  );

  function listTemplate(data) {
    return (
      <div className="list-wrapper">
        <span className="list-content">{data.name}</span>
        <img src={lock} alt="lock_img" className="lock" />
      </div>
    );
  }

  const searchWorkflow = (args) => {
    if (args?.target.value !== "") {
      const newWorkflows = [...filteredWorkflow];
      const searchedFound = newWorkflows?.filter((element) => {
        if (
          element.name.toLowerCase().includes(args.target.value.toLowerCase())
        ) {
          return element;
        }
      });
      setFilteredworkflow(searchedFound);
    } else {
      setFilteredworkflow(newWorkflowData[selectedTemplateFilter?.current.value]);
    }
  };
  const filterWorkflow = (args) => {
    if (args?.target.value !== "") {
      setFilteredworkflow([...newWorkflowData[args?.target.value]]);
    }
  };

  return (
    <div className="template-content">
      <center>
        <h1 className="template-header">Select your workflow to configure</h1>
        <p className="template-subtitle">
          Choose from configured templates or start with a new configuration.
        </p>
      </center>
      <div className="fileCardContainer">
        <text className="list-template-header">Workflow Templates</text>

        <input
          className="searchTemplateBar"
          placeholder="Search for a template"
          type="text"
          onChange={(e) => searchWorkflow(e)}
        ></input>
        <div className="filterTab">
          <div className="filter-box">
            <text className="list-template-filter">Filter:</text>

            <select
              className="list-filter-dropdown select-option"
              ref={selectedTemplateFilter}
              id="filterTemplate"
              popupHeight="300px"
              onChange={filterWorkflow.bind(this)}
            >
              <option value="configuredTemplates" className="select-option">
                Configured Workflows
              </option>
              <option value="templates" className="select-option">
                New Workflows
              </option>
            </select>
          </div>
          <div className="filter-box">
            <text className="list-template-filter">Filter:</text>

            <select
              className="list-filter-dropdown select-option"
              id="filterTime"
              popupHeight="300px"
            >
              <option value="option 1" className="select-option">
                Last Modified
              </option>
            </select>
          </div>
        </div>

        <ListViewComponent
          id="workflow"
          dataSource={filteredWorkflow}
          fields={workflowFields}
          text={workflowText}
          query={workflowQuery}
          popupHeight="300px"
          template={listTemplate}
          select={(e) => {
            // const selectedId = e.data._id;
            // console.log(workflowData);
            // const set = workflowData[
            //   selectedTemplateFilter?.current.value
            // ].find((element) => element["_id"] === selectedId);
            // console.log("Set");
            // console.log(set);
            selectWorkflow(e);
          }}
        />
      </div>
    </div>
  );
};
ConfigTemplateWindow.propTypes = {
  workflowData: PropTypes.object.isRequired,
  workflowFields: PropTypes.object.isRequired,
  workflowText: PropTypes.string.isRequired,
  workflowQuery: PropTypes.string.isRequired,
  selectWorkflow: PropTypes.func.isRequired,
};

export default ConfigTemplateWindow;
