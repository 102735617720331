import "./index.css";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../Common/Loader.jsx";
import { preview as previewFunction, clearErrors } from "../../redux/previewSlice.js";
function MonitorPreview() {
  const [videoSource, setVideoSource] = useState("");
  const [imageSource, setImageSource] = useState("");
  const { loading, data, error } = useSelector((state) => state.preview);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [ioFilter, setIOFilter] = useState("All");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedTrackName, setSelectedTrackName] = useState("");
  const [previewData, setPreviewData] = useState([
    {
      id: "0000",
      type: "mov",
      filename: "sample_2.mov",
      duration: "00:00:00.00",
      creation: "Jan 1st 2024",
      url: "https://api-uploadtest.s3.amazonaws.com/samHD.mp4",
    },
    {
      id: "0000",
      type: "mov",
      filename: "Sample_1.mov",
      duration: "00:00:00.00",
      creation: "Jan 1st 2024",
      size: "2 bytes",
    },
    {
      id: "0000",
      type: "jpg",
      filename: "showfilenameEp01",
      duration: "00:00:00.00",
      creation: "Jan 1st 2024",
    },
  ]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!loading) {
      if (data) {
        setPreviewData(data);
        enqueueSnackbar(`Assets data Loaded!`, {
          variant: "success",
        });
        dispatch(clearErrors());
      }
      if (error) {
        enqueueSnackbar(error, {
          variant: "error",
        });
        dispatch(clearErrors());
      }
    }
  }, [loading, data, error]);
  useEffect(() => {
    if (id) {
      dispatch(previewFunction(id));
    }
  }, [id]);

  const handleRowClick = (item) => {
    setSelectedTitle(item.title);
    setSelectedTrackName(item.filename);
    if(item.type === "jpg" || item.type === "png" || item.type === "gif")
    {
setImageSource(item.url);
setVideoSource("");
    }
    else{
    setVideoSource(item.url);
    setImageSource("");
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value); // Step 2: Handle input change
  };

  const handleIOFilterChange = (e) => {
    setIOFilter(e.target.value); // Step 2: Handle filter change
  };

  //filter filename:
  const filteredPreviewData = previewData.filter((item) => {
    const filenameMatch = item.filename
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const ioMatch = ioFilter === "All" || item.fileType === ioFilter;

    // Return true only if both filters match
    return filenameMatch && ioMatch;
  });

  return (
    <div className="grid-container">
      <div className="preview-leftside" style={{ backgroundColor: "white" }}>
        <div className="header-frame">
          <div className="preview-frame1">Files Preview</div>
          <div className="preview-frame2">Dailies Workflow</div>
          <div className="SearchBarPreview">
            <div className="filename-id">
              <div className="workflow-id-frame">
                {/* <div className="workflow-id-frame-label">Filename</div>
                <text className="workflow-id-frame-sublabel">*</text> */}
              </div>

              <input
                className="filename-id-input"
                type="text"
                placeholder="Search Filename"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>

            <div className="workflow-id">
              <div className="workflow-id-frame">
                {/* <div className="workflow-id-frame-label">Input/output</div> */}
                {/* <text className="workflow-id-frame-sublabel">*</text> */}
              </div>

              <select
                className="io-input"
                value={ioFilter}
                onChange={handleIOFilterChange}
              >
                <option value="All">All</option>
                <option value="Input">Input</option>
                <option value="Output">Output</option>
              </select>
            </div>
          </div>
        </div>
        <div className="table-asset">
          <table>
            <thead>
              <tr>
                <th>TYPE</th>
                <th>TITLE</th>
                <th>I/O</th>
                <th>ID</th>
                <th>FILE NAME</th>
                <th>DURATION</th>
                <th>CREATION</th>
              </tr>
            </thead>
            <tbody>
              {!loading ? (
                filteredPreviewData.map((item, index) => (
                  <tr key={index} onClick={() => handleRowClick(item)}>
                    <td>
                      {item.type === "jpg" ? (
                        <img
                          src="/jpg_image.png"
                          alt="JPG"
                          style={{ width: "25px", height: "25px" }}
                        />
                      ) : item.type === "mov" || item.type === "mp4" ? (
                        <img
                          src="/movfile_image.jpg"
                          alt="MOV"
                          style={{ width: "30px", height: "25px" }}
                        />
                      ) : item.type === "sub" ? (
                        <img
                          src="path_to_sub_icon.jpg"
                          alt="SUB"
                          style={{ width: "20px", height: "auto" }}
                        />
                      ) : (
                        <img
                          src="path_to_default_icon.jpg"
                          alt="Default"
                          style={{ width: "20px", height: "auto" }}
                        />
                      )}
                    </td>
                    <td>{item.title}</td>
                    <td>{item.fileType}</td>
                    <td>{item["_id"]?item["_id"].slice(-5):""}</td>
                    <td>{(item["filename"]?item["filename"].substring(item["filename"].lastIndexOf("/")):"")}</td>
                    <td>{item.duration}</td>
                    <td>{item.creation}</td>
                  </tr>
                ))
              ) : (
                <Loader />
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="preview-rightside" style={{ marginTop: "10vh" }}>
        <div className="uppper-right">
          <div className="playerDiv">
            <>
              {videoSource && (
                <video
                  controls
                  style={{ width: "100%", height: "100%" }}
                  src={`${videoSource}`}
                ></video>
              )}
              {imageSource && (
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={`${imageSource}`}
                  alt="Image"
                />
              )}
            </>
          </div>
        </div>

        <div className="lower-right">
          <div style={{ margin: "10px 0" }}>
            <text className="filemetadata_" style={{ marginBottom: "10px" }}>
              File Metadata
            </text>
          </div>
          <div>
            <text>TITLE</text>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              placeholder="Entered text"
              className="input-box"
              value={selectedTitle}
              readOnly
            />
          </div>
          <div>
            <text>TRACK NAME</text>
          </div>
          <div>
            <input
              type="text"
              placeholder="Entered text"
              className="input-box"
              value={selectedTrackName}
              readOnly
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <button disabled="true" className="btnclose">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MonitorPreview;
