// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-approval {
  display: flex;
  height: 36px;
  padding: 6px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--tools-disabled, #d2d2d2);
  background: var(--header-background, #fff);
  color: var(--Secondary-button, #8f8f8f);

  /* Desktop/text-button-menu */
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
`, "",{"version":3,"sources":["webpack://./src/components/MonitorApproval/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;EACnB,SAAS;EACT,kBAAkB;EAClB,gDAAgD;EAChD,0CAA0C;EAC1C,uCAAuC;;EAEvC,6BAA6B;EAC7B,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".btn-approval {\r\n  display: flex;\r\n  height: 36px;\r\n  padding: 6px 12px;\r\n  align-items: center;\r\n  gap: 10px;\r\n  border-radius: 8px;\r\n  border: 1px solid var(--tools-disabled, #d2d2d2);\r\n  background: var(--header-background, #fff);\r\n  color: var(--Secondary-button, #8f8f8f);\r\n\r\n  /* Desktop/text-button-menu */\r\n  font-family: Inter;\r\n  font-size: 15px;\r\n  font-style: normal;\r\n  font-weight: 500;\r\n  line-height: normal;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
