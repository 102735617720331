import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import apiReducer from "./apiFileSlice";
import serviceSlice from "./serviceSlice";
import configurationReducer from "./configurationSlice";
import designReducer from "./designerSlice";
import executionSlice from "./executionSlice";
import signUpSlice from "./signUpSlice";
import clientReducer from "./accountSettingSlice";
import previewSlice from "./previewSlice";
import guestSliece from "./guestContact";
import { catalogApi } from "./apiSlice";
import {jobAllocationApi } from "./jobAllocationSlice"
import { projectApi } from "./projectapiSlice";
import ConfigWorkflows from "./configurationWorkflowSlice"
import { userApi } from "./userSlice";

export default combineReducers({
  auth: authReducer,
  apiFile: apiReducer,
  configWorkflow: ConfigWorkflows,
  configuration: configurationReducer,
  service: serviceSlice,
  design: designReducer,
  execution: executionSlice,
  signUp: signUpSlice,
  client: clientReducer,
  preview: previewSlice,
  guest: guestSliece,
  [catalogApi.reducerPath]: catalogApi.reducer,
  [jobAllocationApi.reducerPath]: jobAllocationApi.reducer,
  [projectApi.reducerPath]: projectApi.reducer,
  [userApi.reducerPath]: userApi.reducer 
});
