import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const apiBaseUrl = process.env.REACT_APP_API_URL;

export const projectApi = createApi({
  reducerPath: "projectApi",
  baseQuery: customFetchBaseQuery,
  endpoints: (build) => ({
    getProjects: build.query({
      query: () => "accountsettings/getProjects",
      providesTags: ["Projects"],
    }),
    addProject: build.mutation({
      query: (data) => {
        return {
          url: `/accountsettings/uploadProject`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Projects"],
    }),
    deleteProject: build.mutation({
      query: (data) => {
        return {
          url: `/accountsettings/deleteProject/${data}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Projects"],
    }),
    editProject: build.mutation({
      query: (data) => {
        return {
          url: `/accountsettings/editProject/${data.id}`,
          method: "PUT",
          body:data
        };
      },
      invalidatesTags: ["Projects"],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useAddProjectMutation,
  useDeleteProjectMutation,
  useEditProjectMutation
} = projectApi;

function customFetchBaseQuery(args, api, extraOptions) {
  const { getState } = api; // Access getState from the api object
  const { token } = getState().auth;
  return fetchBaseQuery({
    baseUrl: apiBaseUrl,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
    ...extraOptions,
  })(args, api, extraOptions);
}
