import React, { useState, useEffect } from "react";
import "./changeProject.css";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";

const ChangeProject = ({
  onClose,
  project,
  onUpdate,
  projectData,
  userRef,
}) => {
  const [selectedProject, setSelectedProject] = useState(project._id);

  const handleChangeProject = (e) => {
    setSelectedProject(e.value);
  };

  return (
    <div className="assignment-container1" ref={userRef}>
      <div className="assignment-container-frame1">
        <text>Switch Project</text>
      </div>
      <div className="assignment-container-frame2">
        <div className="assignment-container-frame3">
          <div className="assignment-module-container">
            <div className="assignment-module1">
              <div className="assignment-module-title">
                <div className="project-label">
                  <text>Project Name</text>
                  <p1>*</p1>
                </div>
                <DropDownListComponent
                  placeholder="Select Project"
                  dataSource={projectData}
                  fields={{ text: "projectName", value: "_id" }}
                  value={selectedProject}
                  change={handleChangeProject}
                  style={{ height: "28px" }}
                />
              </div>
            </div>
            <div className="assignment-button-frame">
              <button className="assignment-cancel-button" onClick={onClose}>
                <div className="assignment-cancel-button-label">
                  <text>Cancel</text>
                </div>
              </button>
              <button
                className="assignment-create-button"
                onClick={() =>
                  onUpdate({
                    id: selectedProject,
                  })
                }
              >
                <div className="assignment-create-button-label">
                  <text>Switch</text>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeProject;
