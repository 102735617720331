import React, { useState, useEffect } from "react";
import JsonEditor from "./JsonEditor";
//import parserJson from 'prettier/parser-json';

const EditJsonFile = ({ data, onClose, metaData }) => {

  const [jsonData, setJsonData] = useState(data);
  
  return (
    <div>
      <center>
        <h1>JSON Editor</h1>
      </center>
      <JsonEditor jsonData={jsonData} onChange={onClose} metaData={metaData} />
    </div>
  );
};

export default EditJsonFile;
