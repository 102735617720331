import React, { useState, createContext } from "react";

const configCtx = createContext();
const useConfigData=()=>{
  const [workflow, setWorkflow] = useState(null);
  const [workflowId, setWorkflowId] = useState(null);
  const [configId, setConfigId] = useState(null);
  const [status, setStatus] = useState(null);
  const [apiData, setApiData] = useState(null);
  const [workflowData, setWorkflowData] = useState(null);
  const [fileName, setFileName] = useState("Untitled");
  const [carbonScore, setCarbonScore] = useState({});
const saveData=(workflow,workflowId,configId,status,apiData,workflowData,fileName,carbonScore )=>{
    setWorkflow(workflow);
    setWorkflowId(workflowId);
    setConfigId(configId);
    setStatus(status);
    setApiData(apiData);
    setWorkflowData(workflowData);
    setFileName(fileName)
    setCarbonScore(carbonScore)
}
return {
  workflow,workflowId,configId,status,apiData,
  workflowData,fileName,
  saveData,carbonScore
};
}
const ConfigProvider = () => {
  const {workflow,workflowId,configId,status,apiData, saveData,workflowData,fileName,carbonScore}=useConfigData();
  return {
    configCtx,
    ConfigProvider: ({ children }) => (
      <configCtx.Provider value={{ workflow,workflowId,configId,status,apiData, saveData,workflowData,fileName,carbonScore}}>
        {children}
      </configCtx.Provider>
    ),
  };
}
export default ConfigProvider;