import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const apiBaseUrl = process.env.REACT_APP_API_URL;
const initialState = {
  filesave: {
    loading: false,
    fileSaveData: "",
    error: null,
  },
  getDesignedWorkflows: {
    loading: false,
    designedWorkflows: [],
    error: null,
  },
};
export const fileSave = createAsyncThunk("fileSave", async (data, thunkAPI) => {
  const { token,userGroup } = thunkAPI.getState().auth;
  // const response = await fetch(`${apiBaseUrl}/api/uploadFile/${userGroup}`, {
  //   mode: "cors",
  //   headers: {
  //     Accept: "application/json",
  //     "Content-Type": "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //     Authorization: `Bearer ${token}`,
  //   },
  //   method: "post",
  //   body: JSON.stringify(data),
  // }).catch((error) => {
  //   return thunkAPI.rejectWithValue("No Resonse from Server!");
  // });

  try {
    const response = await fetch(`${apiBaseUrl}/api/uploadFile/${userGroup}`, {
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
      method: "post",
      body: JSON.stringify(data),
    });
    if (response.status === 200) {
      const responseData = await response.json();
      return responseData;
    } else {
      const errorData = await response.json();
      return thunkAPI.rejectWithValue(errorData);
    }
  } catch (error) {
    return thunkAPI.rejectWithValue("No response from server!");
  }
});
export const getDesignedWorkflow = createAsyncThunk(
  "getDesignedWorkflow",
  async (_, thunkAPI) => {
    const { token, userGroup } = thunkAPI.getState().auth;
    try {
      const response = await fetch(
        `${apiBaseUrl}/api/getWorkflows/${userGroup}`,
        {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        const designedWorkflows = await response.json();
        return designedWorkflows;
      } else {
        const errorData = await response.json();
        return thunkAPI.rejectWithValue(errorData);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("No response from API!");
    }
  }
);
export const clearAll = () => async (dispatch) => {
  dispatch({ type: "CLEAR_ALL" });
};
const authSlice = createSlice({
  name: "designer",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fileSave.pending, (state) => {
        state.filesave.loading = true;
        state.filesave.fileSaveData = "";
        state.filesave.error = null;
      })
      .addCase(fileSave.fulfilled, (state, action) => {
        state.filesave.loading = false;
        state.filesave.fileSaveData = action.payload;
        state.filesave.error = null;
      })
      .addCase(fileSave.rejected, (state, action) => {
        state.filesave.loading = false;
        state.filesave.fileSaveData = "";
        state.filesave.error = action.payload;
      })
      .addCase(getDesignedWorkflow.pending, (state) => {
        state.getDesignedWorkflows.loading = true;
        state.getDesignedWorkflows.designedWorkflows = [];
        state.getDesignedWorkflows.error = null;
      })
      .addCase(getDesignedWorkflow.fulfilled, (state, action) => {
        state.getDesignedWorkflows.loading = false;
        state.getDesignedWorkflows.designedWorkflows = action.payload;
        state.getDesignedWorkflows.error = null;
      })
      .addCase(getDesignedWorkflow.rejected, (state, action) => {
        state.getDesignedWorkflows.loading = false;
        state.getDesignedWorkflows.designedWorkflows = [];
        state.getDesignedWorkflows.error = action.payload;
      })
      .addCase("CLEAR_ALL", (state) => {
        state.filesave = { loading: false, fileSaveData: "", error: null };
        state.getDesignedWorkflows = {
          loading: false,
          designedWorkflows: [],
          error: null,
        };
      });
  },
});
export default authSlice.reducer;

