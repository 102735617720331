import * as React from "react";
import actor from "../../images/context/character.svg";
import script from "../../images/context/script.svg";
import Slate from "../../images/context/slate.svg";
import useConfigData from "../../service/configApi";
import {useLocation } from "react-router-dom";
import Costume from "../../images/context/costume.svg";
import Shoot_day from "../../images/context/shoot_day.svg";
import orchetrator_manager from "../../images/context/orchestration_manager_250.png";
import policy_enforcement from "../../images/context/policy_enforcement_point_250.png";
import CreativeWorkImg from "../../images/context/creative_work_fill.svg";
import LocationImg from "../../images/context/location.svg";
import MovingImg from "../../images/context/moving_image_1_250.png";
import set_250 from "../../images/context/set_250.png";
import shot_250 from "../../images/context/shot_250.png";
import slate_250 from "../../images/context/slate_250.png";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { useState, useRef, useEffect, useContext } from "react";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { clearAll, fileSave } from "../../redux/designerSlice";
import TemplateWindow from "./templateWindow";
import SaveDialog from "../Common/SaveDialog";
import {
  DiagramComponent,
  SymbolPaletteComponent,
  Node,
  DiagramTools,
  NodeConstraints,
  ConnectorConstraints,
  PrintAndExport,
  UndoRedo,
  Inject,
} from "@syncfusion/ej2-react-diagrams";
import { useHistory } from "react-router-dom";
import "./material.css";
import "./index.css";
import { ToolbarComponent } from "@syncfusion/ej2-react-navigations";
import { DropDownButton } from "@syncfusion/ej2-react-splitbuttons";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
const connectors = [];
//Initialize the flowshapes for the symbol palatte

const sample_css = `
#conTypeBtn{
  background-color: transparent;
  border-color: transparent;
}
#exportBtn{
  background-color: transparent;
  border-color: transparent;
}
#shapesBtn{
  background-color: transparent;
  border-color: transparent;
}
#alignBtn{
  background-color: transparent;
  border-color: transparent;
}
#distributeBtn{
  background-color: transparent;
  border-color: transparent;
}
#orderBtn{
  background-color: transparent;
  border-color: transparent;
}
#groupBtn{
  background-color: transparent;
  border-color: transparent;
}
#rotateBtn{
  background-color: transparent;
  border-color: transparent;
}
#flipBtn{
  background-color: transparent;
  border-color: transparent;
}
#btnZoomIncrement{
  background-color: transparent;
  border-color: transparent;
}
`;

let flowshapes = [
  { id: "Infrastructure", shape: { type: "Flow", shape: "Terminator" } },

  // { id: "Document", shape: { type: "Flow", shape: "Document" } },

  { id: "Context", shape: { type: "Basic", shape: "Ellipse" } },

  { id: "Asset", shape: { type: "Event", shape: "Task" } },
  {
    id: "Participant",
    shape: { type: "Flow", shape: "Preparation" },
  },
  {
    id: "Task",
    shape: {
      type: "Path",
      shape: "ManualOperation",
      data: "M1 3.45961C1 2.19098 2.16595 1.24291 3.40791 1.50165L23.4079 5.66831C24.3353 5.86153 25 6.67892 25 7.62628V18.3737C25 19.3211 24.3353 20.1385 23.4079 20.3317L3.40791 24.4984C2.16595 24.7571 1 23.809 1 22.5404V3.45961Z",
    },
  },
  // {
  //   id: "Task",
  //   shape: { type: "Image", source: TaskImg },
  //   style: {
  //     strokeWidth: 0,
  //   },
  // },
  // {
  //   id: "Task",
  //   shape: { type: "Flow", shape: "ManualOperation" },
  //   style: {
  //     transform: "rotate(90deg)",
  //   },
  // },
];
//Initializes connector symbols for the symbol palette
let connectorSymbols = [
  {
    id: "AngledFlow",
    type: "Orthogonal",
    sourcePoint: { x: 0, y: 0 },
    targetPoint: { x: 60, y: 60 },
    targetDecorator: {
      shape: "Arrow",
      style: { strokeColor: "#757575", fill: "#757575" },
    },
    style: { strokeWidth: 1, strokeColor: "#757575" },
  },
  {
    id: "Association",
    type: "Orthogonal",
    sourcePoint: { x: 0, y: 0 },
    targetPoint: { x: 60, y: 60 },

    targetDecorator: {
      shape: "Circle",
      style: { strokeColor: "#757575", fill: "#757575" },
    },
    sourceDecorator: {
      shape: "Circle",
      style: { strokeColor: "#757575", fill: "#757575" },
    },
    style: { strokeWidth: 1, strokeColor: "#757575" },
  },
  {
    id: "Flow",
    type: "Straight",
    sourcePoint: { x: 0, y: 0 },
    targetPoint: { x: 60, y: 60 },
    targetDecorator: {
      shape: "Arrow",
      style: { strokeColor: "#757575", fill: "#757575" },
    },
    style: { strokeWidth: 1, strokeColor: "#757575" },
  },
  {
    id: "Straight",
    type: "Straight",
    sourcePoint: { x: 0, y: 0 },
    targetPoint: { x: 60, y: 60 },
    style: { strokeWidth: 1, strokeColor: "#757575" },
    targetDecorator: { shape: "None" },
  },
  {
    id: "Callout",
    type: "Bezier",
    sourcePoint: { x: 0, y: 0 },
    targetPoint: { x: 60, y: 60 },
    style: { strokeWidth: 1, strokeColor: "#757575" },
    targetDecorator: { shape: "None" },
  },
];
let contextSymbols = [
  {
    id: "Character",
    shape: { type: "Image", source: actor },
    style: {
      strokeWidth: 0,
    },
  },
  {
    id: "Script",
    shape: { type: "Image", source: script },
    style: {
      strokeWidth: 0,
    },
  },
  {
    id: "CreativeWork",
    shape: { type: "Image", source: CreativeWorkImg },
    style: {
      strokeWidth: 0,
    },
  },
  {
    id: "Location",
    shape: { type: "Image", source: LocationImg },
    style: {
      strokeWidth: 0,
    },
  },

  {
    id: "Slate",
    shape: { type: "Image", source: Slate },
    style: {
      strokeWidth: 0,
    },
  },
  {
    id: "Costume",
    shape: { type: "Image", source: Costume },
    style: {
      strokeWidth: 0,
    },
  },

  {
    id: "ShootDay",
    shape: { type: "Image", source: Shoot_day },
    style: {
      strokeWidth: 0,
    },
  },
  {
    id: "SET250",
    shape: { type: "Image", source: set_250 },
    style: {
      strokeWidth: 0,
    },
  },
  {
    id: "Slat250",
    shape: { type: "Image", source: slate_250 },
    style: {
      strokeWidth: 0,
    },
  },
  {
    id: "Shot250",
    shape: { type: "Image", source: shot_250 },
    style: {
      strokeWidth: 0,
    },
  },
  {
    id: "OrchestratorManager",
    shape: { type: "Image", source: orchetrator_manager },
    style: {
      strokeWidth: 0,
    },
  },
  {
    id: "PolicyEnforcementPoint",
    shape: { type: "Image", source: policy_enforcement },
    style: {
      strokeWidth: 0,
    },
  },
];
let interval;
interval = [
  1, 9, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75,
  0.25, 9.75, 0.25, 9.75, 0.25, 9.75,
];
let gridlines = {
  lineColor: "#e0e0e0",
  lineIntervals: interval,
};
let diagramInstance;
let toolbarEditor;
function handlepan(){
}
function Default() {
  const { token } = useSelector((state) => state.auth);
  const { filesave } = useSelector((state) => state.design);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  //const [workFlowName, setWorkFlowName] = useState("");
  let workflowName = "";
  let toolbarItems = [
    {
      type: "Input",
      tooltipText: "File Name",
      template: `<text >${workflowName}</text>`,
      cssClass: "filenameText",
    },
    { prefixIcon: "e-icons e-circle-add", tooltipText: "New Diagram" },
    { prefixIcon: "e-icons e-folder-open", tooltipText: "Open Diagram" },
    { prefixIcon: "e-icons e-save", tooltipText: "Save Diagram" },
    { prefixIcon: "e-print e-icons", tooltipText: "Print Diagram" },
    {
      type: "Input",
      tooltipText: "Export Diagram",
      template: '<button id="exportBtn" style="width:100%;"></button>',
    },
    { type: "Separator" },
    {
      disabled: true,
      prefixIcon: "e-cut e-icons",
      tooltipText: "Cut",
      cssClass: "tb-item-middle tb-item-lock-category",
    },
    {
      disabled: true,
      prefixIcon: "e-copy e-icons",
      tooltipText: "Copy",
      cssClass: "tb-item-middle tb-item-lock-category",
    },
    { prefixIcon: "e-icons e-paste", tooltipText: "Paste", disabled: true },
    { type: "Separator" },
    { disabled: true, prefixIcon: "e-icons e-undo", tooltipText: "Undo" },
    { disabled: true, prefixIcon: "e-icons e-redo", tooltipText: "Redo" },
    { type: "Separator" },
    {
      prefixIcon: "e-pan e-icons",
      tooltipText: "Pan Tool",
      cssClass: "tb-item-start pan-item",
    },
    {
      prefixIcon: "e-mouse-pointer e-icons",
      tooltipText: "Select Tool",
      cssClass: "tb-item-middle tb-item-selected",
    },
    {
      tooltipText: "Change Connector Type",
      template: '<button id="conTypeBtn" style="width:100%;"></button>',
      cssClass: "tb-item-middle",
    },
    {
      tooltipText: "Draw Shapes",
      template: '<button id="shapesBtn" style="width:100%;"></button>',
      cssClass: "tb-item-middle",
    },
    {
      prefixIcon: "e-caption e-icons",
      tooltipText: "Text Tool",
      cssClass: "tb-item-end",
    },
    { type: "Separator" },
    {
      disabled: true,
      prefixIcon: "e-icons e-lock",
      tooltipText: "Lock",
      cssClass: "tb-item-middle tb-item-lock-category",
    },
    {
      disabled: false,
      prefixIcon: "e-trash e-icons",
      tooltipText: "Delete",
      cssClass: "tb-item-middle tb-item-lock-category",
    },
    { type: "Separator", align: "Center" },
    {
      disabled: true,
      type: "Input",
      tooltipText: "Align Objects",
      template: '<button id="alignBtn" style="width:100%;"></button>',
      cssClass: "tb-item-middle  tb-item-align-category",
    },
    {
      disabled: true,
      type: "Input",
      tooltipText: "Distribute Objects",
      template: '<button id="distributeBtn" style="width:100%;"></button>',
      cssClass: "tb-item-middle tb-item-space-category",
    },
    { type: "Separator" },
    {
      disabled: true,
      type: "Input",
      tooltipText: "Order Commands",
      template: '<button id="orderBtn" style="width:100%;"></button>',
      cssClass: "tb-item-middle tb-item-lock-category",
    },
    { type: "Separator" },
    {
      disabled: true,
      type: "Input",
      tooltipText: "Group/Ungroup",
      template: '<button id="groupBtn" style="width:100%;"></button>',
      cssClass: "tb-item-middle tb-item-align-category",
    },
    { type: "Separator" },
    {
      disabled: true,
      type: "Input",
      tooltipText: "Rotate",
      template: '<button id="rotateBtn" style="width:100%;"></button>',
      cssClass: "tb-item-middle tb-item-lock-category",
    },
    { type: "Separator" },
    {
      disabled: true,
      type: "Input",
      tooltipText: "Flip",
      template: '<button id="flipBtn" style="width:100%;"></button>',
      cssClass: "tb-item-middle tb-item-lock-category",
    },
    { type: "Separator" },
    {
      cssClass: "tb-item-end tb-zoom-dropdown-btn",
      template: '<button id="btnZoomIncrement"></button>',
    },
    { prefixIcon: "e-icons e-download", tooltipText: "Download JSON" },
  ];
  const { configCtx } = useConfigData();
  const {
    workflow,
    workflowId,
    configId,
    status,
    apiData,
    saveData,
    workflowData: workflowDetails,
    fileName,
    carbonScore,
  } = useContext(configCtx);
  useEffect(() => {
    if (!filesave.loading) {
      if (filesave?.fileSaveData) {
        enqueueSnackbar(filesave.fileSaveData?.message, { variant: "success" });
        dispatch(clearAll());
      }
      if (filesave?.error) {
        enqueueSnackbar(
          filesave?.error.message || "Workflow Could not be saved",
          { variant: "error" }
        );
        dispatch(clearAll());
      }
    }
  }, [filesave]);
  let toastObj;
  let position = { X: "Right" };
  let toastBtnHide;

  function onclose(e) {
    if (e.toastContainer.childElementCount === 0) {
      toastBtnHide.element.style.display = "none";
    }
  }

  const nodes = [];
  const workflowObj = useRef(null);
  let isMobile;
  function getPorts(obj) {
    let ports = [
      { id: "port1", shape: "Circle", offset: { x: 0, y: 0.5 } },
      { id: "port2", shape: "Circle", offset: { x: 0.5, y: 1 } },
      { id: "port3", shape: "Circle", offset: { x: 1, y: 0.5 } },
      { id: "port4", shape: "Circle", offset: { x: 0.5, y: 0 } },
    ];
    return ports;
  }

  function addEvents() {
    isMobile = window.matchMedia("(max-width:550px)").matches;
    if (isMobile) {
      let paletteIcon = document.getElementById("palette-icon");
      if (paletteIcon) {
        paletteIcon.addEventListener("click", openPalette, false);
      }
    }
  }
  function openPalette() {
    let paletteSpace = document.getElementById("palette-space");
    isMobile = window.matchMedia("(max-width:550px)").matches;
    if (isMobile) {
      if (!paletteSpace.classList.contains("sb-mobile-palette-open")) {
        paletteSpace.classList.add("sb-mobile-palette-open");
      } else {
        paletteSpace.classList.remove("sb-mobile-palette-open");
      }
    }
  }
  function printDiagram(args) {
    let options = {};
    options.mode = "Download";
    options.region = "Content";
    options.stretch = "Stretch";
    options.multiplePage = diagramInstance.pageSettings.multiplePage;
    options.pageHeight = diagramInstance.pageSettings.height;
    options.pageWidth = diagramInstance.pageSettings.width;
    diagramInstance.print(options);
  }
  function enableItems() {
    toolbarEditor.items[7].disabled = false;
    toolbarEditor.items[8].disabled = false;
    toolbarEditor.items[9].disabled = false;
    toolbarEditor.items[19].disabled = false;
    toolbarEditor.items[20].disabled = false;
    toolbarEditor.items[25].disabled = false;
    toolbarEditor.items[29].disabled = false;
    toolbarEditor.items[31].disabled = false;
  }
  function disableMultiselectedItems() {
    toolbarEditor.items[22].disabled = true;
    toolbarEditor.items[23].disabled = true;
    toolbarEditor.items[27].disabled = true;
  }
  const {userGroup } = useSelector((state) => state.auth);
 
  
  function toolbarClick(args) {
    setIsOverlayVisible(false);
    let item = args.item.tooltipText;
    switch (item) {
      case "Undo":
        diagramInstance.undo();
        break;
      case "Redo":
        diagramInstance.redo();
        break;
      case "Lock":
        lockObject(args);
        break;
      case "Cut":
        diagramInstance.cut();
        toolbarEditor.items[7].disabled = false;
        break;
      case "Copy":
        diagramInstance.copy();
        toolbarEditor.items[8].disabled = false;
        break;
      case "Paste":
        diagramInstance.paste();
        toolbarEditor.items[9].disabled = false;
        break;
      case "Delete":
        diagramInstance.remove();
        toolbarEditor.items[19].disabled = false;
        break;
      case "Select Tool":
        diagramInstance.clearSelection();
        diagramInstance.tool = DiagramTools.Default;
        break;
      case "Text Tool":
        diagramInstance.clearSelection();
        diagramInstance.selectedItems.userHandles = [];
        diagramInstance.drawingObject = { shape: { type: "Text" } };
        diagramInstance.tool = DiagramTools.ContinuousDraw;
        break;
      case "Pan Tool":
        diagramInstance.clearSelection();
        diagramInstance.tool = DiagramTools.ZoomPan;
        //handlepan();
        break;
      case "New Diagram":
        diagramInstance.clear();
        historyChange(args);
        break;
      case "Print Diagram":
        printDiagram(args);
        break;
      case "Save Diagram":
        download(diagramInstance.saveDiagram());
        break;
        case "Download JSON":
        downloadJSON(diagramInstance.saveDiagram());
        break;
      case "Open Diagram":
        showOverlay();

        break;
    }
    diagramInstance.dataBind();
  }
  function zoomChange(args) {
    let zoomCurrentValue =
      document.getElementById("btnZoomIncrement").ej2_instances[0];
    let currentZoom = diagramInstance.scrollSettings.currentZoom;
    let zoom = {};
    switch (args.item.text) {
      case "Zoom In":
        diagramInstance.zoomTo({ type: "ZoomIn", zoomFactor: 0.2 });
        zoomCurrentValue.content =
          (diagramInstance.scrollSettings.currentZoom * 100).toFixed() + "%";
        break;
      case "Zoom Out":
        diagramInstance.zoomTo({ type: "ZoomOut", zoomFactor: 0.2 });
        zoomCurrentValue.content =
          (diagramInstance.scrollSettings.currentZoom * 100).toFixed() + "%";
        break;
      case "Zoom to Fit":
        diagramInstance.fitToPage({ mode: "Page", region: "Content" });
        zoomCurrentValue.content = diagramInstance.scrollSettings.currentZoom;
        break;
      case "Zoom to 50%":
        if (currentZoom === 0.5) {
          currentZoom = 0;
          zoom.zoomFactor = 0.5 / currentZoom - 1;
          diagramInstance.zoomTo(zoom);
        } else {
          zoom.zoomFactor = 0.5 / currentZoom - 1;
          diagramInstance.zoomTo(zoom);
        }
        break;
      case "Zoom to 100%":
        if (currentZoom === 1) {
          currentZoom = 0;
          zoom.zoomFactor = 1 / currentZoom - 1;
          diagramInstance.zoomTo(zoom);
        } else {
          zoom.zoomFactor = 1 / currentZoom - 1;
          diagramInstance.zoomTo(zoom);
        }
        break;
      case "Zoom to 200%":
        if (currentZoom === 2) {
          currentZoom = 0;
          zoom.zoomFactor = 2 / currentZoom - 1;
          diagramInstance.zoomTo(zoom);
        } else {
          zoom.zoomFactor = 2 / currentZoom - 1;
          diagramInstance.zoomTo(zoom);
        }
        break;
      default:
        break;
    }
    zoomCurrentValue.content =
      Math.round(diagramInstance.scrollSettings.currentZoom * 100) + " %";
  }
  let asyncSettings;
  function onConnectorSelect(args) {
    diagramInstance.clearSelection();
    diagramInstance.drawingObject = { type: args.item.text };
    diagramInstance.tool = DiagramTools.ContinuousDraw;
    diagramInstance.selectedItems.userHandles = [];
    diagramInstance.dataBind();
  }
  function onShapesSelect(args) {
    diagramInstance.clearSelection();
    diagramInstance.drawingObject = { shape: { shape: args.item.text } };
    diagramInstance.tool = DiagramTools.ContinuousDraw;
    diagramInstance.selectedItems.userHandles = [];
    diagramInstance.dataBind();
  }

  //Export the diagraming object based on the format.
  function onselectExport(args) {
    let exportOptions = {};
    exportOptions.format = args.item.text;
    exportOptions.mode = "Download";
    exportOptions.region = "Content";
    exportOptions.fileName = "Export";
    exportOptions.margin = { left: 10, top: 10, bottom: 10, right: 10 };
    exportOptions.stretch = 'Stretch';
    diagramInstance.fitToPage({ mode: "Page", region: "Content" });
    diagramInstance.exportDiagram(exportOptions);
  }
  function onSelectGroup(args) {
    if (args.item.text === "Group") {
      diagramInstance.group();
    } else if (args.item.text === "Ungroup") {
      diagramInstance.unGroup();
    }
  }
  function onSelectAlignObjects(args) {
    let item = args.item.text;
    let alignType = item.replace("Align", "");
    let alignType1 = alignType.charAt(0).toUpperCase() + alignType.slice(1);
    diagramInstance.align(alignType1.trim());
  }
  function onSelectDistributeObjects(args) {
    if (args.item.text === "Distribute Objects Vertically") {
      diagramInstance.distribute("BottomToTop");
    } else {
      diagramInstance.distribute("RightToLeft");
    }
  }
  function onSelectOrder(args) {
    switch (args.item.text) {
      case "Bring Forward":
        diagramInstance.moveForward();
        break;
      case "Bring To Front":
        diagramInstance.bringToFront();
        break;
      case "Send Backward":
        diagramInstance.sendBackward();
        break;
      case "Send To Back":
        diagramInstance.sendToBack();
        break;
    }
  }
  function onSelectRotate(args) {
    if (args.item.text === "Rotate Clockwise") {
      diagramInstance.rotate(diagramInstance.selectedItems, 90);
    } else {
      diagramInstance.rotate(diagramInstance.selectedItems, -90);
    }
  }
  function onSelectFlip(args) {
    flipObjects(args.item.text);
  }
  // To flip diagram objects
  function flipObjects(flipType) {
    let selectedObjects = diagramInstance.selectedItems.nodes.concat(
      diagramInstance.selectedItems.connectors
    );
    for (let i = 0; i < selectedObjects.length; i++) {
      selectedObjects[i].flip =
        flipType === "Flip Horizontal" ? "Horizontal" : "Vertical";
    }
    diagramInstance.dataBind();
  }
  function validateWorkflow(data) {
    let isValid = true;
    const dataObject = JSON.parse(data).nodes;
    const dataConnectors = JSON.parse(data).connectors;

    // dataObject.map((node) => {
    //   if (node.annotations[0].content == "") {
    //     isValid = false;
    //     return;
    //   }
    // });
    dataConnectors.map((node) => {
      if (node.sourceID === "" || node.sourceID === "") {
        isValid = false;
        return;
      }
    });
    return isValid;
  }
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [validWorkflow, setValidWorkflow] = useState(false);

  function downloadJSON(data) {
    const isValid = validateWorkflow(data);
    
    if (isValid) {
        const dataString = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data));
        const ele = document.createElement('a');
        ele.href = dataString;
        ele.download = 'Diagram.json';
        document.body.appendChild(ele);
        ele.click();
        document.body.removeChild(ele);
    } else {
        enqueueSnackbar("Workflow is Not Valid!", {
            variant: "error",
        });
    }
}
function download(data) {
    
    const isValid = validateWorkflow(data);
    if (isValid) {
      setOpenSaveDialog(true);
      setValidWorkflow(data);
    } else {
      enqueueSnackbar("Workflow is Not Valid!", {
        variant: "error",
      });
    }
  }

  function onSave(name, desc) {
    setOpenSaveDialog(false);
    if (name) {
      const fileName = name;
      dispatch(
        fileSave({ workflow: validWorkflow, fileName: fileName, desc: desc })
      );
    }
  }
  function onClose() {
    setOpenSaveDialog(false);
  }

  const history = useHistory();

  let selectedId;
  let selectedworkflow;

  const selectWorkflow = async (args) => {
    hideOverlay();
    //setWorkFlowName(args.text);
    //fileName = args.data?.name;

    // openDiagram();
    history.push(`/designer?open=${args.data?._id}`);
    // selectedworkflow = args.data?.workflowJson;
    // selectedId = args.data?._id;
    // loadDiagram1(args.data?.workflowJson);
    // diagramInstance.fitToPage();
  };
  function lockObject(args) {
    for (let i = 0; i < diagramInstance.selectedItems.nodes.length; i++) {
      let node = diagramInstance.selectedItems.nodes[i];
      if (node.constraints & NodeConstraints.Drag) {
        node.constraints =
          NodeConstraints.PointerEvents | NodeConstraints.Select;
      } else {
        node.constraints = NodeConstraints.Default;
      }
    }
    for (let j = 0; j < diagramInstance.selectedItems.connectors.length; j++) {
      let connector = diagramInstance.selectedItems.connectors[j];
      if (connector.constraints & ConnectorConstraints.Drag) {
        connector.constraints =
          ConnectorConstraints.PointerEvents | ConnectorConstraints.Select;
      } else {
        connector.constraints = ConnectorConstraints.Default;
      }
    }
    diagramInstance.dataBind();
  }

  const callHistory = () => {
    history.push({
      pathname: "/configure",
      state: { data: selectedworkflow, id: selectedId }, // your data array of objects
    });
  };
  function refreshOverflow() {
    setTimeout(() => {
      toolbarEditor.refreshOverflow();
    }, 100);
  }
  asyncSettings = {
    saveUrl: "https://ej2.syncfusion.com/services/api/uploadbox/Save",
    removeUrl: "https://ej2.syncfusion.com/services/api/uploadbox/Remove",
  };
  function onUploadSuccess(args) {
    let file = args.file;
    let rawFile = file.rawFile;
    let reader = new FileReader();
    reader.readAsText(rawFile);
    reader.onloadend = loadDiagram;
  }
  function loadDiagram(event) {
    diagramInstance.loadDiagram(event.target.result);
  }
  function loadDiagram1(event) {
    diagramInstance.loadDiagram(event);
  }

  const [isOverlayVisible, setIsOverlayVisible] = useState(true);
  const location = useLocation();
  const searchParams = location ? new URLSearchParams(location.search) : {};
  const id = searchParams.get("open");
  console.log(id);
  const [apiUrl, setApiUrl] = useState(process.env?.REACT_APP_API_URL);
  React.useEffect(() => {
    console.log("I am here");
    getWorkflow();
  }, []);
  React.useEffect(() => {
    if (id) {
      openDiagram(id);
      hideOverlay();
    }
  }, [id]);
  const [workflowData, setWorkflowData] = useState([]);

  const getWorkflow = async () => {
    const filedata = await fetch(`${apiUrl}/api/getWorkflows/${userGroup}`, {
      method: "get",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }).catch((error) => {
      setWorkflowData([]);
      return "No Resonse from API!";
    });
    try {
      if (filedata.status === 200) {
        const response1 = await filedata.json();
        setWorkflowData(response1.data);
        enqueueSnackbar("Workflows Loaded Successfully!", {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar("Unable to load the Workflows!", { variant: "success" });
      return "No Resonse from API!";
    }
  };
  //open Diagram
  const openDiagram = async (id) => {
    const filedata = await fetch(`${apiUrl}/api/getFile/${id}`, {
      method: "get",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    });
    try {
      if (filedata.status === 200) {
        const response1 = await filedata.json();
        loadDiagram1(response1.data.workflowJson);
        workflowName = response1.data.name;
        // setWorkFlowName(response1.data.name);
        diagramInstance.fitToPage({ mode: "Page", region: "Content" });
        // if (response1?.data?.workflowData) {
        //   saveData(
        //     response1.data.workflowJson,
        //     id,
        //     id,
        //     "",
        //     "",
        //     response1.data?.workflowData,
        //     response1.data?.name,
        //     response1.data?.carbonScore ? response1.data?.carbonScore : {}
        //   );
        // } else {
        //   saveData(
        //     response1.data.workflowJson,
        //     response1.data._id,
        //     "",
        //     "",
        //     "",
        //     "",
        //     response1.data?.name,
        //     response1.data?.carbonScore ? response1.data?.carbonScore : {}
        //   );
        // }
      }
    } catch (error) {
      return "No Resonse from API!";
    }
  };
  function historyChange(args) {
    //console.log("change,undoredo ", diagramInstance);
    if (diagramInstance.historyManager.undoStack.length > 0) {
      toolbarEditor.items[11].disabled = false;
    } else {
      toolbarEditor.items[11].disabled = true;
    }
    if (diagramInstance.historyManager.redoStack.length > 0) {
      toolbarEditor.items[12].disabled = false;
    } else {
      toolbarEditor.items[12].disabled = true;
    }
  }

  const workflowFields = { text: "name", value: "_id" };
  const [workflowText] = useState(null);
  const [workflowQuery] = useState(null);

  const showOverlay = () => {
    setIsOverlayVisible(true);
  };
  const hideOverlay = () => {
    setIsOverlayVisible(false);
  };
  const handleOverlayClick = (event) => {
    // Close the overlay if the click is outside the overlay
    if (event.target.id === "overlay") {
      hideOverlay();
    }
  };
  return (
    <div className="control-pane1">
      <div>
        {isOverlayVisible && workflowData.length > 0 && (
          <div id="overlay" className="overlay" onClick={handleOverlayClick}>
            <div>
              {/* Your template overlay content goes here */}
              <TemplateWindow
                onClose={hideOverlay}
                workflowData={workflowData}
                workflowObj={workflowObj}
                workflowFields={workflowFields}
                workflowText={workflowText}
                workflowQuery={workflowQuery}
                selectWorkflow={selectWorkflow}
                newDiagram={toolbarClick}
              />
            </div>
          </div>
        )}
      </div>
      <div>
        {openSaveDialog && (
          <div id="overlay" className="overlay" onClick={handleOverlayClick}>
            <div className="dialogContainer">
              {/* Your save dialog overlay  goes here */}
              <SaveDialog
                title="Save Your Workflow"
                header="Workflow Name"
                onSave={onSave}
                onClose={onClose}
              />
            </div>
          </div>
        )}
      </div>
      <ToastComponent
        ref={(toast) => {
          toastObj = toast;
        }}
        id="toast_default"
        position={position}
        close={onclose.bind(this)}
      ></ToastComponent>

      <style>{sample_css}</style>

      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
        <div className="db-toolbar-container">
          <ToolbarComponent
            ref={(toolbar) => (toolbarEditor = toolbar)}
            id="toolbar_diagram"
            created={() => {
              if (diagramInstance !== undefined) {
                let conTypeBtn = new DropDownButton({
                  items: [
                    { text: "Straight", iconCss: "e-icons e-line" },
                    {
                      text: "Orthogonal",
                      iconCss: "sf-diagram-icon-orthogonal",
                    },
                    { text: "Bezier", iconCss: "sf-diagram-icon-bezier" },
                  ],
                  iconCss: "e-diagram-icons1 e-diagram-connector e-icons",
                  select: function (args) {
                    onConnectorSelect(args);
                  },
                });
                conTypeBtn.appendTo("#conTypeBtn");
                let btnZoomIncrement = new DropDownButton({
                  items: [
                    { text: "Zoom In" },
                    { text: "Zoom Out" },
                    { text: "Zoom to Fit" },
                    { text: "Zoom to 50%" },
                    { text: "Zoom to 100%" },
                    { text: "Zoom to 200%" },
                  ],
                  content:
                    Math.round(
                      diagramInstance.scrollSettings.currentZoom * 100
                    ) + " %",
                  select: zoomChange,
                });
                btnZoomIncrement.appendTo("#btnZoomIncrement");
                let shapesBtn = new DropDownButton({
                  items: [
                    { text: "Rectangle", iconCss: "e-rectangle e-icons" },
                    { text: "Ellipse", iconCss: " e-circle e-icons" },
                    { text: "Polygon", iconCss: "e-line e-icons" },
                  ],
                  iconCss: "e-shapes e-icons",
                  select: function (args) {
                    onShapesSelect(args);
                  },
                });
                shapesBtn.appendTo("#shapesBtn");
                let exportBtn = new DropDownButton({
                  items: [{ text: "JPG" }, { text: "PNG" }, { text: "SVG" }],
                  iconCss: "e-icons e-export",
                  select: function (args) {
                    onselectExport(args);
                  },
                });
                exportBtn.appendTo("#exportBtn");
                let groupBtn = new DropDownButton({
                  items: [
                    { text: "Group", iconCss: "e-icons e-group-1" },
                    { text: "Ungroup", iconCss: "e-icons e-ungroup-1" },
                  ],
                  iconCss: "e-icons e-group-1",
                  select: function (args) {
                    onSelectGroup(args);
                  },
                });
                groupBtn.appendTo("#groupBtn");
                let alignBtn = new DropDownButton({
                  items: [
                    {
                      iconCss: "sf-diagram-icon-align-left-1",
                      text: "Align Left",
                    },
                    {
                      iconCss: "sf-diagram-icon-align-center-1",
                      text: "Align Center",
                    },
                    {
                      iconCss: "sf-diagram-icon-align-right-1",
                      text: "Align Ri ght",
                    },
                    {
                      iconCss: "sf-diagram-icon-align-top-1",
                      text: "Align Top",
                    },
                    {
                      iconCss: "sf-diagram-icon-align-middle-1",
                      text: "Align Middle",
                    },
                    {
                      iconCss: "sf-diagram-icon-align-bottom-1",
                      text: "Align Bottom",
                    },
                  ],
                  iconCss: "e-icons e-restart-at-1",
                  select: function (args) {
                    onSelectAlignObjects(args);
                  },
                });
                alignBtn.appendTo("#alignBtn");
                let distributeBtn = new DropDownButton({
                  items: [
                    {
                      iconCss: "sf-diagram-icon-distribute-horizontal",
                      text: "Distribute Objects Vertically",
                    },
                    {
                      iconCss: "sf-diagram-icon-distribute-vertical",
                      text: "Distribute Objects Horizontally",
                    },
                  ],
                  iconCss: "e-icons e-stroke-width",
                  select: function (args) {
                    onSelectDistributeObjects(args);
                  },
                });
                distributeBtn.appendTo("#distributeBtn");
                let orderBtn = new DropDownButton({
                  items: [
                    {
                      iconCss: "e-icons e-bring-forward",
                      text: "Bring Forward",
                    },
                    {
                      iconCss: "e-icons e-bring-to-front",
                      text: "Bring To Front",
                    },
                    {
                      iconCss: "e-icons e-send-backward",
                      text: "Send Backward",
                    },
                    {
                      iconCss: "e-icons e-send-to-back",
                      text: "Send To Back",
                    },
                  ],
                  iconCss: "e-icons e-order",
                  select: function (args) {
                    onSelectOrder(args);
                  },
                });
                orderBtn.appendTo("#orderBtn");
                let rotateBtn = new DropDownButton({
                  items: [
                    {
                      iconCss: "e-icons e-transform-right",
                      text: "Rotate Clockwise",
                    },
                    {
                      iconCss: "e-icons e-transform-left",
                      text: "Rotate Counter-Clockwise",
                    },
                  ],
                  iconCss: "e-icons e-repeat",
                  select: function (args) {
                    onSelectRotate(args);
                  },
                });
                rotateBtn.appendTo("#rotateBtn");
                let flipBtn = new DropDownButton({
                  items: [
                    {
                      iconCss: "e-icons e-flip-horizontal",
                      text: "Flip Horizontal",
                    },
                    {
                      iconCss: "e-icons e-flip-vertical",
                      text: "Flip Vertical",
                    },
                  ],
                  iconCss: "e-icons e-flip-horizontal",
                  select: function (args) {
                    onSelectFlip(args);
                  },
                });
                flipBtn.appendTo("#flipBtn");
                refreshOverflow();
              }
            }}
            clicked={toolbarClick}
            items={toolbarItems}
            overflowMode={"Scrollable"}
            width={"100%"}
          ></ToolbarComponent>
        </div>
        <div className="sb-mobile-palette-bar">
          <div
            id="palette-icon"
            style={{ float: "right" }}
            className="e-ddb-icons1 e-toggle-palette"
          ></div>
        </div>
        <div id="palette-space" className="sb-mobile-palette-designer ">
          <SymbolPaletteComponent
            id="symbolpalette"
            expandMode="Multiple"
            palettes={[
              {
                id: "flow",
                expanded: true,
                symbols: flowshapes,
                iconCss: "e-diagram-icons1 e-diagram-flow",
                title: "MovieLabs Shapes",
              },
              {
                id: "connectors",
                expanded: true,
                symbols: connectorSymbols,
                iconCss: "e-diagram-icons1 e-diagram-connector",
                title: "Connectors",
              },
              {
                id: "contextIcons",
                expanded: true,
                symbols: contextSymbols,
                iconCss: "e-diagram-icons1 e-diagram-flow ",
                title: "Icons",
              },
            ]}
            width={"100%"}
            symbolHeight={60}
            symbolWidth={60}
            getNodeDefaults={(symbol) => {
              if (
                symbol.id === "Infrastructure" ||
                symbol.id === "Process" ||
                symbol.id === "Delay"
              ) {
                symbol.width = 70;
                symbol.height = 40;
              } else if (
                symbol.id === "Decision" ||
                symbol.id === "Document" ||
                symbol.id === "PreDefinedProcess" ||
                symbol.id === "PaperTap" ||
                symbol.id === "DirectData" ||
                symbol.id === "MultiDocument" ||
                symbol.id === "Data"
              ) {
                symbol.width = 50;
                symbol.height = 40;
              } else {
                symbol.width = 60;
                symbol.height = 60;
              }
              symbol.style.strokeColor = "#757575";
            }}
            symbolMargin={{ left: 15, right: 15, top: 15, bottom: 15 }}
            getSymbolInfo={(symbol) => {
              return { fit: true };
            }}
          />
        </div>
        <div id="diagram-space" className="sb-mobile-diagram-designer ">
          <DiagramComponent
            id="diagram"
            ref={(diagram) => (diagramInstance = diagram)}
            width={"100%"}
            height={"610px"}
            created={() => {
              diagramInstance.fitToPage({
                mode: "Page",
                region: "Content",
                margin: {
                  bottom: 10,
                },
                canZoomIn: true,
                width: "100%",
                height: "100%",
              });
            }}
            snapSettings={{
              horizontalGridlines: gridlines,
              verticalGridlines: gridlines,
            }}
            nodes={nodes}
            connectors={connectors} //Sets the default values of a node
            pageSettings={{
              // Sets the PageOrientation for the diagram to page
              orientation: "Landscape",
              width: 1050,
              height: 600,
            }}
            getNodeDefaults={(node) => {
              let obj = {};
              if (obj.width === undefined) {
                obj.width = 145;
              } else {
                let ratio = 100 / obj.width;
                obj.width = 100;
                obj.height *= ratio;
              }
              obj.style = { fill: "#357BD2", strokeColor: "black" };
              obj.annotations = [
                { style: { color: "black", fill: "transparent" } },
              ];
              //Set ports
              obj.ports = getPorts(node);
              return obj;
            }} //Sets the default values of a connector
            getConnectorDefaults={(obj) => {
              if (obj.id.indexOf("connector") !== -1) {
                obj.targetDecorator = {
                  shape: "Arrow",
                  width: 10,
                  height: 10,
                };
              }
            }}
            scrollChange={(args) => {
              if (args.panState !== "Start") {
                let zoomCurrentValue =
                  document.getElementById("btnZoomIncrement").ej2_instances[0];
                zoomCurrentValue.content =
                  Math.round(diagramInstance.scrollSettings.currentZoom * 100) +
                  " %";
              }
            }}
            historyChange={(args) => {
              historyChange(args);
            }}
            selectionChange={(args) => {
              if (args.state === "Changed") {
                let selectedItems = diagramInstance.selectedItems.nodes;
                selectedItems = selectedItems.concat(
                  diagramInstance.selectedItems.connectors
                );
                if (selectedItems.length === 0) {
                  toolbarEditor.items[7].disabled = true;
                  toolbarEditor.items[8].disabled = true;
                  toolbarEditor.items[9].disabled = false;
                  toolbarEditor.items[19].disabled = true;
                  toolbarEditor.items[20].disabled = true;
                  toolbarEditor.items[25].disabled = true;
                  toolbarEditor.items[29].disabled = true;
                  toolbarEditor.items[31].disabled = true;
                  disableMultiselectedItems();
                }
                if (selectedItems.length === 1) {
                  enableItems();
                  disableMultiselectedItems();
                  if (
                    selectedItems[0].children !== undefined &&
                    selectedItems[0].children.length > 0
                  ) {
                    toolbarEditor.items[27].disabled = false;
                  } else {
                    toolbarEditor.items[27].disabled = true;
                  }
                }
                if (selectedItems.length > 1) {
                  enableItems();
                  toolbarEditor.items[22].disabled = false;
                  toolbarEditor.items[23].disabled = false;
                  toolbarEditor.items[27].disabled = false;
                  if (selectedItems.length > 2) {
                    toolbarEditor.items[23].disabled = false;
                  } else {
                    toolbarEditor.items[23].disabled = true;
                  }
                }
              }
            }}
            //Sets the Node style for DragEnter element.
            dragEnter={(args) => {
              let obj = args.element;
              if (obj instanceof Node) {
                let oWidth = obj.width;
                let oHeight = obj.height;
                let ratio = 100 / obj.width;
                obj.width = 100;
                obj.height *= ratio;
                obj.offsetX += (obj.width - oWidth) / 2;
                obj.offsetY += (obj.height - oHeight) / 2;
                obj.style = { fill: "white", strokeColor: "black" };
              }
            }}
          >
            <Inject services={[PrintAndExport, UndoRedo]} />
          </DiagramComponent>
        </div>
      </div>
    </div>
  );
}
export default Default;
