import { Switch, Route, BrowserRouter } from "react-router-dom";
import React, { Suspense } from "react";
import "./App.css";
import Protected from "./components/Protected";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import Default from "./components/Designer";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import Home from "./components/Home";
import DomainStatus from "./components/DomainStatus";
import ConfigureWindow from "./components/Configure";
import Orchestrator from "./components/Orchestrator";
import MonitorPreview from "./components/MonitorPreview";
import MonitorApproval from "./components/MonitorApproval";
import Service from "./components/Service";
import Dashboard from "./components/Dashboard";
import AccountSettings from "./components/AccountSetting/menuPanel";
import { useSelector } from "react-redux";

// import WorkAllocation from "./components/WorkAllocation";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { login as loginAuth, clearErrors } from "./redux/authSlice";
import { useDispatch } from "react-redux";
const WorkAllocation = React.lazy(() => import("./components/WorkAllocation"));
const App = () => {
  const location = window.location;
  const searchParams = location ? new URLSearchParams(location?.search) : {};
  const { user, priviliges, loading } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  // Retrieve user details from query parameters
  const loginUser = async (email, password) => {
    await dispatch(loginAuth({ email: email, password: password }));
  };
  useEffect(() => {
    if (!loading) {
      if (user == null) {
        const userId = searchParams.get("userId");
        const pass = searchParams.get("pass");
        if (userId && pass) {
          loginUser(userId, pass);
        }
      }
    }
  }, [user]);

  return (
    <div className="container1">
      <BrowserRouter>
        <div className="header">
          <Navigation />
        </div>
        <Switch>
          <div className="content">
            <Route user={user} exact path="/">
              <Home />
              <Footer />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/signup">
              <SignUp />
            </Route>
            <Route path="/account_settings">
              <AccountSettings />
              <Footer />
            </Route>
            <Protected
              privileges={priviliges}
              navigateTo="Dashboard"
              user={user}
              path="/dashboard"
            >
              <Dashboard />
              <Footer />
            </Protected>

            <Protected
              user={user}
              privileges={priviliges}
              navigateTo="Designer"
              exact
              path="/designer/:id"
            >
              <Default />
            </Protected>
            <Protected
              user={user}
              privileges={priviliges}
              navigateTo="Designer"
              exact
              path="/designer"
            >
              <Default />
            </Protected>
            <Protected
              user={user}
              privileges={priviliges}
              navigateTo="Monitor Status"
              path="/domain"
            >
              <DomainStatus dashDomain={true} />
            </Protected>

            <Protected
              user={user}
              privileges={priviliges}
              navigateTo="Orchestrator"
              path="/orchestrator"
            >
              <Orchestrator />
            </Protected>
            <Protected
              user={user}
              privileges={priviliges}
              navigateTo="Monitor Prview"
              path="/monitorpreview/:id"
            >
              <MonitorPreview />
            </Protected>
            <Protected
              user={user}
              privileges={priviliges}
              navigateTo="Human Task"
              path="/monitorapproval"
            >
              <MonitorApproval />
            </Protected>
            <Protected
              user={user}
              privileges={priviliges}
              navigateTo="Configurator"
              path="/configure"
            >
              <ConfigureWindow />
            </Protected>
            <Protected
              user={user}
              privileges={priviliges}
              navigateTo="Service Settings"
              path="/serviceConfigure"
            >
              <Service />
            </Protected>
            <Protected
              user={user}
              privileges={priviliges}
              navigateTo="Work Allocation"
              path="/workAllocation"
            >
              <Suspense fallback={<div>Loading...</div>}>
                <WorkAllocation create={true} />
              </Suspense>

              <Footer />
            </Protected>
          </div>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
