import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createGuest } from "../../redux/guestContact";
import { useSnackbar } from "notistack";
import Banner from "../Common/Banner";
import Card from "../Common/Card";
import EntTech from "../../images/Logos/EntTech.png";
import Fxdmz from "../../images/Logos/fxdmz_logo.png";
import Prodo from "../../images/Logos/BetaLogo.png";
import DesignWorkflow from "../../images/Logos/DesignWorkflow.png";
import WorkflowConfig from "../../images/Logos/WorkflowConfig.png";
import WorkflowOrch from "../../images/Logos/WorkflowOrch.png";
import Autonomy from "../../images/Logos/MacBookAir13.png";
import Carlos from "../../images/Logos/carlos.png";
import Aditya from "../../images/Logos/aditya.png";
import Name from "../../images/Logos/Name.png";
import "./index.css";

const Home = () => {
  const dispatch = useDispatch();
  const [guestName, setGuestName] = useState("");
  const [guestEmail, setGuestEmail] = useState("");
  const [guestCompany, setGuestCompany] = useState("");
  const [guestPhone, setGuestPhone] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const emailInputRef = useRef(null);
  const phoneInputRef = useRef(null);
  const [phoneValid, setPhoneValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  //page scroll to contact form
  const ContactDemoRef = useRef(null);

  const handleContactDemo = () => {
    ContactDemoRef.current.scrollIntoView({ behavior: "smooth" });
  };

  //On contact form input:
  const onGuestNameChange = (e) => {
    setGuestName(e.target.value);
  };
  const onGuestEmailChange = (e) => {
    const email = e.target.value;
    setGuestEmail(email);
  };
  const onGuestCompanyChange = (e) => {
    setGuestCompany(e.target.value);
  };
  const onGuestPhoneChange = (e) => {
    setGuestPhone(e.target.value);
    validPhoneNumber(e.target.value);
  };

  const clearForm = () => {
    setGuestName("");
    setGuestEmail("");
    setGuestCompany("");
    setGuestPhone("");
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();

    if (submitting) return;
    try {
      setSubmitting(true);
      const isFormValid = validateForm();
      if (!isFormValid) {
        return;
      }
      if (isEmailValid) {
        enqueueSnackbar("Invalid Email!", {
          variant: "error",
        });
        return;
      }

      const response = await dispatch(
        createGuest({ guestName, guestEmail, guestCompany, guestPhone })
      );
      if (!response.error) {
        clearForm();

        enqueueSnackbar(response.payload.message, {
          variant: "success",
        });
      } else {
        enqueueSnackbar(response.payload.message, {
          variant: "error",
        });
      }
    } catch {
      enqueueSnackbar("An unknown error occurred", {
        variant: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (guestPhone && !validPhoneNumber(guestPhone)) {
      enqueueSnackbar("Valid format: +19999999999!", {
        variant: "error",
      });
      phoneInputRef.current.focus();
    }
  }, [guestPhone]);

  const validateForm = () => {
    let isValid = true;
    if (!guestPhone || !validPhoneNumber(guestPhone)) {
      enqueueSnackbar("Invalid phone number!", {
        variant: "error",
      });
      if (phoneInputRef.current) {
        phoneInputRef.current.focus();
      }
      isValid = false;
    }

    return isValid;
  };

  const ValidateEmail = () => {
    if (!validEmailId(guestEmail)) {
      setEmailError("Invalid email Address!");
      setIsEmailValid(false);
    } else {
      setEmailError("");
      setIsEmailValid(true);
    }
  };
  //email validation:
  const validEmailId = (email) => {
    const regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEmail.test(String(email).toLowerCase());
  };
  // phone number validation with country code:
  const validPhoneNumber = (phone) => {
    const regPhone = /^\+\d{1,3}\d{4,14}(?:x\d{1,5})?$/;
    return regPhone.test(phone);
  };

  const LogoCard = ({ data, classColor }) => {
    return (
      <div className={`card-logo-container ${classColor}`}>
        {data.map((item, index) => (
          <div className="company-logo" key={index}>
            <img src={item.image} alt={`Image ${index}`} />
            <p>
              {item.description.text}{" "}
              {item.description.link && (
                <a
                  href={item.description.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.description.link}
                </a>
              )}
            </p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="containerDesigner">
      <Banner />
      <div className="maintext">
        <p1>
          MediaMesh is brought to you by Entertainment Technologists in
          partnership with FX-DMZ and Prodo.
        </p1>
        <p2>
          MediaMesh is a free tool provided to the Media and Entertainment
          industry to build and explore complex media workflows using the
          MovieLabs Visual Language.
        </p2>
      </div>
      <div className="home-workflows">
        <Card
          heading="Designer Workflow"
          data={[
            {
              image: DesignWorkflow,
              description:
                "Design, save, load , share and edit media workflows using the MovieLabs Visual Language for Media Creation. Build your own workflows or start from our preconfigured ones.",
            },
          ]}
          classColor="card red"
        />
        <Card
          heading="Workflow Configurator"
          data={[
            {
              image: WorkflowConfig,
              description:
                "Configure each workflow task with information including the tools, vendors, participants and infrastructure to be used so your workflow can be run",
            },
          ]}
          classColor="card green"
        />
        <Card
          heading="Workflow Orchestrator"
          data={[
            {
              image: WorkflowOrch,
              description:
                "Run your workflows with full automation and monitor status of jobs",
            },
          ]}
          classColor="card purple"
        />
      </div>

      <div className="simplicity-image">
        {/* <img src={Simplicity} alt="simplicity" /> */}
        <div className="simplicity-image-frame">
          <text id="H1">Streamlined Workflow Deployment</text>
          <text id="H2">Deploy Complex Workflows in Clicks</text>
          <text id="H3">
            MediaMesh revolutionizes workflow efficiency, enabling rapid and
            seamless setup and deployment tailored for the dynamic media
            industry. With intuitive design and powerful automation, it
            dramatically reduces the complexity and time needed to launch new
            workflows, meeting the urgent need for agility and quick adaptation
            to creative demands. Embrace the future of media production with
            MediaMesh, where innovation accelerates your creative potential.
          </text>
        </div>
      </div>

      <div className="autonomy-image">
        <div className="autonomy-mac-div">
          <img className="autonomy-mac" src={Autonomy} alt="autonomy" />
        </div>
        <div className="autonomy-frame">
          <div className="autonomy-frame1">
            <text id="at1">Autonomy in Operations</text>
            <text id="at2">Empower Your Team, Reduce Dependency</text>
            <text id="at3">
              Make Things Happen, Effortlessly: With MediaMesh, say goodbye to
              the endless back-and-forth and hello to smooth sailing. Our
              automation tools are like having a superpower – they do the heavy
              lifting, so your team can focus on the cool stuff. Less
              micromanaging, more creating. Get ready to boost your efficiency
              and give your team the freedom to shine!
            </text>
          </div>
          <div className="autonomy-frame1">
            <text id="at1">Unified Workflow view</text>
            <text id="at2">All Your Workflows, One Dashboard</text>
            <text id="at3">
              Step into the world of MediaMesh and discover a dashboard that
              brings everything into focus. Get a crisp, clear view of your
              workflows, tools, and all the moving parts in between. It’s about
              having the insights you need, right when you need them, to steer
              your projects with precision. Simplify the complex and make
              informed decisions with ease. Welcome to your workflow, elevated
              and illuminated.
            </text>
          </div>
        </div>
      </div>

      {/* <div className="stories-container">
        <div className="stories-frame1">
          <text>Success Stories: Innovation in Action</text>
        </div>
        <div className="workflows-frame">
          <div className="designer1">
            <img src={Carlos} alt="carlos profile pic" />
            <div className="designer1-profile">
              <text id="d1">Carlos</text>
              <text id="d2">CTO From distribution Platform</text>
            </div>
            <text id="d3">
              "Implementing MediaMesh transformed our workflow efficiency from
              day one. Its intuitive design and powerful automation have enabled
              us to scale our operations and respond to market demands with
              unprecedented speed. A game-changer for our team and our clients
              alike."
            </text>
          </div>
          <div className="designer1">
            <img src={Aditya} alt="Aditya profile pic" />
            <div className="designer1-profile">
              <text id="d1">Adytia</text>
              <text id="d2">Media Owner from production house</text>
            </div>
            <text id="d3">
              "MediaMesh has been a revelation for us, blending seamlessly into
              our creative processes. Its adaptability and innovative approach
              have not only streamlined our workflows but also inspired our team
              to push the boundaries of storytelling. Truly indispensable."
            </text>
          </div>
          <div className="designer1">
            <img src={Name} alt="Name profile pic" />
            <div className="designer1-profile">
              <text id="d1">Name</text>
              <text id="d2">ad agency owner</text>
            </div>
            <text id="d3">
              "MediaMesh has revolutionized the way we approach our campaigns,
              providing us with the agility to adapt and thrive in the
              fast-paced advertising world. Its efficiency and the ease of use
              have empowered our creatives and strategists alike, making it our
              go-to for workflow management."
            </text>
          </div>
        </div>
      </div> */}

      <div className="teamwork-container">
        <div className="teamwork-frame">
          <text id="tm1">Teamwork, Supercharged by MediaMesh</text>
          <text id="tm2">Effortless Collaboration Across Your Projects</text>
          <text id="tm3">
            MediaMesh simplifies teamwork: Instant Updates: Real-time syncing
            keeps everyone on the same page. One Place for All Conversations:
            Integrated chats and comments cut through the clutter. Custom
            Access: Roles and permissions for seamless project management.
            Elevate Your Team's Potential Empower your team with tools that
            foster creativity and streamline workflows. Discover the difference
            MediaMesh can make.
          </text>
          <button className="teamwork-button" onClick={handleContactDemo}>
            <div className="teamwork-button-frame">
              <text>See it in Action. Book a Demo!</text>
            </div>
          </button>
        </div>{" "}
      </div>

      <div className="sustanibility-container">
        <div className="sustanibility-frame">
          <text id="st1">Go Green with MediaMesh</text>
          <text id="st2">Track, Choose, Act: Sustainability at Every Step</text>
          <text id="st3">
            <li>
              <b>Orchestrator's Eco-Tool:</b> Get carbon footprint data for
              workflow steps, guiding eco-friendly decisions.
            </li>
            <li>
              <b>Green Provider Choices:</b> Empower orchestrators to prefer
              environmentally considerate providers.{" "}
            </li>
            <li>
              <b>Embrace Eco-Responsibility:</b>
              Your workflows, now with a sustainability focus.{" "}
            </li>
            <br />
            <b>
              Shape a Sustainable Future in Media Production MediaMesh:
            </b>{" "}
            Where every decision can contribute to a greener planet.
          </text>
          <button className="sustanibility-button" onClick={handleContactDemo}>
            <div className="sustanibility-button-frame">
              <text>Get in touch!</text>
            </div>
          </button>
        </div>
      </div>
      <div className="elevate-container" ref={ContactDemoRef}>
        <div className="elevate-frame1">
          <text>Elevate your Media Workflows with MediaMesh</text>
        </div>
        <div className="elevate-workflows-frame">
          <div className="elevate-workflows-designer1">
            <text>
              <li>
                {" "}
                <b>Advanced Automation:</b> Simplify operations with our
                cutting-edge automation technology.{" "}
              </li>
              <li>
                <b>User-Friendly Design Tools: </b>Effortlessly craft and adjust
                workflows to meet your needs.{" "}
              </li>
              <li>
                <b>Comprehensive Dashboard:</b> Gain insights with a panoramic
                view of all workflows, tools, and processes.
              </li>{" "}
              <li>
                <b>Boosted Efficiency & Creativity:</b> Propel your team's
                productivity and creative output to new heights.
              </li>{" "}
              <li>
                <b>eamless Collaboration:</b>S Foster effective teamwork with
                our collaborative platform.{" "}
              </li>
              <li>
                <b>Sustainability Insight:</b> Measure the carbon footprint of
                your workflows to make environmentally conscious decisions.
              </li>
              <li>
                <b>Future-Forward Technology:</b> Prepare your team with tools
                that not only meet today's demands but also tomorrow's
                challenges.
              </li>
              <br />
              Transform your media production into a model of efficiency and
              sustainability. <b>Get started with MediaMesh today.</b>
            </text>
          </div>
          <form className="elevate-workflows-designer2" onSubmit={onFormSubmit}>
            <input
              className="elevate-input"
              placeholder="Name"
              name="name"
              value={guestName}
              onChange={onGuestNameChange}
              required
            />
            <input
              className="elevate-input"
              placeholder="Email"
              name="email"
              value={guestEmail}
              onChange={onGuestEmailChange}
              required
            />
            <input
              className="elevate-input"
              placeholder="Company"
              name="company"
              value={guestCompany}
              onChange={onGuestCompanyChange}
              required
            />
            <input
              className={`elevate-input ${phoneValid ? "" : "invalid"}`}
              placeholder="Phone (eg.+19999999999)"
              name="phone"
              value={guestPhone}
              onChange={onGuestPhoneChange}
              required
              ref={phoneInputRef}
            />
            <div className="elevate-tc">
              {/* <text>Accept Terms & Conditions</text> */}
            </div>{" "}
            <button className="elevate-button" disabled={submitting}>
              <div className="elevate-button-frame">
                <text>Contact us to Book a demo!</text>
              </div>
            </button>
          </form>
        </div>
      </div>

      <div className="home-companies">
        <LogoCard
          data={[
            {
              image: EntTech,
              description: {
                text: "MediaMesh is designed and built by Entertainment Technologists as a workflow design and orchestration platform. Find out more at",
                link: "https://www.entertainmenttechnologists.com",
              },
            },
          ]}
        />
        <LogoCard
          data={[
            {
              image: Fxdmz,
              description: {
                text: "Provides data on M&E participants (organizations people), roles, tools, and tasks which are integrated into MediaMesh. The subscription data service can be directly licensed from ",
                link: "https://www.fx-dmz.com/",
              },
            },
          ]}
        />
        <LogoCard
          data={[
            {
              image: Prodo,
              description: {
                text: "Provides data to measure the environmental impact of M&E workflow tasks and infrastructure and is integrated into MediaMesh so users can make decisions to minimize their carbon footprint. To directly license Prodo data or find out more about Prodo can help optimize your workflows visit us at",
                link: "https://www.prodo.tv/",
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Home;
