// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fallback_error {
  display: flex;
  justify-content: center;
  align-items: center;
 text-align: center;
  min-width: 90vw;
  min-height: 90vh;
 
}
.fallback_error .err_msg {
  font-size: large;
}
`, "",{"version":3,"sources":["webpack://./src/components/ErrorBoundary/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;CACpB,kBAAkB;EACjB,eAAe;EACf,gBAAgB;;AAElB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".fallback_error {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n text-align: center;\r\n  min-width: 90vw;\r\n  min-height: 90vh;\r\n \r\n}\r\n.fallback_error .err_msg {\r\n  font-size: large;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
