import "./index.css";
import {
  DateRangePickerComponent,
  TimePickerComponent,
} from "@syncfusion/ej2-react-calendars/index.js";
import { Switch } from "antd";
import { useRef, useState } from "react";
const Scheduler = ({ onClose, onSave, workflowName }) => {
  const dateObject = useRef(null);
  const timeObject = useRef(null);
  const [recurringOptions, setRecurringOptions] = useState([
    { id: "Every Monday", name: "Mon", value: false },
    { id: "Every Tuesday", name: "Tues", value: false },
    { id: "Every Wednesday", name: "Wed", value: false },
    { id: "Every Thursday", name: "Thur", value: false },
    { id: "Every Friday", name: "Fri", value: false },
    { id: "Every Weekday", name: "Sat", value: false },
    { id: "Every Sunday", name: "Sun", value: false },
  ]);
  const handleRepeatToggle = (checked) => {
    setIsRepeat(checked);
  };
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isRepeat, setIsRepeat] = useState(false);

  return (
    <div className="scheduler">
      <div className="scheduler-overlay-content">
        <div className="scheduler-frame1">
          <p1> Schedule Workflow Execution</p1>
          <p2>
            Schedule your workflow to run automatically at specified times.
            Select the date range, time, and days of the week to automate your
            workflow execution in MediaMesh.
          </p2>
        </div>
        <div className="scheduler-frame2">
          <div className="scheduler-frame2-inner">
            <div className="scheduler-workflow-name">
              <div className="workflow-name-label">
                <text>Workflow name</text>
              </div>
              <div className="workflow-name-input">
                <text>{workflowName}</text>
              </div>
            </div>
            <div className="scheduler-date-time">
              <div className="scheduler-date">
                <h4>Select Date range</h4>
                <div className="schedulerGroup">
                  <DateRangePickerComponent
                    ref={dateObject}
                    width="180px"
                    placeholder="Select Date Range"
                  ></DateRangePickerComponent>
                </div>
              </div>
              <div className="scheduler-time">
                <div className="scheduler-time-label">
                  <h4>select time</h4>
                </div>
                <div className="scheduler-time-input">
                  <TimePickerComponent
                    width="160px"
                    placeholder="Select Time"
                    ref={timeObject}
                  ></TimePickerComponent>
                </div>
              </div>
            </div>
            <div className="repeat-frame">
              <div className="carbon-switch-types">
                <div className="carbon-switch-frame">
                  <div className="carbon-switch">
                    <Switch
                      id="repeatSwitch"
                      checked={isRepeat}
                      onChange={handleRepeatToggle}
                    />
                  </div>
                </div>
                <h4>REPEAT</h4>
              </div>
              {recurringOptions.map((options) => (
                <div className="days">
                  <div className="day">
                    <input
                      type="checkbox"
                      value={options.value}
                      onChange={() => {
                        setSelectedOptions([...selectedOptions, options.name]);
                      }}
                    ></input>
                    <text className="dayText">{options.name}</text>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="button-grp">
          <button onClick={onClose} className="cancel-button">
            <div className="cancel-button-inner">
              {" "}
              <text>Cancel</text>
            </div>
          </button>
          <button
            onClick={() =>
              onSave(dateObject, selectedOptions, timeObject, isRepeat)
            }
            className="shedule-button"
          >
            <div className="shedule-button-inner">
              <text>Schedule</text>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
export default Scheduler;
